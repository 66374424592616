import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import digit from "../../Assets/Partners_logo/BOOKMYINSURANCE/digit.jpg";
import zuno from "../../Assets/Partners_logo/BOOKMYINSURANCE/zuno.jpg";
import shreeRam from "../../Assets/Partners_logo/BOOKMYINSURANCE/shriram.jpg";
import icici from "../../Assets/Partners_logo/BOOKMYINSURANCE/icici lombard.jpg";
import hdfc from "../../Assets/Partners_logo/BOOKMYINSURANCE/hdfc.jpg";
import sbi from "../../Assets/Partners_logo/BOOKMYINSURANCE/SBI.jpg";
import acko from "../../Assets/Partners_logo/BOOKMYINSURANCE/acko.jpg";
import tata from "../../Assets/Partners_logo/BOOKMYINSURANCE/TATA AIG.jpg";
import united from "../../Assets/Partners_logo/BOOKMYINSURANCE/united.jpg";
import national from "../../Assets/Partners_logo/BOOKMYINSURANCE/national.jpg";
import Botton from "../Button/Button";
import success from "../../Assets/Images/success.svg";
import fail from "../../Assets/Images/failure.svg";
import {
  zunoFullQuoteAction,
  zunoQuickQuoteAction,
} from "../../redux/Actions/zunoApiAction";
import { shreeRamQuickQuoteAction } from "../../redux/Actions/shreeRamApiAction";
import { getCompanyNameForForm } from '../../redux/Reducers/companyNameReducer'
import { digitQuickQuoteAction } from "../../redux/Actions/digitApiAction";
import { sbiQuickQuoteAction } from "../../redux/Actions/sbiApiAction";
import { Routes, Route, useNavigate, Link } from "react-router-dom";
import PlanDetails from "./PlanDetails";
import {
  SBI,
  RAHEJA,
  SHREERAM,
  ZUNO,
  DIGIT,
  ICICI,
  HDFC,
  ACKO,
  TATA,
  UNITED,
  NATIONAL,
  findKeyValue
} from "../../utils/commonUtils";
import { getCompanyName } from "../../redux/Reducers/companyNameReducer";
import ButtonLoader from "../Pages/Loader/Button Loader/ButtonLoader";
import SkeletonLoader from "../Pages/Loader/Skeleton loader/SkeletonLoader";
import inspection from "../../Assets/Images/inspection.svg"
import noInspection from "../../Assets/Images/noInspection.png"
import PlancardError from "./PlancardError";
import { allBodyDataAction } from "../../redux/Reducers/bodyDataReducer";
import rightArrow from "../../Assets/Images/arrowRight.svg"
import Modal from "react-modal";
import PackageModal from "./PackageModal";
import DigitPackageModal from "./DigitPackageModal"
import ICICIPackageModal from "./ICICIPackageModal"
import tooltip from "../../Assets/Images/tooltip2.svg"
import { changeSectionValueFormat } from "@mui/x-date-pickers/internals/hooks/useField/useField.utils";

const customStyles = {
  content: window.innerWidth <= 610
    ? {
      top: "auto",
      left: "0",
      right: "0",
      bottom: "0",
      margin: "0",
      transform: "none",
      borderRadius: "10px 10px 0 0",
      padding: "20px",
      height: "auto",
    }
    : {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      borderRadius: "10px",
      padding: "20px",
      height: "auto",
    },
};

const Plancard = (props) => {

  const {
    planType,
    openModal,
    carDetails,
    setShowBottomTab,
    ShowSkeleton,
    test,
    setDigitAddon,
    DigitAddon,
    objWihtAddOn,
    triggerVerifyTab,
    setCarDetails,
    carDetailsCopyState,
    setcarDetailsCopyState,
    CalendarDeactiveFlag,
    ShowPackageModal,
    setShowPackageModal,
    ShowDigPackageModal,
    setShowDigPackageModal,
    setShowNilDepModal,
    setShowICICIPackageModal,
    ShowICICIPackageModal,
    setIsActiveFromPlanCard,
    DigitPlan,
    ICICIPlan,
    digitAddonsplan,
    setShowRtiModal,
    stopApi,
    allIDVValues,
  } = props;

  const navigate = useNavigate();
  const DigitAddonsPlan = useRef();
  const [ErrorPlan, setErrorPlan] = useState(false);
  const [OnClickAddonsPlan, setOnClickAddonsPlan] = useState(false); // false
  const [newStateforLoder, setNewSateLoder] = useState(false)
  let maxIdv = [];
  let minIdv = [];

  const inspectDetails = useSelector((state) => {
    return state?.inspectDetails?.data?.isInspectionReq;
  });

  const DigitPlans = useSelector((state) => {
    return state?.digitQuickQuote;
  });

  let checkboxTrueFalseObject = {
    paCover: false,
    zeroDep: false,
    consumables: false,
    roadAssistance: false,
    engineProtection: false,
    invoiceCover: false,
    keyLockReplacement: false,
    ncbProtection: false,
    driverCover: false,
    passengerCover: false,
    accessories: false,
    tyreProtection: false,
    emiProtection: false,
    lossofPersonalBelongings: false,
    dailyAllowance: false,
    shreeramProtect: false,
  };

  for (let addon of test) {
    if (addon.name in checkboxTrueFalseObject) {
      checkboxTrueFalseObject[addon.name] = addon.checked ? true : false;
    }
  }

  const handleBuyNow = (logo, policyData, companyName) => {
    dispatch(
      getCompanyName({
        companyName: companyName,
      })
    );
    openModal(logo, policyData, companyName);
  };

  const dispatch = useDispatch();

  let ans = test.filter((e) => {
    return e.id == false
  })

  let sbiQuickQuote = useSelector((state) => {
    return state?.sbiQuickQuote;
  })

  const sbiQuickQuoteLoading = sbiQuickQuote?.isLoading;

  const getBodyData = useSelector((state) => {
    return state?.bodyData;
  });

  // Digit Plans Name response
  let RsaPlan = DigitPlans?.data?.data?.plans?.RSA;
  let proPlan = DigitPlans?.data?.data?.plans?.Pro
  let DproPlan = DigitPlans?.data?.data?.plans?.["D Pro"];
  let digitQuote4 = DigitPlans?.data?.data?.plans?.["DC Pro"];
  let digitQuote5 = DigitPlans?.data?.data?.plans?.["DCE Pro"];
  let digitQuote6 = DigitPlans?.data?.data?.plans?.["DCT Pro"];
  let digitQuote7 = DigitPlans?.data?.data?.plans?.["DCET Pro"];
  let digitQuote8 = DigitPlans?.data?.data?.plans?.["DC- RTI Pro"];
  let digitQuote9 = DigitPlans?.data?.data?.plans?.["DCE - RTI Pro"];
  let digitQuote10 = DigitPlans?.data?.data?.plans?.["DCT - RTI Pro"];
  let digitQuote11 = DigitPlans?.data?.data?.plans?.["DCET - RTI Pro"];
  
  console.log(DigitPlans,'DigitPlansyutyweutyyw');
  

  let sbiPrice = {
    paCover: false,
    zeroDep: false,
    consumables: false,
    roadAssistance: false,
    engineProtection: false,
    invoiceCover: false,
    keyLockReplacement: false,
    ncbProtection: false,
    driverCover: false,
    passengerCover: false,
    accessories: false,
    totalPremium: false,
    basicPremium: false,
    idv: false,
    duePremium: false,
    tyreProtection: false,
    emiProtection: false,
    lossofPersonalBelongings: false,
    dailyAllowance: false,
    odExternalCngLpg: false,
    odInternalCngLpg: false,
    tpCngLpg: false,
    totalAddonValue: 0,
    paidDriver: sbiQuickQuote?.data?.body?.data?.quote?.PolicyLobList ? sbiQuickQuote?.data?.body?.data?.quote?.PolicyLobList[0]?.PolicyRiskList[0]?.PolicyCoverageList[0]?.PolicyBenefitList[2]?.AnnualPremium : 0
  };

  if (
    sbiQuickQuote?.data?.body?.data?.quote?.PolicyLobList?.length &&
    sbiQuickQuote?.data?.body?.data?.quote?.PolicyLobList[0]?.PolicyRiskList[0]
      ?.PolicyCoverageList?.length
  ) {
    sbiPrice.idv =
      sbiQuickQuote?.data?.body?.data?.quote?.PolicyLobList[0]?.PolicyRiskList[0]?.IDV_User;
    sbiPrice.duePremium =
      sbiQuickQuote?.data?.body?.data?.quote?.BeforeVatPremium;

    if (planType === "Third Party") {
      sbiPrice.basicPremium =
        sbiQuickQuote?.data?.body?.data?.quote?.PolicyLobList[0]?.PolicyRiskList[0]?.TP_BasePremium;
    } else if (planType === "Own Damage") {
      sbiPrice.basicPremium =
        sbiQuickQuote?.data?.body?.data?.quote?.PolicyLobList[0]?.PolicyRiskList[0]?.OD_After_Disc; // od after ncb discount
    } // PolicyLobList[0]?.PolicyRiskList[0]?.OD_After_Disc;
    else {
      sbiPrice.basicPremium =
        sbiQuickQuote?.data?.body?.data?.quote?.PolicyLobList[0]
          ?.PolicyRiskList[0]?.OD_After_Disc +
        sbiQuickQuote?.data?.body?.data?.quote?.PolicyLobList[0]
          ?.PolicyRiskList[0]?.TP_BasePremium; // od after ncb discount + basic tp
    }

    for (
      let index = 0;
      index <
      sbiQuickQuote?.data?.body?.data?.quote?.PolicyLobList[0]
        ?.PolicyRiskList[0]?.PolicyCoverageList?.length;
      index++
    ) {
      const element =
        sbiQuickQuote?.data?.body?.data?.quote?.PolicyLobList[0]
          ?.PolicyRiskList[0]?.PolicyCoverageList?.[index];
      switch (element?.ProductElementCode) {
        case "C101066":
          // sbiPrice.paCover = element?.AnnualPremium;

          break;
        case "C101072":
          sbiPrice.zeroDep = element?.AnnualPremium;

          sbiPrice.totalAddonValue += element?.AnnualPremium;
          break;
        case "C101111":
          sbiPrice.consumables = element?.AnnualPremium;
          sbiPrice.totalAddonValue += element?.AnnualPremium;

          break;
        case "C101069":
          sbiPrice.roadAssistance =
            element?.PolicyBenefitList[0]?.AnnualPremium;
          sbiPrice.totalAddonValue += element?.PolicyBenefitList[0]?.AnnualPremium;;
          break;
        case "C101108":
          sbiPrice.engineProtection = element?.AnnualPremium;
          sbiPrice.totalAddonValue += element?.AnnualPremium;
          break;
        case "C101073":
          sbiPrice.keyLockReplacement = element?.AnnualPremium;
          sbiPrice.totalAddonValue += element?.AnnualPremium;
          break;
        case "C101067":
          sbiPrice.invoiceCover = element?.AnnualPremium;
          sbiPrice.totalAddonValue += element?.AnnualPremium;
          break;
        case "C101069":
          sbiPrice.roadAssistance = element?.AnnualPremium;

          sbiPrice.totalAddonValue += element?.AnnualPremium;
          break;
        case "C101110":
          sbiPrice.tyreProtection = element?.AnnualPremium;
          sbiPrice.totalAddonValue += element?.AnnualPremium;
          break;
        case "C101109":
          sbiPrice.emiProtection = element?.AnnualPremium;
          sbiPrice.totalAddonValue += element?.AnnualPremium;
          break;
        case "C101075":
          sbiPrice.lossofPersonalBelongings = element?.AnnualPremium;
          sbiPrice.totalAddonValue += element?.AnnualPremium;
          break;
        case "C101074":
          sbiPrice.dailyAllowance = element?.AnnualPremium;
          sbiPrice.totalAddonValue += element?.AnnualPremium;
          break;
        case "C101068":
          // if (test[7].checked == true) {
          //   sbiPrice.ncbProtection =
          //     element?.AnnualPremium === 0 ? false : element?.AnnualPremium;
          //   sbiPrice.totalAddonValue += element?.AnnualPremium;


          // }

          sbiPrice.ncbProtection =
            element?.AnnualPremium === 0 ? false : element?.AnnualPremium;
          sbiPrice.totalAddonValue += element?.AnnualPremium;
          break;
        default:
          break;
      }

      for (let index = 0; index < element?.PolicyBenefitList?.length; index++) {
        const coveragePolicy = element?.PolicyBenefitList[index];
        switch (coveragePolicy?.ProductElementCode) {
          case "B00016": // PA - Passenger cover
            sbiPrice.passengerCover = coveragePolicy?.GrossPremium;
            sbiPrice.totalAddonValue += coveragePolicy?.GrossPremium;
            break;
          case "B00027": // PA - Driver cover
            sbiPrice.driverCover = coveragePolicy?.GrossPremium;
            sbiPrice.totalAddonValue += coveragePolicy?.GrossPremium;
            break;
          case "B00015": //PA - Owner Driver
            sbiPrice.paCover = coveragePolicy?.GrossPremium;
            sbiPrice.totalAddonValue += coveragePolicy?.GrossPremium;

            break;
          case "B00004": //electrical
            sbiPrice.accessories += coveragePolicy?.AnnualPremium;
            // sbiPrice.totalAddonValue += coveragePolicy?.AnnualPremium;
            break;
          case "B00003": // accessories
            sbiPrice.accessories += coveragePolicy?.AnnualPremium;
            // sbiPrice.totalAddonValue += coveragePolicy?.AnnualPremium;
            break;
          case "B00005": // external fitted CNG
            sbiPrice.odExternalCngLpg = coveragePolicy?.AnnualPremium;
            // sbiPrice.totalAddonValue += coveragePolicy?.AnnualPremium;
            break;
          case "B00006": // internal fitted CNG
            sbiPrice.odInternalCngLpg = coveragePolicy?.AnnualPremium;
            // sbiPrice.totalAddonValue += coveragePolicy?.AnnualPremium;
            break;
          case "B00010":
            sbiPrice.tpCngLpg = coveragePolicy?.AnnualPremium;
            // sbiPrice.totalAddonValue += coveragePolicy?.AnnualPremium;
            break;
          case "B00013":
            sbiPrice.paidDriver = coveragePolicy?.GrossPremium;
            break;
          default:
            break;
        }
      }
    }
  }

  //shree ram set value

  let shreeRamQuote = useSelector((state) => {
    return state?.shreeRamQuickQuote;
  })

  const shreeRamQuotePrice = shreeRamQuote?.data?.data?.quote?.GetQuotResult;
  const shreeRamQuoteLoading = shreeRamQuote?.isLoading;

  let shreeRamPrice = {
    paCover: false,
    zeroDep: false,
    zeroDepLoad: false,
    consumables: false,
    consumablesLoad: false,
    roadAssistance: false,
    engineProtection: false,
    engineProtectionLaod: false,
    invoiceCover: false,
    keyLockReplacement: false,
    ncbProtection: false,
    driverCover: false,
    passengerCover: false,
    accessories: false,
    electricAccessories: false,
    nonElectricAccessories: false,
    totalPremium: false,
    basicPremium: 0,
    deTarrifAmount: 0,
    ncbDiscount: 0,
    idv: false,
    duePremium: false,
    tyreProtection: false,
    emiProtection: false,
    lossofPersonalBelongings: false,
    dailyAllowance: false,
    odExternalCngLpg: false,
    tpExternalCngLpg: false,
    shreeramProtect: false,
    totalAddonValue: 0
  };

  let coutPlanCards = 0;

  for (
    let index = 0;
    index < shreeRamQuotePrice?.CoverDtlList.length;
    index++
  ) {
    let premium = shreeRamQuotePrice.CoverDtlList[index].Premium;
    let switchCaseMatchForAllYear = shreeRamQuotePrice.CoverDtlList[
      index
    ].CoverDesc.replace(/ - (1|2|3|4) Year/g, "");
    switch (switchCaseMatchForAllYear) {
      case "Total Premium":
        shreeRamPrice.totalPremium += Number(premium);
        break;
      case "Depreciation Deduction Waiver (Nil Depreciation) - OD":
        shreeRamPrice.zeroDep += Number(premium);
        shreeRamPrice.totalAddonValue += Number(premium);
        break;
      case "Nil Depreciation Loading - OD":
        shreeRamPrice.zeroDepLoad += Number(premium);
        shreeRamPrice.zeroDep += Number(premium);
        shreeRamPrice.totalAddonValue += Number(premium);
        break;
      case "Engine Protector - OD":
        shreeRamPrice.engineProtection += Number(premium);
        shreeRamPrice.totalAddonValue += Number(premium);
        break;
      case "Engine Protector Loading - OD":
        shreeRamPrice.engineProtectionLaod += Number(premium);
        shreeRamPrice.engineProtection += Number(premium);
        shreeRamPrice.totalAddonValue += Number(premium);
        break;
      case "Consumable - OD":
        shreeRamPrice.consumables += Number(premium);
        shreeRamPrice.totalAddonValue += Number(premium);
        break;
      case "Consumable Loading - OD":
        shreeRamPrice.consumablesLoad += Number(premium);
        shreeRamPrice.consumables += Number(premium);
        shreeRamPrice.totalAddonValue += Number(premium);
        break;
      case "Key Replacement - OD":
        shreeRamPrice.keyLockReplacement += Number(premium);
        shreeRamPrice.totalAddonValue += Number(premium);
        break;
      case "Road Side Assistance - OD":
        shreeRamPrice.roadAssistance += Number(premium);
        shreeRamPrice.totalAddonValue += Number(premium);
        break;
      case "GR42-Outbuilt CNG/LPG-Kit-Cover - OD":
        shreeRamPrice.odExternalCngLpg += Number(premium);
        break;
      case "GR36B2-PA Cover For Passengers (Un-Named Persons) - TP":
        shreeRamPrice.passengerCover += Number(premium);
        shreeRamPrice.totalAddonValue += Number(premium);
        break;
      case "GR36A-PA FOR OWNER DRIVER - TP":
        shreeRamPrice.paCover += Number(premium);
        shreeRamPrice.totalAddonValue += Number(premium);
        break;
      case "Legal Liability Coverages For Paid Driver - TP":
        shreeRamPrice.driverCover += Number(premium);
        shreeRamPrice.totalAddonValue += Number(premium);
        break;
      case "GR42-Outbuilt CNG/LPG-Kit-Cover - TP":
        shreeRamPrice.tpExternalCngLpg += Number(premium);
        shreeRamPrice.totalAddonValue += Number(premium);
        break;
      case "GR41-Cover For Electrical and Electronic Accessories - OD":
        shreeRamPrice.accessories += Number(premium);
        shreeRamPrice.totalAddonValue += Number(premium);
        break;
      case "Cover For Non Electrical Accessories - OD":
        shreeRamPrice.accessories += Number(premium);
        shreeRamPrice.totalAddonValue += Number(premium);
        break;
      case "Return to Invoice - OD":
        shreeRamPrice.invoiceCover += Number(premium);
        shreeRamPrice.totalAddonValue += Number(premium);
        break;
      case "Personal Belonging - OD":
        shreeRamPrice.lossofPersonalBelongings += Number(premium);
        shreeRamPrice.totalAddonValue += Number(premium);
        break;
      case "Personal Belonging - OD":
        shreeRamPrice.lossofPersonalBelongings += Number(premium);
        shreeRamPrice.totalAddonValue += Number(premium);
        break;
      case "Basic Premium - OD":
        shreeRamPrice.basicPremium += Number(premium);
        break;
      case "Basic Premium - TP":
        shreeRamPrice.basicPremium += Number(premium);
        break;
      case "De-Tariff Discount - OD":
        shreeRamPrice.deTarrifAmount += Number(premium);
        break;
      case "NCB Discount  - OD":
        shreeRamPrice.ncbDiscount = Number(premium);
        break;
      case "Motor Protection - OD":
        shreeRamPrice.shreeramProtect += Number(premium);
        shreeRamPrice.totalAddonValue += Number(premium);
        break;
      default:
        break;
    }
  }

  shreeRamPrice.basicPremium =
    (shreeRamPrice.basicPremium + shreeRamPrice.accessories) -
    shreeRamPrice.deTarrifAmount -
    shreeRamPrice.ncbDiscount;

  let digitQuote12 = useSelector((state) => {
    return state?.digitQuickQuote;
  })

  let digitQuote = digitQuote12
  let SingleAddonDig = test

  if (carDetails.quotePackageNameDigit == "RSA") {
    digitQuote = RsaPlan;
    SingleAddonDig = digitAddonsplan.RSAplan

  } else if (carDetails.quotePackageNameDigit == "D Pro") {
    digitQuote = DproPlan;
    SingleAddonDig = digitAddonsplan.DROplan

  } else if (carDetails.quotePackageNameDigit == "DC Pro") {
    digitQuote = digitQuote4
    SingleAddonDig = digitAddonsplan.DCProplan
  } else if (carDetails.quotePackageNameDigit == "Pro") {
    digitQuote = proPlan;
    SingleAddonDig = digitAddonsplan.Proplan
  } else if (carDetails.quotePackageNameDigit == "DCE Pro") {
    digitQuote = digitQuote5
    SingleAddonDig = digitAddonsplan.DCEProplan
  } else if (carDetails.quotePackageNameDigit == "DCT Pro") {
    digitQuote = digitQuote6
    SingleAddonDig = digitAddonsplan.DCTProplan
  } else if (carDetails.quotePackageNameDigit == "DCET Pro") {
    digitQuote = digitQuote7
    SingleAddonDig = digitAddonsplan.DCETProplan
  } else if (carDetails.quotePackageNameDigit == "DCRTI Pro") {
    digitQuote = digitQuote8
    SingleAddonDig = digitAddonsplan.DCRTIProplan
  } else if (carDetails.quotePackageNameDigit == "DCERTI Pro") {
    digitQuote = digitQuote9
    SingleAddonDig = digitAddonsplan.DCERTIProPlan
  } else if (carDetails.quotePackageNameDigit == "DCTRTI Pro") {
    digitQuote = digitQuote10
    SingleAddonDig = digitAddonsplan.DCTRTIProPlan
  } else if (carDetails.quotePackageNameDigit == "DCETRTI Pro") {
    digitQuote = digitQuote11
    SingleAddonDig = digitAddonsplan.DCETRTIProPlan
  } else {
    digitQuote = digitQuote12;
    SingleAddonDig = test
  }

  let OnClickAddonsplan = null

  if (checkboxTrueFalseObject.roadAssistance && !carDetails.quotePackageNameDigit) {
    digitQuote = RsaPlan;
    SingleAddonDig = digitAddonsplan.RSAplan
    OnClickAddonsplan = true
  }
  if (checkboxTrueFalseObject.zeroDep && !carDetails.quotePackageNameDigit) {
    digitQuote = DproPlan;
    SingleAddonDig = digitAddonsplan.PROplan
    OnClickAddonsplan = true
  }
  if ((checkboxTrueFalseObject.zeroDep && checkboxTrueFalseObject.consumables) && !carDetails.quotePackageNameDigit) {
    digitQuote = digitQuote4
    SingleAddonDig = digitAddonsplan.DCProplan
    OnClickAddonsplan = true
  }
  if ((checkboxTrueFalseObject.zeroDep && checkboxTrueFalseObject.engineProtection) && !carDetails.quotePackageNameDigit) {
    digitQuote = digitQuote5
    SingleAddonDig = digitAddonsplan.DCEProplan
    OnClickAddonsplan = true

  }
  if ((checkboxTrueFalseObject.zeroDep && checkboxTrueFalseObject.tyreProtection) && !carDetails.quotePackageNameDigit) {
    digitQuote = digitQuote6
    SingleAddonDig = digitAddonsplan.DCTProplan
    OnClickAddonsplan = true

  }
  if ((checkboxTrueFalseObject.zeroDep && checkboxTrueFalseObject.tyreProtection && checkboxTrueFalseObject.engineProtection) && !carDetails.quotePackageNameDigit) {
    digitQuote = digitQuote7
    SingleAddonDig = digitAddonsplan.DCETProplan
    OnClickAddonsplan = true

  }
  if ((checkboxTrueFalseObject.zeroDep && checkboxTrueFalseObject.invoiceCover) && !carDetails.quotePackageNameDigit) {
    digitQuote = digitQuote8
    SingleAddonDig = digitAddonsplan.DCRTIProplan
    OnClickAddonsplan = true
  }
  if ((checkboxTrueFalseObject.zeroDep && checkboxTrueFalseObject.tyreProtection && checkboxTrueFalseObject.invoiceCover) && !carDetails.quotePackageNameDigit) {
    digitQuote = digitQuote9
    SingleAddonDig = digitAddonsplan.DCERTIProplan
    OnClickAddonsplan = true

  }
  if ((checkboxTrueFalseObject.zeroDep && checkboxTrueFalseObject.tyreProtection && checkboxTrueFalseObject.invoiceCover) && !carDetails.quotePackageNameDigit) {
    digitQuote = digitQuote10
    SingleAddonDig = digitAddonsplan.DCTRTIProplan
    OnClickAddonsplan = true

  }

  const digitQuotePrice = carDetails.quotePackageNameDigit || OnClickAddonsplan ? digitQuote?.["Motor-Quick Quote"] : digitQuote?.data?.data?.quote?.["Motor-Quick Quote"];
  const digitQuoteLoading = digitQuote?.isLoading;
  const digitContractCovereage = digitQuotePrice?.contract?.coverages;
  const digitIDV = digitQuotePrice?.vehicle?.vehicleIDV;

  const digitPrice = {
    paCover:
      digitContractCovereage?.["personalAccident"]?.["selection"] &&
        digitContractCovereage?.["personalAccident"]?.["coverAvailability"] !==
        "NOT_AVAILABLE"
        ? digitContractCovereage?.["personalAccident"]?.["netPremium"]
        : false,
    zeroDep:
      digitContractCovereage?.["addons"]?.["partsDepreciation"]?.[
        "selection"
      ] &&
        digitContractCovereage?.["addons"]?.["partsDepreciation"]?.[
        "coverAvailability"
        ] !== "NOT_AVAILABLE"
        ? digitContractCovereage?.["addons"]?.["partsDepreciation"]?.[
        "netPremium"
        ]
        : false,
    consumables:
      digitContractCovereage?.["addons"]?.["consumables"]?.["selection"] &&
        digitContractCovereage?.["addons"]?.["consumables"]?.[
        "coverAvailability"
        ] !== "NOT_AVAILABLE"
        ? digitContractCovereage?.["addons"]?.["consumables"]?.["netPremium"]
        : false,
    roadAssistance:
      digitContractCovereage?.["addons"]?.["roadSideAssistance"]?.[
        "selection"
      ] &&
        digitContractCovereage?.["addons"]?.["roadSideAssistance"]?.[
        "coverAvailability"
        ] !== "NOT_AVAILABLE"
        ? digitContractCovereage?.["addons"]?.["roadSideAssistance"]?.[
        "netPremium"
        ]
        : false,
    engineProtection:
      digitContractCovereage?.["addons"]?.["engineProtection"]?.["selection"] &&
        digitContractCovereage?.["addons"]?.["engineProtection"]?.[
        "coverAvailability"
        ] !== "NOT_AVAILABLE"
        ? digitContractCovereage?.["addons"]?.["engineProtection"]?.[
        "netPremium"
        ]
        : false,
    invoiceCover:
      digitContractCovereage?.["addons"]?.["returnToInvoice"]?.["selection"] &&
        digitContractCovereage?.["addons"]?.["returnToInvoice"]?.[
        "coverAvailability"
        ] !== "NOT_AVAILABLE"
        ? digitContractCovereage?.["addons"]?.["returnToInvoice"]?.[
        "netPremium"
        ]
        : false,
    keyLockReplacement:
      digitContractCovereage?.["addons"]?.["keyAndLockProtect"]?.[
        "selection"
      ] &&
        digitContractCovereage?.["addons"]?.["keyAndLockProtect"]?.[
        "coverAvailability"
        ] !== "NOT_AVAILABLE"
        ? digitContractCovereage?.["addons"]?.["keyAndLockProtect"]?.[
        "netPremium"
        ]
        : false,
    driverCover: digitContractCovereage?.["unnamedPA"]?.["unnamedPaidDriver"]?.[
      "selection"
    ]
      ? digitContractCovereage?.["unnamedPA"]?.["unnamedPaidDriver"]?.[
      "netPremium"
      ]
      : false,
    passengerCover: digitContractCovereage?.["unnamedPA"]?.["unnamedPax"]?.[
      "selection"
    ]
      ? digitContractCovereage?.["unnamedPA"]?.["unnamedPax"]?.["netPremium"]
      : false,
    accessories:
      digitContractCovereage?.["accessories"]?.["cng"]?.["selection"] ||
        digitContractCovereage?.["accessories"]?.["electrical"]?.["selection"] ||
        digitContractCovereage?.["accessories"]?.["nonElectrical"]?.["selection"]
        ? true
        : false,
    tyreProtection:
      digitContractCovereage?.["addons"]?.["tyreProtection"]?.["selection"] &&
        digitContractCovereage?.["addons"]?.["tyreProtection"]?.[
        "coverAvailability"
        ] !== "NOT_AVAILABLE"
        ? digitContractCovereage?.["addons"]?.["tyreProtection"]?.["netPremium"]
        : false,
    rimProtection:
      digitContractCovereage?.["addons"]?.["rimProtection"]?.["selection"] &&
        digitContractCovereage?.["addons"]?.["rimProtection"]?.[
        "coverAvailability"
        ] !== "NOT_AVAILABLE"
        ? digitContractCovereage?.["addons"]?.["rimProtection"]?.["netPremium"]
        : false,
    personalBelonging:
      digitContractCovereage?.["addons"]?.["personalBelonging"]?.[
        "selection"
      ] &&
        digitContractCovereage?.["addons"]?.["personalBelonging"]?.[
        "coverAvailability"
        ] !== "NOT_AVAILABLE"
        ? digitContractCovereage?.["addons"]?.["personalBelonging"]?.[
        "netPremium"
        ]
        : false,
    emiProtection: false,
    dailyAllowance: false,
    ncbProtection: false,
    totalPremium: digitQuotePrice?.grossPremium?.slice(4),
    duePremium: digitQuotePrice?.netPremium?.slice(4),
    totalAddOnDigi: false
    //basicPremium: digitQuotePrice?.netPremium
  };

  for (let key in digitPrice) {
    if ((key && key !== NaN) && (key == 'driverCover' || key == "paCover")) {
      if (digitPrice[key] !== false) {
        let val = digitPrice[key].toString()?.split(" ")?.pop();
        digitPrice.totalAddOnDigi += Math.round(Number(val))
      }
    }
  }

  let OD_BasicPremium =
    digitContractCovereage?.thirdPartyLiability?.netPremium?.slice(4);
  let TP_BasicPremium = digitContractCovereage?.ownDamage?.netPremium?.slice(4);
  if (planType === "Third Party") {
    digitPrice.basicPremium = Math.round(OD_BasicPremium ? Number(OD_BasicPremium) : 0);
  } else if (planType === "Own Damage") {
    digitPrice.basicPremium = Math.round(TP_BasicPremium ? (Number(TP_BasicPremium) - ((digitContractCovereage?.ownDamage?.ncbDiscountWithZeroDepWithPreInspection ? (Number(digitContractCovereage?.ownDamage?.ncbDiscountWithZeroDepWithPreInspection.slice(4))) : 0))) : 0); // od after ncb discount
  } else {
    digitPrice.basicPremium =
      Math.round((OD_BasicPremium ? Number(OD_BasicPremium) : 0) +
        (TP_BasicPremium ? Number(TP_BasicPremium) : 0) -
        (digitContractCovereage?.ownDamage?.ncbDiscountWithZeroDep ? (Number(digitContractCovereage?.ownDamage?.ncbDiscountWithZeroDep.slice(4))) : 0));
  }

  let iciciQuote = useSelector((state) => {
    return state?.iciciQuickQuote;
  })
  // });
  const iciciQuotePrice = iciciQuote?.data?.data?.quote;
  const iciciQuoteLoading = iciciQuote?.isLoading;
  const iciciIDV = iciciQuotePrice?.generalInformation?.depriciatedIDV;

  const iciciPrice = {
    paCover: iciciQuotePrice?.riskDetails?.paCoverForOwnerDriver
      ? iciciQuotePrice?.riskDetails?.paCoverForOwnerDriver
      : false,
    zeroDep: iciciQuotePrice?.riskDetails?.zeroDepreciation
      ? iciciQuotePrice?.riskDetails?.zeroDepreciation
      : false,
    consumables: iciciQuotePrice?.riskDetails?.consumables
      ? iciciQuotePrice?.riskDetails?.consumables
      : false,
    roadAssistance: iciciQuotePrice?.riskDetails?.roadSideAssistance
      ? iciciQuotePrice?.riskDetails?.roadSideAssistance
      : false,
    engineProtection: iciciQuotePrice?.riskDetails?.engineProtect
      ? iciciQuotePrice?.riskDetails?.engineProtect
      : false,
    invoiceCover: iciciQuotePrice?.riskDetails?.returnToInvoice
      ? iciciQuotePrice?.riskDetails?.returnToInvoice
      : false,
    keyLockReplacement: iciciQuotePrice?.riskDetails?.keyProtect
      ? iciciQuotePrice?.riskDetails?.keyProtect
      : false,
    driverCover: iciciQuotePrice?.riskDetails?.paidDriver
      ? iciciQuotePrice?.riskDetails?.paidDriver
      : false,
    passengerCover: iciciQuotePrice?.riskDetails?.paCoverForUnNamedPassenger
      ? iciciQuotePrice?.riskDetails?.paCoverForUnNamedPassenger
      : false,
    accessories:
      iciciQuotePrice?.riskDetails?.electricalAccessories ||
        iciciQuotePrice?.riskDetails?.nonElectricalAccessories
        ? true
        : false,
    tyreProtection: iciciQuotePrice?.riskDetails?.tyreProtect
      ? iciciQuotePrice?.riskDetails?.tyreProtect
      : false,
    rimProtection: false,
    personalBelonging: iciciQuotePrice?.riskDetails?.lossOfPersonalBelongings
      ? iciciQuotePrice?.riskDetails?.lossOfPersonalBelongings
      : false,
    emiProtection: false,
    dailyAllowance: false,
    ncbProtection: false,
    totalPremium: iciciQuotePrice?.finalPremium,
    duePremium: iciciQuotePrice?.packagePremium
      ? iciciQuotePrice?.packagePremium
      : iciciQuotePrice?.totalLiabilityPremium,
    totalAddOn: false
  };

  for (let key in iciciPrice) {
    if (key && key !== NaN && key !== 'totalPremium' && key !== 'duePremium' && key !== 'totalAddOn') {
      iciciPrice.totalAddOn += iciciPrice[key]
    }
  }

  //adding od and tp basic price
  iciciPrice.basicPremium =
    (iciciQuotePrice?.riskDetails?.basicOD
      ? iciciQuotePrice?.riskDetails?.basicOD
      : 0) +
    (iciciQuotePrice?.riskDetails?.biFuelKitOD
      ? iciciQuotePrice?.riskDetails?.biFuelKitOD
      : 0) +
    (iciciQuotePrice?.riskDetails?.electricalAccessories
      ? iciciQuotePrice?.riskDetails?.electricalAccessories
      : 0) +
    (iciciQuotePrice?.riskDetails?.nonElectricalAccessories
      ? iciciQuotePrice?.riskDetails?.nonElectricalAccessories
      : 0) +
    iciciQuotePrice?.riskDetails?.basicTP +
    iciciQuotePrice?.riskDetails?.biFuelKitTP;

  let loadingAmt = iciciQuotePrice?.riskDetails?.breakinLoadingAmount ? iciciQuotePrice?.riskDetails?.breakinLoadingAmount : 0;
  let ncbDiscount = iciciQuotePrice?.riskDetails?.bonusDiscount ? iciciQuotePrice?.riskDetails?.bonusDiscount : 0;

  //minus ncb
  iciciPrice.basicPremium = (iciciPrice.basicPremium + loadingAmt) - ncbDiscount;
  iciciPrice.basicPremium = iciciPrice.basicPremium ? iciciPrice.basicPremium : "NA";
  iciciPrice.duePremium = iciciPrice.duePremium ? iciciPrice.duePremium : 0;
  iciciPrice.odExternalCngLpg = iciciQuotePrice?.riskDetails?.biFuelKitOD;
  iciciPrice.tpCngLpg = iciciQuotePrice?.riskDetails?.biFuelKitTP;

  // hdfcprice
  const hdfcQuote = useSelector((state) => {
    return state?.hdfcQuickQuote;
  });

  const hdfcQuotePrice = hdfcQuote?.data?.data?.quote?.Resp_PvtCar;
  const hdfcQuoteLoading = hdfcQuote?.isLoading;
  const hdfcIDV = hdfcQuote?.data?.data?.quote?.Resp_PvtCar?.IDV;

  const hdfcPrice = {
    paCover: hdfcQuotePrice?.PAOwnerDriver_Premium
      ? hdfcQuotePrice?.PAOwnerDriver_Premium
      : false,
    zeroDep: hdfcQuotePrice?.Vehicle_Base_ZD_Premium
      ? hdfcQuotePrice?.Vehicle_Base_ZD_Premium
      : false,
    consumables: hdfcQuotePrice?.Vehicle_Base_COC_Premium
      ? hdfcQuotePrice?.Vehicle_Base_COC_Premium
      : false,
    roadAssistance: hdfcQuotePrice?.EA_premium
      ? hdfcQuotePrice?.EA_premium
      : false,
    engineProtection: hdfcQuotePrice?.Vehicle_Base_ENG_Premium
      ? hdfcQuotePrice?.Vehicle_Base_ENG_Premium
      : false,
    invoiceCover: hdfcQuotePrice?.Vehicle_Base_RTI_Premium
      ? hdfcQuotePrice?.Vehicle_Base_RTI_Premium
      : false,
    keyLockReplacement: false,
    driverCover: hdfcQuotePrice?.PAPaidDriver_Premium
      ? hdfcQuotePrice?.PAPaidDriver_Premium
      : false,
    passengerCover: hdfcQuotePrice?.UnnamedPerson_premium
      ? hdfcQuotePrice?.UnnamedPerson_premium
      : false,
    accessories: Number(hdfcQuotePrice?.Electical_Acc_Premium) + Number(hdfcQuotePrice?.NonElectical_Acc_Premium) > 0
      ? Number(hdfcQuotePrice?.Electical_Acc_Premium) + Number(hdfcQuotePrice?.NonElectical_Acc_Premium)
      : false,
    tyreProtection: hdfcQuotePrice?.Vehicle_Base_TySec_Premium
      ? hdfcQuotePrice?.Vehicle_Base_TySec_Premium
      : false,
    rimProtection: false,
    personalBelonging: hdfcQuotePrice?.LossOfPersonalBelongings_Premium
      ? hdfcQuotePrice?.LossOfPersonalBelongings_Premium
      : false,
    emiProtection: false,
    dailyAllowance: false,
    ncbProtection: hdfcQuotePrice?.Vehicle_Base_NCB_Premium
      ? hdfcQuotePrice?.Vehicle_Base_NCB_Premium
      : false,
    totalPremium: hdfcQuotePrice?.Total_Premium,
    duePremium: hdfcQuotePrice?.Net_Premium

  };

  //adding od and tp basic price
  hdfcPrice.basicPremium =
    hdfcQuotePrice?.Basic_OD_Premium +
    hdfcQuotePrice?.Basic_TP_Premium

  // let loadingAmt = iciciQuotePrice?.riskDetails?.breakinLoadingAmount ? iciciQuotePrice?.riskDetails?.breakinLoadingAmount : 0;
  let ncbDiscountHdfc = hdfcQuotePrice?.NCBBonusDisc_Premium ? hdfcQuotePrice?.NCBBonusDisc_Premium : 0;

  //minus ncb
  hdfcPrice.basicPremium = hdfcPrice.basicPremium - ncbDiscountHdfc;
  hdfcPrice.duePremium = hdfcPrice.duePremium ? hdfcPrice.duePremium : 0;
  hdfcPrice.odExternalCngLpg = hdfcQuotePrice?.BiFuel_Kit_OD_Premium;
  hdfcPrice.tpCngLpg = hdfcQuotePrice?.BiFuel_Kit_TP_Premium;

  //Handle Acko Quote 
  const ackoQuote = useSelector((state) => {
    return state?.ackoQuickQuote;
  });

  const ackoQuotePrice = ackoQuote?.data?.data?.quote?.insured[0];
  const ackoQuoteLoading = ackoQuote?.isLoading;
  const ackoIDV = ackoQuotePrice?.idv?.values[0].idv_amount;
  const ackoPrice = {}
  let ackoPlanId = ''
  if (planType === "Comprehensive") {
    ackoPlanId = 'car_comprehensive'
  } else if (planType === "Bundled") {
    ackoPlanId = 'car_bundled'
  } else if (planType === "Third Party") {
    ackoPlanId = 'car_tp'
  } else if (planType === "Own Damage") {
    ackoPlanId = 'car_od'
  }

  let ackoPlan = {};

  if (ackoQuotePrice?.plans) {
    ackoPlan = ackoQuotePrice?.plans.find(item => {
      return item.id == ackoPlanId
    })
  }

  function getAddonPrice(addonData, plan_id) {
    if (addonData) {
      let addOnprice = addonData.find(item => {
        return item.id == plan_id
      })
      return addOnprice?.price?.net_premium ? addOnprice?.price?.net_premium : false
    } else {
      return false
    }
  }

  let ackoisPiRequired = false;
  ackoisPiRequired = ackoPlan?.is_pi_required;

  if (carDetails?.prevPolicyType == "Third Party" && planType == "Comprehensive") {
    ackoisPiRequired = true
  }

  //check for addons is_pi_flag_req
  if (!ackoisPiRequired) {
    if (ackoPlan?.addons?.length) {
      for (let index = 0; index < ackoPlan?.addons?.length; index++) {
        const element = ackoPlan?.addons[index];
        switch (element.id) {
          case "zero_depreciation_car":
            if (checkboxTrueFalseObject.zeroDep && element.is_pi_required) {
              ackoisPiRequired = true
            }
            break;
          case "rsa_outstation_keyprotect_combo_car":
            if (
              (checkboxTrueFalseObject.keyLockReplacement ||
                checkboxTrueFalseObject.roadAssistance) && element.is_pi_required
            ) {
              ackoisPiRequired = true
            }
            break;
          case "consumables_car":
            if (checkboxTrueFalseObject.consumables && element.is_pi_required) {
              ackoisPiRequired = true
            }
            break;
          case "engine_protect_car":
            if (checkboxTrueFalseObject.engineProtection && element.is_pi_required) {
              ackoisPiRequired = true
            }
            break;
          case "invoice_car":
            if (checkboxTrueFalseObject.invoiceCover && element.is_pi_required) {
              ackoisPiRequired = true
            }
            break;
          case "ncb_protect_car":
            if (checkboxTrueFalseObject.ncbProtection && element.is_pi_required) {
              ackoisPiRequired = true
            }
            break;
          case "personal_belongings_damage_car":
            if (checkboxTrueFalseObject.lossofPersonalBelongings && element.is_pi_required) {
              ackoisPiRequired = true
            }
            break;
          case "electrical_accessories_own_damage_basic_car":
            if (checkboxTrueFalseObject.accessories && element.is_pi_required) {
              ackoisPiRequired = true
            }
            break;
          case "non_electrical_accessories_own_damage_basic_car":
            if (checkboxTrueFalseObject.accessories && element.is_pi_required) {
              ackoisPiRequired = true
            }
            break;
          case "pa_unnamed_passenger_car":
            if (checkboxTrueFalseObject.passengerCover && element.is_pi_required) {
              ackoisPiRequired = true
            }
            break;
          case "pa_owner_car":
            if (checkboxTrueFalseObject.paCover && element.is_pi_required) {
              ackoisPiRequired = true
            }
            break;
          case "legal_liability_car":
            if (checkboxTrueFalseObject.driverCover && element.is_pi_required) {
              ackoisPiRequired = true
            }
            break;
          default:
            break;
        }
      }
    }
  }

  ackoPrice.basicPremium = ackoPlan?.total_price?.net_premium
  ackoPrice.paCover = checkboxTrueFalseObject.paCover ? getAddonPrice(ackoPlan?.addons, "pa_owner_car") : false;
  ackoPrice.zeroDep = checkboxTrueFalseObject.zeroDep ? getAddonPrice(ackoPlan?.addons, "zero_depreciation_car") : false;
  ackoPrice.consumables = checkboxTrueFalseObject.consumables ? getAddonPrice(ackoPlan?.addons, "consumables_car") : false;
  ackoPrice.roadAssistance = checkboxTrueFalseObject.roadAssistance ? getAddonPrice(ackoPlan?.addons, "rsa_outstation_keyprotect_combo_car") : false;
  ackoPrice.engineProtection = checkboxTrueFalseObject.engineProtection ? getAddonPrice(ackoPlan?.addons, "engine_protect_car") : false;
  ackoPrice.invoiceCover = checkboxTrueFalseObject.invoiceCover ? getAddonPrice(ackoPlan?.addons, "invoice_car") : false;
  ackoPrice.keyLockReplacement = checkboxTrueFalseObject.keyLockReplacement ? getAddonPrice(ackoPlan?.addons, "rsa_outstation_keyprotect_combo_car") : false;
  ackoPrice.driverCover = checkboxTrueFalseObject.driverCover ? getAddonPrice(ackoPlan?.addons, "legal_liability_car") : false;
  ackoPrice.passengerCover = checkboxTrueFalseObject.passengerCover ? getAddonPrice(ackoPlan?.addons, "pa_unnamed_passenger_car") : false;
  ackoPrice.accessories = checkboxTrueFalseObject.accessories ? getAddonPrice(ackoPlan?.addons, "electrical_accessories_own_damage_basic_car") : false;
  ackoPrice.accessories = checkboxTrueFalseObject.accessories ? getAddonPrice(ackoPlan?.addons, "non_electrical_accessories_own_damage_basic_car") : false;
  ackoPrice.personalBelonging = checkboxTrueFalseObject.lossofPersonalBelongings ? getAddonPrice(ackoPlan?.addons, "personal_belongings_damage_car") : false;
  ackoPrice.ncbProtection = checkboxTrueFalseObject.ncbProtection ? getAddonPrice(ackoPlan?.addons, "ncb_protect_car") : false;
  ackoPrice.tyreProtection = false;
  ackoPrice.rimProtection = false;
  ackoPrice.emiProtection = false;
  ackoPrice.dailyAllowance = false;
  ackoPrice.odExternalCngLpg = getAddonPrice(ackoPlan?.covers, "own_damage_cng_car");
  ackoPrice.tpCngLpg = getAddonPrice(ackoPlan?.covers, "tp_cng_car");
  ackoPrice.duePremium = ackoPlan?.total_price?.net_premium + ackoPrice.paCover +
    ackoPrice.zeroDep + ackoPrice.consumables + (ackoPrice.roadAssistance || ackoPrice.keyLockReplacement)
    + ackoPrice.engineProtection + ackoPrice.invoiceCover + ackoPrice.driverCover + ackoPrice.passengerCover
    + ackoPrice.accessories + ackoPrice.personalBelonging + ackoPrice.ncbProtection;

  // **************Zuno Quick quote *************//

  // get response from store
  const zunoQuickQuote = useSelector((state) => {
    return state?.zunoQuickQuote;
  });

  const {
    premiumDetails,
    policyLevelDetails,
    contractDetails,
    commisionDetails,
  } = zunoQuickQuote?.data?.data?.quote || {};

  // zuno quick quote loading
  const zunoQuickQuoteLoading = zunoQuickQuote?.isLoading;
  const zunoIDV = findKeyValue(contractDetails, "systemIdv")

  //  Calculate their value
  let zunoElectricAccessories = 0,
    zunononElectricAccessories = 0,
    zunoPassengerCover = 0,
    zunoDriverCover = 0,
    zunoExtrnalCng = 0;
  if (premiumDetails?.totalODPremium > 0 && premiumDetails?.totalTPPremium > 0) {
    if ((contractDetails[0]?.coverage?.subCoverage)?.length > 0) {
      for (let item of contractDetails[0]?.coverage?.subCoverage) {
        if (item.salesProductTemplateId == "MOSCMF02") {
          zunoElectricAccessories = item.grossPremium;
        } else if (item.salesProductTemplateId == "MOSCMF01") {
          zunononElectricAccessories = item.grossPremium;
        } else if (item.salesProductTemplateId == "MOSCMF03") {
          zunoExtrnalCng = item.grossPremium
        }
      }
    }
  } else if (premiumDetails?.totalODPremium > 0 && premiumDetails?.totalTPPremium == 0 && (contractDetails[0]?.coverage?.subCoverage)?.length > 0) {
    for (let item of contractDetails[0]?.coverage?.subCoverage) {
      if (item.salesProductTemplateId == "MOSCMF02") {
        zunoElectricAccessories = item.grossPremium;
      } else if (item.salesProductTemplateId == "MOSCMF01") {
        zunononElectricAccessories = item.grossPremium;
      } else if (item.salesProductTemplateId == "MOSCMF03") {
        zunoExtrnalCng = item.grossPremium
      }
    }
  } else if (premiumDetails?.totalTPPremium == 0 && Array.isArray(contractDetails?.coverage?.subCoverage)) {
    for (let item of contractDetails?.coverage?.subCoverage) {
      if (item.salesProductTemplateId == "MOSCMF02") {
        zunoElectricAccessories = item.grossPremium;
      } else if (item.salesProductTemplateId == "MOSCMF01") {
        zunononElectricAccessories = item.grossPremium;
      } else if (item.salesProductTemplateId == "MOSCMF03") {
        zunoExtrnalCng = item.grossPremium
      }
    }
  }

  // for passengerZuno, driverZuno , tpCngLpgZuno
  let contractDetailsLength = Array.isArray(contractDetails) ? contractDetails?.length - 1 : 0;
  if (premiumDetails?.totalODPremium > 0 && contractDetailsLength > 0) {
    if ((contractDetails[contractDetailsLength]?.coverage?.subCoverage)?.length > 0) {
      for (let item of contractDetails[contractDetailsLength]?.coverage
        ?.subCoverage) {
        if (item.salesProductTemplateId == "MOSCMF24") {
          zunoPassengerCover = item.totalPremium;
        } else if (item.salesProductTemplateId == "MOSCMF20") {
          zunoDriverCover = item.totalPremium;
        } else if (item.salesProductTemplateId == "MOSCMF17") {
          zunoExtrnalCng = item.totalPremium;
        }
      }
    }
  } else if (premiumDetails?.totalODPremium == 0 && contractDetailsLength == 0) {
    if ((contractDetails?.coverage?.subCoverage)?.length > 0) {
      for (let item of contractDetails?.coverage?.subCoverage) {
        if (item.salesProductTemplateId == "MOSCMF24") {
          zunoPassengerCover = item.totalPremium;
        } else if (item.salesProductTemplateId == "MOSCMF20") {
          zunoDriverCover = item.totalPremium;
        } else if (item.salesProductTemplateId == "MOSCMF17") {
          zunoExtrnalCng = item.totalPremium;
        }
      }
    }
  } else if (premiumDetails?.totalODPremium == 0 && contractDetailsLength > 0) {
    if ((contractDetails[contractDetailsLength]?.coverage?.subCoverage)?.length > 0) {
      for (let item of contractDetails[contractDetailsLength]?.coverage
        ?.subCoverage) {
        if (item.salesProductTemplateId == "MOSCMF24") {
          zunoPassengerCover = item.totalPremium;
        } else if (item.salesProductTemplateId == "MOSCMF20") {
          zunoDriverCover = item.totalPremium;
        } else if (item.salesProductTemplateId == "MOSCMF17") {
          zunoExtrnalCng = item.totalPremium;
        }
      }
    }
  }

  // Zuno price
  const zunoPrice = {
    paCover: (premiumDetails?.totalPApremium > 0)
      ? premiumDetails?.totalPApremium
      : false,
    zeroDep: (premiumDetails?.individualAddOnPremium?.zeroDepreciation > 0)
      ? premiumDetails?.individualAddOnPremium?.zeroDepreciation
      : false,
    consumables: (premiumDetails?.individualAddOnPremium?.consumableCover > 0)
      ? premiumDetails?.individualAddOnPremium?.consumableCover
      : false,
    roadAssistance: (premiumDetails?.individualAddOnPremium?.basicRoadAssistance > 0)
      ? premiumDetails?.individualAddOnPremium?.basicRoadAssistance
      : false,
    engineProtection: (premiumDetails?.individualAddOnPremium?.engineProtect > 0)
      ? premiumDetails?.individualAddOnPremium?.engineProtect
      : false,
    invoiceCover: (premiumDetails?.individualAddOnPremium?.invoiceValueProtect > 0)
      ? premiumDetails?.individualAddOnPremium?.invoiceValueProtect
      : false,
    keyLockReplacement: (premiumDetails?.individualAddOnPremium?.keyReplacement > 0)
      ? premiumDetails?.individualAddOnPremium?.keyReplacement
      : false,
    odExternalCngLpg: Number(zunoExtrnalCng > 0) ? zunoExtrnalCng : false,
    driverCover: Number(zunoDriverCover) > 0 ? zunoDriverCover : false,
    passengerCover: Number(zunoPassengerCover) > 0 ? zunoPassengerCover : false,
    accessories:
      Number(zunoElectricAccessories) + Number(zunononElectricAccessories) > 0
        ? Number(zunoElectricAccessories) + Number(zunononElectricAccessories)
        : false,
    tyreProtection: (premiumDetails?.individualAddOnPremium?.tyreProtect > 0)
      ? premiumDetails?.individualAddOnPremium?.tyreProtect
      : false,
    rimProtection: false,
    lossofPersonalBelongings: (premiumDetails?.individualAddOnPremium?.lossOfPersonalBelongings > 0)
      ? premiumDetails?.individualAddOnPremium?.lossOfPersonalBelongings
      : false,
    emiProtection: false,
    dailyAllowance: false,
    ncbProtection: (premiumDetails?.individualAddOnPremium?.protectionOfNCB > 0)
      ? premiumDetails?.individualAddOnPremium?.protectionOfNCB
      : false,
    totalPremium: premiumDetails?.grossTotalPremium,
    duePremium: premiumDetails?.netTotalPremium,
    basicPremium:
      premiumDetails?.totalODPremium +
      premiumDetails?.totalTPPremium -
      Number(zunoElectricAccessories) -
      Number(zunononElectricAccessories) -
      Number(zunoDriverCover) -
      Number(zunoPassengerCover),

  };

  let totalAddOnzuno = 0;

  for (let key in zunoPrice) {
    if (key && key !== NaN && key !== 'totalPremium' && key !== 'duePremium' && key !== 'basicPremium') {
      if (zunoPrice[key]) {
        let num = Number(zunoPrice[key]);
        totalAddOnzuno += num
      }
    }
  }

  // **************Tata Quick quote *************//
  let tataGetquote
  const tataQuickQuote = useSelector((state) => {
    return state?.tataQuickQuote;
  });

  if ((carDetails.quotePackageName == "PLATINUM" && tataQuickQuote?.data?.data?.plans?.P9)) {
    // planOpted = "PLATINUM"
    // planOptedNo = "P9"
    // alert(carDetails?.quotePackageName);
    tataGetquote = tataQuickQuote?.data?.data?.plans?.P9
  } else if ((carDetails.quotePackageName == "PEARL++" && tataQuickQuote?.data?.data?.plans?.P11)) {
    // planOpted = "PEARL++"
    // planOptedNo = "P11"
    tataGetquote = tataQuickQuote?.data?.data?.plans?.P11
    // alert(carDetails?.quotePackageName);
  } else if (carDetails.quotePackageName == "SAPPHIRE++" && tataQuickQuote?.data?.data?.plans?.P7) {
    // planOpted = "SAPPHIRE++"
    // planOptedNo = "P7"
    tataGetquote = tataQuickQuote?.data?.data?.plans?.P7
  } else if (carDetails.quotePackageName == "SAPPHIRE+" && tataQuickQuote?.data?.data?.plans?.P6) {
    // planOpted = "SAPPHIRE+"
    // planOptedNo = "P6"
    tataGetquote = tataQuickQuote?.data?.data?.plans?.P6
  } else if (carDetails.quotePackageName == "SAPPHIRE" && tataQuickQuote?.data?.data?.plans?.P5) {
    // planOpted = "SAPPHIRE"
    // planOptedNo = "P5"
    tataGetquote = tataQuickQuote?.data?.data?.plans?.P5
  } else if ((carDetails.quotePackageName == "PEARL+" && tataQuickQuote?.data?.data?.plans?.P4)) {
    // planOpted = "PEARL+"
    // planOptedNo = "P4"
    tataGetquote = tataQuickQuote?.data?.data?.plans?.P4
  } else if ((carDetails.quotePackageName == "CORAL" && tataQuickQuote?.data?.data?.plans?.P10)) {
    // planOpted = "CORAL"
    // planOptedNo = "P10"
    tataGetquote = tataQuickQuote?.data?.data?.plans?.P10
  } else if (carDetails.quotePackageName == "PEARL" && tataQuickQuote?.data?.data?.plans?.P3) {
    // planOpted = "PEARL"
    // planOptedNo = "P3"
    tataGetquote = tataQuickQuote?.data?.data?.plans?.P3
  } else if (carDetails.quotePackageName == "GOLD" && tataQuickQuote?.data?.data?.plans?.P2) {
    // planOpted = "GOLD"
    // planOptedNo = "P2"
    tataGetquote = tataQuickQuote?.data?.data?.plans?.P2
  } else if ((carDetails.quotePackageName == "SILVER" && tataQuickQuote?.data?.data?.plans?.P1)) {
    // planOpted = "SILVER"
    // planOptedNo = "P1"
    tataGetquote = tataQuickQuote?.data?.data?.plans?.P1
    // alert(carDetails?.quotePackageName);
  } else {
    tataGetquote = tataQuickQuote?.data?.data?.quote
  }

  // set TATA response to variable
  const tataQuoteLoading = tataQuickQuote?.isLoading;
  const tataPlanPackages = tataQuickQuote?.data?.data?.plans;
  const tataPremiumedetails = tataGetquote?.data[0]?.data?.premium_break_up ? tataGetquote?.data[0]?.data?.premium_break_up : ""
  const tataIDV = tataGetquote?.data[0]?.pol_dlts?.vehicle_idv ? tataGetquote?.data[0]?.pol_dlts?.vehicle_idv : "";

  // TATA price
  const tataPrice = {
    paCover: (tataPremiumedetails?.total_tp_premium?.pa_owner_prem > 0)
      ? tataPremiumedetails?.total_tp_premium?.pa_owner_prem
      : false,
    zeroDep: (tataPremiumedetails?.total_addOns?.dep_reimburse_prem > 0)
      ? tataPremiumedetails?.total_addOns?.dep_reimburse_prem
      : false,
    consumables: (tataPremiumedetails?.total_addOns?.consumbale_expense_prem > 0)
      ? tataPremiumedetails?.total_addOns?.consumbale_expense_prem
      : false,
    roadAssistance: (tataPremiumedetails?.total_addOns?.rsa_prem > 0)
      ? tataPremiumedetails?.total_addOns?.rsa_prem
      : false,
    engineProtection: (tataPremiumedetails?.total_addOns?.engine_secure_prem > 0)
      ? tataPremiumedetails?.total_addOns?.engine_secure_prem
      : false,
    invoiceCover: (tataPremiumedetails?.total_addOns?.return_invoice_prem > 0)
      ? tataPremiumedetails?.total_addOns?.return_invoice_prem
      : false,
    keyLockReplacement: (tataPremiumedetails?.total_addOns?.key_replace_prem > 0)
      ? tataPremiumedetails?.total_addOns?.key_replace_prem
      : false,
    odExternalCngLpg: (tataPremiumedetails?.total_od_premium?.od?.cng_lpg_od_prem > 0) ? tataPremiumedetails?.total_od_premium?.od?.cng_lpg_od_prem : false,
    driverCover: tataPremiumedetails?.total_tp_premium?.ll_paid_prem > 0 ? tataPremiumedetails?.total_tp_premium?.ll_paid_prem : false,
    passengerCover: tataPremiumedetails?.total_tp_premium?.pa_unnamed_prem > 0 ? tataPremiumedetails?.total_tp_premium?.pa_unnamed_prem : false,
    accessories:
      tataPremiumedetails?.total_od_premium?.od?.electrical_prem > 0
        ? tataPremiumedetails?.total_od_premium?.od?.electrical_prem
        : false,
    tyreProtection: (tataPremiumedetails?.total_addOns?.tyre_secure_prem > 0)
      ? tataPremiumedetails?.total_addOns?.tyre_secure_prem
      : false,
    rimProtection: false,
    lossofPersonalBelongings: (tataPremiumedetails?.total_addOns?.personal_loss_prem > 0)
      ? tataPremiumedetails?.total_addOns?.personal_loss_prem
      : false,
    emiProtection: false,
    dailyAllowance: false,
    ncbProtection: (tataPremiumedetails?.total_addOns?.ncb_protection_prem > 0)
      ? tataPremiumedetails?.total_addOns?.ncb_protection_prem
      : false,
    totalPremium: tataPremiumedetails?.net_premium,
    duePremium: tataPremiumedetails?.final_premium - (checkboxTrueFalseObject.paCover == false ? +(tataPremiumedetails?.total_tp_premium?.pa_owner_prem) : 0)
      - (checkboxTrueFalseObject.passengerCover == false ? +(tataPremiumedetails?.total_tp_premium?.pa_unnamed_prem) : 0)
      - (checkboxTrueFalseObject.driverCover == false ? +(tataPremiumedetails?.total_tp_premium?.ll_paid_prem) : 0)
      - ((planType == 'ABC' && !carDetails.quotePackageName) ? +(tataPremiumedetails?.total_addOns?.total_addon) : 0)
      - (checkboxTrueFalseObject.ncbProtection == false ? +(tataPremiumedetails?.total_addOns?.ncb_protection_prem) : 0),
    basicPremium: Number(tataPremiumedetails?.total_od_premium?.total_od) + Number(tataPremiumedetails?.total_tp_premium?.basic_tp),
  };

  // ************** United Quick quote *************//
  const unitedQuickQuote = useSelector((state) => {
    return state?.unitedQuickQuote;
  });
  // console.log(unitedQuickQuote, "unitedQuickQuote");

  // set value to TATA variable
  const unitedQuoteLoading = unitedQuickQuote?.isLoading;
  const unitedPremiumedetails = unitedQuickQuote?.data?.data?.quote?.ROOT?.HEADER ? unitedQuickQuote?.data?.data?.quote?.ROOT?.HEADER : ""
  const unitedAddonsValue = unitedQuickQuote?.data?.data?.availableAddons
  const unitedIDV = unitedQuickQuote?.data?.data?.quote?.ROOT?.HEADER?.NUM_IEV_BASE_VALUE ? unitedQuickQuote?.data?.data?.quote?.ROOT?.HEADER?.NUM_IEV_BASE_VALUE : "";
  const calAccessories = (
    (Array.isArray(unitedAddonsValue) && (unitedAddonsValue?.map((o) => o.PropCoverDetails_CoverGroups).includes("Electrical or Electronic Accessories")) ? Number(unitedAddonsValue[unitedAddonsValue.map((o) => o.PropCoverDetails_CoverGroups).indexOf("Electrical or Electronic Accessories")]["PropCoverDetails_Premium"]) : 0)
    + (Array.isArray(unitedAddonsValue) && (unitedAddonsValue?.map((o) => o.PropCoverDetails_CoverGroups).includes("Non-Electrical Accessories")) ? Number(unitedAddonsValue[unitedAddonsValue.map((o) => o.PropCoverDetails_CoverGroups).indexOf("Non-Electrical Accessories")]["PropCoverDetails_Premium"]) : 0)
  )
  const calExternalCng = (
    (Array.isArray(unitedAddonsValue) && (unitedAddonsValue?.map((o) => o.PropCoverDetails_CoverGroups).includes("CNG Kit-OD")) ? Number(unitedAddonsValue[unitedAddonsValue.map((o) => o.PropCoverDetails_CoverGroups).indexOf("CNG Kit-OD")]["PropCoverDetails_Premium"]) : 0)
    + (Array.isArray(unitedAddonsValue) && (unitedAddonsValue?.map((o) => o.PropCoverDetails_CoverGroups).includes("CNG Kit-TP")) ? Number(unitedAddonsValue[unitedAddonsValue.map((o) => o.PropCoverDetails_CoverGroups).indexOf("CNG Kit-TP")]["PropCoverDetails_Premium"]) : 0)
  )
  // console.log(unitedAddonsValue, "united value value");

  // UNITED price
  const unitedPrice = {
    paCover: (Array.isArray(unitedAddonsValue) && unitedAddonsValue?.map((o) => o.PropCoverDetails_CoverGroups).includes("PA Owner Driver")) ? Number(unitedAddonsValue[unitedAddonsValue.map((o) => o.PropCoverDetails_CoverGroups).indexOf("PA Owner Driver")]["PropCoverDetails_Premium"]) : false,
    zeroDep: (Array.isArray(unitedAddonsValue) && unitedAddonsValue?.map((o) => o.PropCoverDetails_CoverGroups).includes("ZeroDepth")) ? Number(unitedAddonsValue[unitedAddonsValue.map((o) => o.PropCoverDetails_CoverGroups).indexOf("ZeroDepth")]["PropCoverDetails_Premium"]) : false,
    consumables: (Array.isArray(unitedAddonsValue) && unitedAddonsValue?.map((o) => o.PropCoverDetails_CoverGroups).includes("Consumables Cover")) ? Number(unitedAddonsValue[unitedAddonsValue.map((o) => o.PropCoverDetails_CoverGroups).indexOf("Consumables Cover")]["PropCoverDetails_Premium"]) : false,
    roadAssistance: (Array.isArray(unitedAddonsValue) && unitedAddonsValue?.map((o) => o.PropCoverDetails_CoverGroups).includes("Road Side Assitance")) ? Number(unitedAddonsValue[unitedAddonsValue.map((o) => o.PropCoverDetails_CoverGroups).indexOf("Road Side Assitance")]["PropCoverDetails_Premium"]) : false,
    engineProtection: (Array.isArray(unitedAddonsValue) && unitedAddonsValue?.map((o) => o.PropCoverDetails_CoverGroups).includes("Engine and Gearbox Protection Platinum AddOn Cover")) ? Number(unitedAddonsValue[unitedAddonsValue.map((o) => o.PropCoverDetails_CoverGroups).indexOf("Engine and Gearbox Protection Platinum AddOn Cover")]["PropCoverDetails_Premium"]) : false,
    invoiceCover: (Array.isArray(unitedAddonsValue) && unitedAddonsValue?.map((o) => o.PropCoverDetails_CoverGroups).includes("Return To Invoice")) ? Number(unitedAddonsValue[unitedAddonsValue.map((o) => o.PropCoverDetails_CoverGroups).indexOf("Return To Invoice")]["PropCoverDetails_Premium"]) : false,
    keyLockReplacement: (Array.isArray(unitedAddonsValue) && unitedAddonsValue?.map((o) => o.PropCoverDetails_CoverGroups).includes("Loss Of Key Cover")) ? Number(unitedAddonsValue[unitedAddonsValue.map((o) => o.PropCoverDetails_CoverGroups).indexOf("Loss Of Key Cover")]["PropCoverDetails_Premium"]) : false,
    odExternalCngLpg: calExternalCng > 0 ? true : false,
    driverCover: unitedPremiumedetails?.CUR_TOTAL_LL_PREMIUM,
    passengerCover: (Array.isArray(unitedAddonsValue) && unitedAddonsValue?.map((o) => o.PropCoverDetails_CoverGroups).includes("Personal Accident Cover-Unnamed")) ? Number(unitedAddonsValue[unitedAddonsValue.map((o) => o.PropCoverDetails_CoverGroups).indexOf("Personal Accident Cover-Unnamed")]["PropCoverDetails_Premium"]) : false,
    accessories: (calAccessories > 0) ? true : false,
    tyreProtection: (Array.isArray(unitedAddonsValue) && unitedAddonsValue?.map((o) => o.PropCoverDetails_CoverGroups).includes("Tyre And Rim Protector Cover")) ? Number(unitedAddonsValue[unitedAddonsValue.map((o) => o.PropCoverDetails_CoverGroups).indexOf("Tyre And Rim Protector Cover")]["PropCoverDetails_Premium"]) : false,
    rimProtection: false,
    lossofPersonalBelongings: (Array.isArray(unitedAddonsValue) && unitedAddonsValue?.map((o) => o.PropCoverDetails_CoverGroups).includes("Loss Of Personal Belonging")) ? Number(unitedAddonsValue[unitedAddonsValue.map((o) => o.PropCoverDetails_CoverGroups).indexOf("Loss Of Personal Belonging")]["PropCoverDetails_Premium"]) : false,
    emiProtection: false,
    dailyAllowance: false,
    ncbProtection: (Array.isArray(unitedAddonsValue) && unitedAddonsValue?.map((o) => o.PropCoverDetails_CoverGroups).includes("Ncb Protection")) ? Number(unitedAddonsValue[unitedAddonsValue.map((o) => o.PropCoverDetails_CoverGroups).indexOf("Ncb Protection")]["PropCoverDetails_Premium"]) : false,
    totalPremium: unitedPremiumedetails?.CUR_FINAL_TOTAL_PREMIUM,
    duePremium: ((planType !== "Third Party") ? ((unitedPremiumedetails?.TXT_PRODUCT_USERDATA?.WorkSheet?.PropLoadingDiscount_Col?.LoadingDiscount?.PropLoadingDiscount_Premium) ?
      +(unitedPremiumedetails?.TXT_PRODUCT_USERDATA?.WorkSheet?.PropLoadingDiscount_Col?.LoadingDiscount?.PropLoadingDiscount_Premium)
      : Number(unitedPremiumedetails?.CUR_BASIC_OD_PREMIUM) + Number(calAccessories) + Number(calExternalCng)) : 0)
      - ((unitedPremiumedetails?.TXT_PRODUCT_USERDATA?.WorkSheet?.PropLoadingDiscount_Col?.LoadingDiscount?.PropLoadingDiscount_EndorsementAmount)
        ? Number(unitedPremiumedetails?.TXT_PRODUCT_USERDATA?.WorkSheet?.PropLoadingDiscount_Col?.LoadingDiscount?.PropLoadingDiscount_EndorsementAmount) : 0)
      + Number(unitedPremiumedetails?.CUR_BASIC_TP_PREMIUM)
      + ((planType !== "Own Damage") ? (+(unitedPremiumedetails?.NUM_TOTAL_ADDITION_OF_TP_PREM)
        - Number(unitedPremiumedetails?.CUR_TOTAL_LL_PREMIUM)
        - Number(unitedPremiumedetails?.CUR_TOTAL_PA_PREMIUM)) : 0),
    basicPremium: ((planType !== "Third Party") ? (Number(unitedPremiumedetails?.CUR_BASIC_OD_PREMIUM)
      - (unitedPremiumedetails?.TXT_PRODUCT_USERDATA?.WorkSheet?.PropLoadingDiscount_Col?.LoadingDiscount?.PropLoadingDiscount_Rate ? ((+(unitedPremiumedetails?.TXT_PRODUCT_USERDATA?.WorkSheet?.PropLoadingDiscount_Col?.LoadingDiscount?.PropLoadingDiscount_Rate) / 100)
        * Number(unitedPremiumedetails?.CUR_BASIC_OD_PREMIUM))
        : 0))
      : 0)
      + Number(unitedPremiumedetails?.CUR_BASIC_TP_PREMIUM),
  };
  // console.log(unitedPrice, "unitedPriceunitedPrice");

  // ************** national Quick quote *************//
  const nationalQuickQuote = useSelector((state) => {
    return state?.nationalQuickQuote;
  });
  // console.log(nationalQuickQuote?.data?.data?.quote?.PolicyObject?.PolicyLobList[0]?.PolicyRiskList[0]?.BasicIDV, "nationalQuickQuote");

  // set value to NATIONAL variable
  const nationalQuoteLoading = nationalQuickQuote?.isLoading;
  const nationalPremiumedetails = nationalQuickQuote?.data?.data?.addonsValue ? nationalQuickQuote?.data?.data?.addonsValue : ""
  const nationalIDV = nationalQuickQuote?.data?.data?.quote?.PolicyObject?.PolicyLobList[0]?.PolicyRiskList[0]?.BasicIDV ? nationalQuickQuote?.data?.data?.quote?.PolicyObject?.PolicyLobList[0]?.PolicyRiskList[0]?.BasicIDV : "";
  // console.log("nationalPremiumedetails", nationalQuickQuote?.data?.data?.quote?.PolicyObject?.DuePremium);


  // NATIONAL price
  const nationalPrice = {
    paCover: (Array.isArray(nationalPremiumedetails) && nationalPremiumedetails?.map((o) => o.CoverageName).includes("CompulsoryPA")) ? Number(nationalPremiumedetails[nationalPremiumedetails.map((o) => o.CoverageName).indexOf("CompulsoryPA")]["GrossPremium"]) : false,
    zeroDep: (Array.isArray(nationalPremiumedetails) && nationalPremiumedetails?.map((o) => o.CoverageName).includes("Nil Depreciation")) ? Number(nationalPremiumedetails[nationalPremiumedetails.map((o) => o.CoverageName).indexOf("Nil Depreciation")]["GrossPremium"]) : false,
    consumables: (Array.isArray(nationalPremiumedetails) && nationalPremiumedetails?.map((o) => o.CoverageName).includes("Consumables Protect")) ? Number(nationalPremiumedetails[nationalPremiumedetails.map((o) => o.CoverageName).indexOf("Consumables Protect")]["GrossPremium"]) : false,
    roadAssistance: (Array.isArray(nationalPremiumedetails) && nationalPremiumedetails?.map((o) => o.CoverageName).includes("Road Side Assistance")) ? Number(nationalPremiumedetails[nationalPremiumedetails.map((o) => o.CoverageName).indexOf("Road Side Assistance")]["GrossPremium"]) : false,
    engineProtection: (Array.isArray(nationalPremiumedetails) && nationalPremiumedetails?.map((o) => o.CoverageName).includes("Engine Protect")) ? Number(nationalPremiumedetails[nationalPremiumedetails.map((o) => o.CoverageName).indexOf("Engine Protect")]["GrossPremium"]) : false,
    invoiceCover: false,
    keyLockReplacement: (Array.isArray(nationalPremiumedetails) && nationalPremiumedetails?.map((o) => o.CoverageName).includes("Lock & Key Protect")) ? Number(nationalPremiumedetails[nationalPremiumedetails.map((o) => o.CoverageName).indexOf("Lock & Key Protect")]["GrossPremium"]) : false,
    odExternalCngLpg: false,
    driverCover: (Array.isArray(nationalPremiumedetails) && nationalPremiumedetails?.map((o) => o.CoverageName).includes("Optional PA for Paid Driver/Cleaner/Conductor")) ? Number(nationalPremiumedetails[nationalPremiumedetails.map((o) => o.CoverageName).indexOf("Optional PA for Paid Driver/Cleaner/Conductor")]["GrossPremium"]) : false,
    passengerCover: (Array.isArray(nationalPremiumedetails) && nationalPremiumedetails?.map((o) => o.CoverageName).includes("Optional PA for - Un-Named")) ? Number(nationalPremiumedetails[nationalPremiumedetails.map((o) => o.CoverageName).indexOf("Optional PA for - Un-Named")]["GrossPremium"]) : false,
    accessories: (Array.isArray(nationalPremiumedetails) && (nationalPremiumedetails?.map((o) => o.CoverageName).includes("Electrical Accessories") || nationalPremiumedetails?.map((o) => o.CoverageName).includes("Non Electrical Accessories"))) ? true : false,
    tyreProtection: false,
    rimProtection: false,
    lossofPersonalBelongings: (Array.isArray(nationalPremiumedetails) && nationalPremiumedetails?.map((o) => o.CoverageName).includes("Loss of Belongings")) ? Number(nationalPremiumedetails[nationalPremiumedetails.map((o) => o.CoverageName).indexOf("Loss of Belongings")]["GrossPremium"]) : false,
    emiProtection: (Array.isArray(nationalPremiumedetails) && nationalPremiumedetails?.map((o) => o.CoverageName).includes("EMI Protect")) ? Number(nationalPremiumedetails[nationalPremiumedetails.map((o) => o.CoverageName).indexOf("EMI Protect")]["GrossPremium"]) : false,
    dailyAllowance: (Array.isArray(nationalPremiumedetails) && nationalPremiumedetails?.map((o) => o.CoverageName).includes("Daily Allowance")) ? Number(nationalPremiumedetails[nationalPremiumedetails.map((o) => o.CoverageName).indexOf("Daily Allowance")]["GrossPremium"]) : false,
    ncbProtection: (Array.isArray(nationalPremiumedetails) && nationalPremiumedetails?.map((o) => o.CoverageName).includes("NCB Protect")) ? Number(nationalPremiumedetails[nationalPremiumedetails.map((o) => o.CoverageName).indexOf("NCB Protect")]["GrossPremium"]) : false,
    totalPremium: nationalQuickQuote?.data?.data?.quote?.PolicyObject?.DuePremium,
    duePremium: ((Array.isArray(nationalPremiumedetails) && nationalPremiumedetails?.map((o) => o.CoverageName).includes("Own Damage Basic - Package")) ? Number(nationalPremiumedetails[nationalPremiumedetails.map((o) => o.CoverageName).indexOf("Own Damage Basic - Package")]["BeforeVatPremium"]) : 0) + ((Array.isArray(nationalPremiumedetails) && nationalPremiumedetails?.map((o) => o.CoverageName).includes("Legal Liability to Third Party")) ? Number(nationalPremiumedetails[nationalPremiumedetails.map((o) => o.CoverageName).indexOf("Legal Liability to Third Party")]["GrossPremium"]) : 0),
    basicPremium: ((Array.isArray(nationalPremiumedetails) && nationalPremiumedetails?.map((o) => o.CoverageName).includes("Own Damage Basic - Package")) ? Number(nationalPremiumedetails[nationalPremiumedetails.map((o) => o.CoverageName).indexOf("Own Damage Basic - Package")]["BeforeVatPremium"]) : 0) + ((Array.isArray(nationalPremiumedetails) && nationalPremiumedetails?.map((o) => o.CoverageName).includes("Legal Liability to Third Party")) ? Number(nationalPremiumedetails[nationalPremiumedetails.map((o) => o.CoverageName).indexOf("Legal Liability to Third Party")]["GrossPremium"]) : 0),
  };
  // console.log("nationalPrice", nationalPrice);

  // to display amount with commas use this in future:-----> .toLocaleString('en-IN')

  let quotesContent = [
    // Acko
    {
      id: 1,
      companyName: ACKO,
      companyImg: acko,
      isInspection: inspectDetails && ackoisPiRequired,
      showInspectionTab: planType !== "Bundled" && planType !== "Third Party",
      loadingSate: ackoQuoteLoading || ackoPrice.duePremium === undefined || ackoPrice.duePremium === 0 || ShowSkeleton || isNaN(ackoPrice.duePremium),
      idv: ackoIDV?.toLocaleString('en-IN'),
      premiumValue: isNaN(ackoPrice.duePremium) ? 0 : ackoPrice.duePremium?.toLocaleString('en-IN'),
      basicPremiumValue: Math.round(ackoPrice?.basicPremium)?.toLocaleString('en-IN'),
      paCoverValue: Math.round(ackoPrice.paCover)?.toLocaleString('en-IN'),
      isExternalFittedCng: ackoPrice?.tpCngLpg,
      zeroDepValue: Math.round(ackoPrice?.zeroDep)?.toLocaleString('en-IN'),
      shreeramProtctValue: 0,
      engProtectValue: ackoPrice?.engineProtection,
      consumableValue: ackoPrice?.consumables,
      isKeyLockChecked: checkboxTrueFalseObject.keyLockReplacement || checkboxTrueFalseObject.roadAssistance,
      isKeyLockValue: ackoPrice?.keyLockReplacement || ackoPrice?.roadAssistance,
      invoiceCoverValue: ackoPrice?.invoiceCover,
      isRoadAssitanceChecked: checkboxTrueFalseObject.keyLockReplacement || checkboxTrueFalseObject.roadAssistance,
      roadAssitanceValue: ackoPrice?.keyLockReplacement || ackoPrice?.roadAssistance,
      tyreProtectionValue: ackoPrice?.tyreProtection,
      personalBelongingValue: ackoPrice?.personalBelonging,
      dailyAllowanceValue: ackoPrice.dailyAllowance,
      passengerCoverValue: ackoPrice.passengerCover,
      ncbProtectionValue: ackoPrice?.ncbProtection,
      accessoriesValue: ackoPrice?.accessories,
      driverCoverValue: ackoPrice.driverCover,
      showPlanDetailsTab: !ackoQuoteLoading && ackoPrice.duePremium && !ShowSkeleton && !isNaN(ackoPrice.duePremium),
      quickQuote: ackoQuote,
      totalAddonValue: 0,
      handleBuyNow: (e) => handleBuyNow(acko, "", ACKO),
      vehicleBreakupPrice: ackoPrice,
    },
    // Sbi
    {
      id: 2,
      companyName: SBI,
      companyImg: sbi,
      isInspection: inspectDetails,
      showInspectionTab: planType !== "Bundled" && planType !== "Third Party",
      loadingSate: sbiQuickQuoteLoading || sbiPrice.duePremium === undefined || sbiPrice.duePremium == 0 || ShowSkeleton,
      idv: Math.round(sbiPrice.idv)?.toLocaleString('en-IN'),
      premiumValue: ((planType == 'Own Damage' ? Math.round(sbiPrice.basicPremium) : planType == 'Comprehensive' ? Math.round(sbiPrice?.duePremium) - Math.round(sbiPrice.totalAddonValue) : Math.round(sbiPrice?.basicPremium) + Math.round(sbiPrice.paidDriver ? sbiPrice.paidDriver : 0))),
      basicPremiumValue: Math.round(sbiPrice.basicPremium)?.toLocaleString('en-IN'),
      paCoverValue: Math.round(sbiPrice?.paCover)?.toLocaleString('en-IN'),
      isExternalFittedCng: sbiPrice?.odExternalCngLpg,
      zeroDepValue: Math.round(sbiPrice?.zeroDep)?.toLocaleString('en-IN'),
      shreeramProtctValue: 0,
      engProtectValue: sbiPrice?.engineProtection,
      consumableValue: sbiPrice?.consumables,
      isKeyLockChecked: checkboxTrueFalseObject.keyLockReplacement,
      isKeyLockValue: sbiPrice?.keyLockReplacement,
      invoiceCoverValue: sbiPrice?.invoiceCover,
      isRoadAssitanceChecked: checkboxTrueFalseObject.roadAssistance,
      roadAssitanceValue: sbiPrice?.roadAssistance,
      tyreProtectionValue: sbiPrice?.tyreProtection,
      personalBelongingValue: sbiPrice.lossofPersonalBelongings,
      dailyAllowanceValue: sbiPrice.dailyAllowance,
      passengerCoverValue: sbiPrice.passengerCover,
      ncbProtectionValue: sbiPrice.ncbProtection,
      accessoriesValue: sbiPrice.accessories,
      driverCoverValue: sbiPrice.driverCover,
      showPlanDetailsTab: !sbiQuickQuoteLoading && sbiPrice.duePremium && !ShowSkeleton,
      quickQuote: sbiQuickQuote,
      totalAddonValue: 0,
      handleBuyNow: (e) => handleBuyNow(sbi, sbiQuickQuote, SBI),
      vehicleBreakupPrice: sbiPrice,
    },
    // Icici
    {
      id: 3,
      companyName: ICICI,
      companyImg: icici,
      isInspection: inspectDetails,
      showInspectionTab: planType !== "Bundled" && planType !== "Third Party",
      loadingSate: iciciQuoteLoading || iciciPrice.duePremium === undefined || iciciPrice.duePremium === 0 || ShowSkeleton,
      idv: Math.round(iciciIDV)?.toLocaleString('en-IN'),
      premiumValue: (Math.round(iciciPrice.duePremium) - Math.round(iciciPrice.totalAddOn)),
      basicPremiumValue: Math.round(iciciPrice?.basicPremium)?.toLocaleString('en-IN') || 0,
      paCoverValue: Math.round(iciciPrice?.paCover)?.toLocaleString('en-IN') || 0,
      isExternalFittedCng: iciciPrice?.odExternalCngLpg,
      zeroDepValue: Math.round(iciciPrice?.zeroDep)?.toLocaleString('en-IN') || 0,
      shreeramProtctValue: 0,
      packagesNameICIC: carDetails.quotePackageNameICICI ? carDetails.quotePackageNameICICI : undefined,
      engProtectValue: iciciPrice?.engineProtection,
      engProtectValuePlan: (carDetails.quotePackageNameICICI == "RSA With Key 2" || carDetails.quotePackageNameICICI == "RSA With Key 4" || carDetails.quotePackageNameICICI == "RSA With Key 5" || carDetails.quotePackageNameICICI == "RSA Plus 2" || carDetails.quotePackageNameICICI == "RSA Plus 4" || carDetails.quotePackageNameICICI == "RSA Plus 5") && iciciPrice?.engineProtection ? true : false,
      consumableValue: iciciPrice?.consumables,
      isKeyLockChecked: checkboxTrueFalseObject.keyLockReplacement,
      isKeyLockValue: iciciPrice?.keyLockReplacement,
      invoiceCoverValue: iciciPrice?.invoiceCover,
      invoiceCoverValuePlan: (carDetails.quotePackageNameICICI == "RSA With Key 5" || carDetails.quotePackageNameICICI == "RSA Plus 5") && iciciPrice?.invoiceCover ? true : false,
      isRoadAssitanceChecked: checkboxTrueFalseObject.roadAssistance,
      roadAssitanceValue: iciciPrice?.roadAssistance,
      tyreProtectionValue: iciciPrice?.tyreProtection,
      tyreProtectionValuePlan: (carDetails.quotePackageNameICICI == "RSA With Key 3" || carDetails.quotePackageNameICICI == "RSA With Key 4" || carDetails.quotePackageNameICICI == "RSA With Key 5" || carDetails.quotePackageNameICICI == "RSA Plus 3" || carDetails.quotePackageNameICICI == "RSA Plus 4" || carDetails.quotePackageNameICICI == "RSA Plus 5") && iciciPrice?.tyreProtection ? true : false,
      personalBelongingValue: iciciPrice?.personalBelonging,
      personalBelongingPlan: (carDetails.quotePackageNameICICI == "RSA Plus 1" || carDetails.quotePackageNameICICI == "RSA Plus 2" || carDetails.quotePackageNameICICI == "RSA Plus 3" || carDetails.quotePackageNameICICI == "RSA Plus 4" || carDetails.quotePackageNameICICI == "RSA Plus 5") && iciciPrice?.personalBelonging ? true : false,

      dailyAllowanceValue: iciciPrice.dailyAllowance,
      passengerCoverValue: iciciPrice.passengerCover,
      ncbProtectionValue: iciciPrice?.ncbProtection,
      accessoriesValue: iciciPrice?.accessories,
      driverCoverValue: iciciPrice?.driverCover,
      showPlanDetailsTab: !iciciQuoteLoading && iciciPrice.duePremium && !ShowSkeleton,
      quickQuote: iciciQuote,

      handleBuyNow: (e) => handleBuyNow(icici, "", ICICI),
      vehicleBreakupPrice: iciciPrice,
    },
    // Zuno
    {
      id: 4,
      companyName: ZUNO,
      companyImg: zuno,
      isInspection: inspectDetails,
      showInspectionTab: planType !== "Bundled" && planType !== "Third Party",
      loadingSate: zunoQuickQuoteLoading || zunoPrice.duePremium === undefined || zunoPrice.duePremium === 0 || ShowSkeleton,
      idv: Math.round(zunoIDV)?.toLocaleString('en-IN'),
      premiumValue: planType !== 'Third Party' ? Math.round(Number(zunoPrice?.duePremium) - totalAddOnzuno) : Math.round(Number(zunoPrice?.basicPremium)),
      basicPremiumValue: Math.round(zunoPrice?.basicPremium)?.toLocaleString('en-IN') || 0,
      paCoverValue: Math.round(zunoPrice?.paCover)?.toLocaleString('en-IN') || 0,
      isExternalFittedCng: zunoPrice?.odExternalCngLpg,
      zeroDepValue: Math.round(zunoPrice?.zeroDep)?.toLocaleString('en-IN') || 0,
      shreeramProtctValue: 0,
      engProtectValue: zunoPrice?.engineProtection,
      consumableValue: zunoPrice?.consumables,
      isKeyLockChecked: checkboxTrueFalseObject.keyLockReplacement,
      isKeyLockValue: zunoPrice?.keyLockReplacement,
      invoiceCoverValue: zunoPrice?.invoiceCover,
      isRoadAssitanceChecked: checkboxTrueFalseObject.roadAssistance,
      roadAssitanceValue: zunoPrice?.roadAssistance,
      tyreProtectionValue: zunoPrice?.tyreProtection,
      personalBelongingValue: zunoPrice?.lossofPersonalBelongings,
      dailyAllowanceValue: zunoPrice?.dailyAllowance,
      passengerCoverValue: zunoPrice?.passengerCover,
      ncbProtectionValue: zunoPrice?.ncbProtection,
      accessoriesValue: zunoPrice?.accessories,
      driverCoverValue: zunoPrice?.driverCover,
      showPlanDetailsTab: !zunoQuickQuoteLoading && zunoPrice.duePremium && !ShowSkeleton,
      quickQuote: zunoQuickQuote,

      handleBuyNow: (e) => handleBuyNow(zuno, "", ZUNO),
      vehicleBreakupPrice: zunoPrice,
    },
    // Shreeram
    {
      id: 5,
      companyName: SHREERAM,
      companyImg: shreeRam,
      isInspection: inspectDetails,
      showInspectionTab: planType !== "Bundled" && planType !== "Third Party",
      loadingSate: shreeRamQuoteLoading || shreeRamPrice?.totalPremium === undefined || shreeRamPrice?.totalPremium === 0 || ShowSkeleton || shreeRamPrice?.totalPremium === false,
      idv: Math.round(shreeRamQuotePrice?.VehicleIDV)?.toLocaleString('en-IN'),
      premiumValue: ((planType !== 'Third Party' ? Math.round(shreeRamPrice?.totalPremium) - Math.round(shreeRamPrice.totalAddonValue) : Math.round(shreeRamPrice?.totalPremium) - Math.round(shreeRamPrice.totalAddonValue))),
      basicPremiumValue: Math.round(shreeRamPrice?.basicPremium)?.toLocaleString('en-IN'),
      paCoverValue: Math.round(shreeRamPrice?.paCover)?.toLocaleString('en-IN'),
      isExternalFittedCng: shreeRamPrice.odExternalCngLpg,
      zeroDepValue: Math.round(shreeRamPrice?.zeroDep)?.toLocaleString('en-IN'),
      shreeramProtctValue: shreeRamPrice?.shreeramProtect,
      engProtectValue: shreeRamPrice?.engineProtection,
      consumableValue: shreeRamPrice?.consumables,
      isKeyLockChecked: checkboxTrueFalseObject.keyLockReplacement,
      isKeyLockValue: shreeRamPrice?.keyLockReplacement,
      invoiceCoverValue: shreeRamPrice?.invoiceCover,
      isRoadAssitanceChecked: checkboxTrueFalseObject.roadAssistance,
      roadAssitanceValue: shreeRamPrice?.roadAssistance,
      tyreProtectionValue: shreeRamPrice?.tyreProtection,
      personalBelongingValue: shreeRamPrice?.lossofPersonalBelongings,
      dailyAllowanceValue: shreeRamPrice?.dailyAllowance,
      passengerCoverValue: shreeRamPrice?.passengerCover,
      ncbProtectionValue: shreeRamPrice?.ncbProtection,
      accessoriesValue: shreeRamPrice?.accessories,
      driverCoverValue: shreeRamPrice?.driverCover,
      showPlanDetailsTab: !shreeRamQuoteLoading && shreeRamPrice?.totalPremium && !ShowSkeleton && shreeRamPrice?.totalPremium !== false,
      quickQuote: shreeRamQuote,

      handleBuyNow: (e) => handleBuyNow(shreeRam, " ", SHREERAM),
      vehicleBreakupPrice: shreeRamPrice,
    },
    // Digit
    {
      id: 6,
      companyName: DIGIT,
      companyImg: digit,
      isInspection: inspectDetails,
      showInspectionTab: planType !== "Bundled" && planType !== "Third Party",
      loadingSate: digitQuoteLoading || digitPrice.duePremium === undefined || digitPrice.duePremium === 0 || ShowSkeleton || planType !== "Third Party" && !digitIDV,
      idv: Math.round(digitIDV?.idv)?.toLocaleString('en-IN'),
      premiumValue: Math.round(digitPrice.duePremium) - Math.round(digitPrice.totalAddOnDigi),
      basicPremiumValue: digitPrice?.basicPremium || 0,
      paCoverValue: digitPrice?.paCover || 0,
      packagesNameDigit: carDetails.quotePackageNameDigit,
      isExternalFittedCng: "", // shreeRamPrice.odExternalCngLpg
      zeroDepValue: digitPrice?.zeroDep || 0,
      zeroDep: digitPrice?.zeroDep ? true : false,
      shreeramProtctValue: 0,
      engProtectValue: digitPrice?.engineProtection,
      engineProtection: digitPrice?.engineProtection ? true : false,
      consumableValue: digitPrice?.consumables,
      consumables: digitPrice?.consumables ? true : false,
      isKeyLockCheckedDig: digitPrice?.keyLockReplacement ? true : false,
      isKeyLockValue: digitPrice?.keyLockReplacement,
      invoiceCoverValue: digitPrice?.invoiceCover,
      invoiceCover: digitPrice?.invoiceCover ? true : false,
      isRoadAssitanceChecked: digitPrice?.roadAssistance ? true : false,
      roadAssitanceValue: digitPrice?.roadAssistance,
      tyreProtectionValue: digitPrice?.tyreProtection,
      tyreProtectionCheck: digitPrice?.tyreProtection ? true : false,
      personalBelongingValue: digitPrice?.personalBelonging,
      personalBelongingCheck: digitPrice?.personalBelonging ? true : false,
      dailyAllowanceValue: digitPrice.dailyAllowance,
      passengerCoverValue: digitPrice.passengerCover,
      ncbProtectionValue: digitPrice?.ncbProtection,
      accessoriesValue: digitPrice?.accessories,
      driverCoverValue: digitPrice.driverCover,
      showPlanDetailsTab: !digitQuoteLoading && digitPrice.duePremium && !ShowSkeleton,
      quickQuote: digitQuote,

      handleBuyNow: (e) => handleBuyNow(digit, "", DIGIT),
      vehicleBreakupPrice: digitPrice,
    },
    // Hdfc
    {
      id: 7,
      companyName: HDFC,
      companyImg: hdfc,
      isInspection: inspectDetails,
      showInspectionTab: planType !== "Bundled" && planType !== "Third Party",
      loadingSate: hdfcQuoteLoading || hdfcPrice.duePremium === undefined || hdfcPrice.duePremium === 0 || ShowSkeleton,
      idv: Math.round(hdfcIDV)?.toLocaleString('en-IN'),
      premiumValue: (Math.round(hdfcPrice.basicPremium)),
      basicPremiumValue: Math.round(hdfcPrice?.basicPremium)?.toLocaleString('en-IN') || 0,
      paCoverValue: Math.round(hdfcPrice?.paCover)?.toLocaleString('en-IN') || 0,
      isExternalFittedCng: hdfcPrice?.odExternalCngLpg,
      zeroDepValue: Math.round(hdfcPrice?.zeroDep)?.toLocaleString('en-IN') || 0,
      shreeramProtctValue: 0,
      engProtectValue: hdfcPrice?.engineProtection,
      consumableValue: hdfcPrice?.consumables,
      isKeyLockChecked: checkboxTrueFalseObject.keyLockReplacement,
      isKeyLockValue: hdfcPrice?.keyLockReplacement,
      invoiceCoverValue: hdfcPrice?.invoiceCover,
      isRoadAssitanceChecked: checkboxTrueFalseObject.roadAssistance,
      roadAssitanceValue: hdfcPrice?.roadAssistance,
      tyreProtectionValue: hdfcPrice?.tyreProtection,
      personalBelongingValue: hdfcPrice?.personalBelonging,
      dailyAllowanceValue: hdfcPrice.dailyAllowance,
      passengerCoverValue: hdfcPrice.passengerCover,
      ncbProtectionValue: hdfcPrice?.ncbProtection,
      accessoriesValue: hdfcPrice?.accessories,
      driverCoverValue: hdfcPrice?.driverCover,
      showPlanDetailsTab: !hdfcQuoteLoading && hdfcPrice.duePremium && !ShowSkeleton,
      quickQuote: hdfcQuote,

      handleBuyNow: (e) => handleBuyNow(hdfc, "", HDFC),
      vehicleBreakupPrice: hdfcPrice,
    },
    // Tata
    {
      id: 8,
      companyName: TATA,
      companyImg: tata,
      isInspection: inspectDetails,
      showInspectionTab: planType !== "Bundled" && planType !== "Third Party",
      loadingSate: tataQuoteLoading || tataPremiumedetails.net_premium === undefined || tataPremiumedetails.net_premium === 0 || ShowSkeleton,
      idv: Math.round(tataIDV)?.toLocaleString('en-IN'),
      plan: tataPrice,
      packagesNameTata: carDetails.quotePackageName,
      premiumValue: Math.round(tataPrice?.duePremium),
      basicPremiumValue: Math.round(tataPrice?.basicPremium)?.toLocaleString('en-IN') || 0,
      paCoverValue: Math.round(tataPrice?.paCover)?.toLocaleString('en-IN') || 0,
      isExternalFittedCng: tataPrice?.odExternalCngLpg,
      zeroDepValue: Math.round(tataPrice?.zeroDep)?.toLocaleString('en-IN') || 0,
      shreeramProtctValue: 0,
      engProtectValue: tataPrice?.engineProtection,
      consumableValue: tataPrice?.consumables,
      isKeyLockChecked: checkboxTrueFalseObject.keyLockReplacement,
      isKeyLockValue: tataPrice?.keyLockReplacement,
      invoiceCoverValue: tataPrice?.invoiceCover,
      isRoadAssitanceChecked: checkboxTrueFalseObject.roadAssistance,
      roadAssitanceValue: tataPrice?.roadAssistance,
      tyreProtectionValue: tataPrice?.tyreProtection,
      personalBelongingValue: tataPrice?.lossofPersonalBelongings,
      dailyAllowanceValue: tataPrice?.dailyAllowance,
      passengerCoverValue: tataPrice?.passengerCover,
      ncbProtectionValue: tataPrice?.ncbProtection,
      accessoriesValue: tataPrice?.accessories,
      driverCoverValue: tataPrice?.driverCover,
      showPlanDetailsTab: !tataQuoteLoading && tataPrice.duePremium && !ShowSkeleton,
      quickQuote: tataGetquote,
      handleBuyNow: (e) => handleBuyNow(tata, "", TATA),
      vehicleBreakupPrice: tataPrice,
    },
    // UNITED
    {
      id: 9,
      companyName: UNITED,
      companyImg: united,
      isInspection: inspectDetails,
      showInspectionTab: planType !== "Bundled" && planType !== "Third Party",
      loadingSate: unitedQuoteLoading || unitedPremiumedetails?.CUR_FINAL_TOTAL_PREMIUM === undefined || unitedPremiumedetails?.CUR_FINAL_TOTAL_PREMIUM === 0 || ShowSkeleton,
      idv: Math.round(unitedIDV)?.toLocaleString('en-IN'),
      premiumValue: Math.round(unitedPrice?.duePremium),
      basicPremiumValue: Math.round(unitedPrice?.basicPremium)?.toLocaleString('en-IN') || 0,
      paCoverValue: Math.round(unitedPrice?.paCover)?.toLocaleString('en-IN') || 0,
      isExternalFittedCng: unitedPrice?.odExternalCngLpg,
      zeroDepValue: Math.round(unitedPrice?.zeroDep)?.toLocaleString('en-IN') || 0,
      shreeramProtctValue: 0,
      engProtectValue: unitedPrice?.engineProtection,
      consumableValue: unitedPrice?.consumables,
      isKeyLockChecked: checkboxTrueFalseObject.keyLockReplacement,
      isKeyLockValue: unitedPrice?.keyLockReplacement,
      invoiceCoverValue: unitedPrice?.invoiceCover,
      isRoadAssitanceChecked: checkboxTrueFalseObject.roadAssistance,
      roadAssitanceValue: unitedPrice?.roadAssistance,
      tyreProtectionValue: unitedPrice?.tyreProtection,
      personalBelongingValue: unitedPrice?.lossofPersonalBelongings,
      dailyAllowanceValue: unitedPrice?.dailyAllowance,
      passengerCoverValue: unitedPrice?.passengerCover,
      ncbProtectionValue: unitedPrice?.ncbProtection,
      accessoriesValue: unitedPrice?.accessories,
      driverCoverValue: unitedPrice?.driverCover,
      showPlanDetailsTab: !unitedQuoteLoading && unitedPrice.duePremium && !ShowSkeleton,
      quickQuote: unitedQuickQuote,
      handleBuyNow: (e) => handleBuyNow(united, "", UNITED),
      vehicleBreakupPrice: unitedPrice,
    },
    // NATIONAL
    {
      id: 10,
      companyName: NATIONAL,
      companyImg: national,
      isInspection: inspectDetails,
      showInspectionTab: planType !== "Bundled" && planType !== "Third Party",
      loadingSate: nationalQuoteLoading || nationalQuickQuote?.data?.data?.quote?.PolicyObject?.DuePremium === undefined || nationalQuickQuote?.data?.data?.quote?.PolicyObject?.DuePremium === 0 || ShowSkeleton,
      idv: Math.round(nationalIDV)?.toLocaleString('en-IN'),
      premiumValue: Math.round(nationalPrice?.duePremium),
      basicPremiumValue: Math.round(nationalPrice?.basicPremium)?.toLocaleString('en-IN') || 0,
      paCoverValue: Math.round(nationalPrice?.paCover)?.toLocaleString('en-IN') || 0,
      isExternalFittedCng: nationalPrice?.odExternalCngLpg,
      zeroDepValue: Math.round(nationalPrice?.zeroDep)?.toLocaleString('en-IN') || 0,
      shreeramProtctValue: 0,
      engProtectValue: nationalPrice?.engineProtection,
      consumableValue: nationalPrice?.consumables,
      isKeyLockChecked: checkboxTrueFalseObject.keyLockReplacement,
      isKeyLockValue: nationalPrice?.keyLockReplacement,
      invoiceCoverValue: nationalPrice?.invoiceCover,
      isRoadAssitanceChecked: checkboxTrueFalseObject.roadAssistance,
      roadAssitanceValue: nationalPrice?.roadAssistance,
      tyreProtectionValue: nationalPrice?.tyreProtection,
      personalBelongingValue: nationalPrice?.lossofPersonalBelongings,
      dailyAllowanceValue: nationalPrice?.dailyAllowance,
      passengerCoverValue: nationalPrice?.passengerCover,
      ncbProtectionValue: nationalPrice?.ncbProtection,
      accessoriesValue: nationalPrice?.accessories,
      driverCoverValue: nationalPrice?.driverCover,
      showPlanDetailsTab: !nationalQuoteLoading && nationalQuickQuote?.data?.data?.quote?.PolicyObject?.BeforeVatPremium && !ShowSkeleton,
      quickQuote: nationalQuickQuote,
      handleBuyNow: (e) => handleBuyNow(national, "", NATIONAL),
      vehicleBreakupPrice: nationalPrice,
    },
  ]

  let result = quotesContent.map(a => a.premiumValue);

  if (checkboxTrueFalseObject.paCover == true) {
    let premiumWithPa = (shreeRamPrice.paCover);
    quotesContent[4].premiumValue += premiumWithPa

    let SbiValue = (sbiPrice.paCover)
    quotesContent[1].premiumValue += Math.round(SbiValue);

    let IciciValue = Number(iciciPrice.paCover)
    quotesContent[2].premiumValue += (IciciValue);

    let val = digitPrice.paCover ? digitPrice.paCover.toString().split("INR").pop() : 0;
    let DigitValue = Math.round(parseInt(quotesContent[5].premiumValue) + Math.round(Number(val)))
    quotesContent[5].premiumValue = DigitValue;

    let zunoVal = Number(zunoPrice.paCover);
    if (quotesContent[3].premiumValue) {
      quotesContent[3].premiumValue += Math.round(zunoVal);
    }
    let HdfcValue = Number(hdfcPrice.paCover)
    quotesContent[6].premiumValue += (HdfcValue);

    //United
    let UnitedValue = Number(unitedPrice.paCover ? unitedPrice.paCover : 0)
    quotesContent[8].premiumValue += (UnitedValue);
    //national
    let nationalValue = Number(nationalPrice.paCover ? nationalPrice.paCover : 0)
    quotesContent[9].premiumValue += (nationalValue);
  }

  if (checkboxTrueFalseObject.zeroDep == true) {
    let premiumWithPa = parseInt(quotesContent[4].premiumValue) + Math.round(shreeRamPrice.zeroDep)
    quotesContent[4].premiumValue = premiumWithPa

    let SbiValue = parseInt(quotesContent[1].premiumValue) + sbiPrice.zeroDep

    quotesContent[1].premiumValue = Math.round(SbiValue);
    if (carDetails?.quotePackageNameICICI == "" || carDetails?.quotePackageNameICICI == undefined || carDetails?.quotePackageNameICICI == null) {
      if (checkboxTrueFalseObject.consumables == true && checkboxTrueFalseObject.roadAssistance == true && planType !== 'Bundled') {
        let IciciValue = parseInt(quotesContent[2].premiumValue) + iciciPrice.zeroDep
        quotesContent[2].premiumValue = IciciValue;
      } else if (planType == 'Bundled') {
        let IciciValue = parseInt(quotesContent[2].premiumValue) + iciciPrice.zeroDep
        quotesContent[2].premiumValue = IciciValue;
      }


    }

    let zunoVal = zunoPrice.zeroDep;
    if (quotesContent[3].premiumValue) {
      quotesContent[3].premiumValue += Math.round(Number(zunoVal));

    }
    let HdfcValue = parseInt(quotesContent[6].premiumValue) + hdfcPrice.zeroDep
    quotesContent[6].premiumValue = Math.round(HdfcValue);

    // // tata
    // if (!carDetails.quotePackageName) {
    //   let TataValue = Number(tataPrice.zeroDep)
    //   quotesContent[7].premiumValue += (TataValue);
    // }

    //United
    let UnitedValue = Number(unitedPrice.zeroDep ? Math.round(unitedPrice.zeroDep) : 0)
    quotesContent[8].premiumValue += (UnitedValue);
    //national
    let nationalValue = Number(nationalPrice.zeroDep ? Math.round(nationalPrice.zeroDep) : 0)
    quotesContent[9].premiumValue += (nationalValue);
  }

  if (checkboxTrueFalseObject.consumables == true) {
    if (checkboxTrueFalseObject.zeroDep == true) {
      let premiumWithPa = parseInt(quotesContent[4].premiumValue) + Math.round(shreeRamPrice.consumables)
      quotesContent[4].premiumValue = premiumWithPa
    }

    let SbiValue = parseInt(quotesContent[1].premiumValue) + Math.round(sbiPrice.consumables)
    quotesContent[1].premiumValue = SbiValue;

    let zunoVal = Number(zunoPrice.consumables);
    if (quotesContent[3].premiumValue) {
      quotesContent[3].premiumValue += Math.round(Number(zunoVal));
    }
    if (carDetails?.quotePackageNameICICI == "" || carDetails?.quotePackageNameICICI == undefined || carDetails?.quotePackageNameICICI == null) {
      if (checkboxTrueFalseObject.zeroDep == true && checkboxTrueFalseObject.roadAssistance == true && planType !== 'Bundled') {
        let IciciValue = parseInt(quotesContent[2].premiumValue) + Math.round(iciciPrice.consumables)
        quotesContent[2].premiumValue = IciciValue;
      } else if (planType == 'Bundled') {
        let IciciValue = parseInt(quotesContent[2].premiumValue) + Math.round(iciciPrice.consumables)
        quotesContent[2].premiumValue = IciciValue;
      }

    }

    let HdfcValue = parseInt(quotesContent[6].premiumValue) + Math.round(hdfcPrice.consumables)
    quotesContent[6].premiumValue = HdfcValue;

    // // tata
    // if (!carDetails.quotePackageName) {
    //   let TataValue = Number(tataPrice.consumables)
    //   quotesContent[7].premiumValue += (TataValue);
    // }

    //United
    let UnitedValue = Number(unitedPrice.consumables ? Math.round(unitedPrice.consumables) : 0)
    quotesContent[8].premiumValue += (UnitedValue);
    //national
    let nationalValue = Number(nationalPrice.consumables ? Math.round(nationalPrice.consumables) : 0)
    quotesContent[9].premiumValue += (nationalValue);

  }

  if (checkboxTrueFalseObject.engineProtection == true) {
    if (checkboxTrueFalseObject.zeroDep == true && planType == 'Bundled') {
      let premiumWithPa = parseInt(quotesContent[4].premiumValue) + Math.round(shreeRamPrice.engineProtection)
      quotesContent[4].premiumValue = premiumWithPa
    } else if (planType !== 'Bundled') {
      let premiumWithPa = parseInt(quotesContent[4].premiumValue) + Math.round(shreeRamPrice.engineProtection)
      quotesContent[4].premiumValue = premiumWithPa
    }

    let SbiValue = parseInt(quotesContent[1].premiumValue) + Math.round(sbiPrice.engineProtection)
    quotesContent[1].premiumValue = SbiValue;

    if (carDetails?.quotePackageNameICICI == "" || carDetails?.quotePackageNameICICI == undefined || carDetails?.quotePackageNameICICI == null) {
      let IciciValue = parseInt(quotesContent[2].premiumValue) + Math.round(iciciPrice.engineProtection)
      quotesContent[2].premiumValue = IciciValue;
    }

    let val = digitPrice.engineProtection ? digitPrice.engineProtection?.toString().split("INR").pop() : 0
    let DigitValue = parseInt(quotesContent[5].premiumValue) + Math.round(Number(val))
    quotesContent[5].premiumValue = Math.round(DigitValue);

    let zunoVal = zunoPrice.engineProtection;
    if (quotesContent[3].premiumValue) {
      quotesContent[3].premiumValue += Math.round(Number(zunoVal));
    }

    let HdfcValue = parseInt(quotesContent[6].premiumValue) + Math.round(hdfcPrice.engineProtection)
    quotesContent[6].premiumValue = HdfcValue;

    // // tata
    // if (!carDetails.quotePackageName) {
    //   let TataValue = Number(tataPrice.engineProtection)
    //   quotesContent[7].premiumValue += (TataValue);
    // }

    //United
    let UnitedValue = Number(unitedPrice.engineProtection ? Math.round(unitedPrice.engineProtection) : 0)
    quotesContent[8].premiumValue += (UnitedValue);
    //national
    let nationalValue = Number(nationalPrice.engineProtection ? Math.round(nationalPrice.engineProtection) : 0)
    quotesContent[9].premiumValue += (nationalValue);

  }

  if (checkboxTrueFalseObject.invoiceCover == true) {
    let premiumWithPa = parseInt(quotesContent[4].premiumValue) + Math.round(shreeRamPrice.invoiceCover)
    quotesContent[4].premiumValue = premiumWithPa

    let SbiValue = parseInt(quotesContent[1].premiumValue) + Math.round(sbiPrice.invoiceCover)
    quotesContent[1].premiumValue = SbiValue;

    if (carDetails?.quotePackageNameICICI == "" || carDetails?.quotePackageNameICICI == undefined || carDetails?.quotePackageNameICICI == null) {
      let IciciValue = parseInt(quotesContent[2].premiumValue) + Math.round(iciciPrice.invoiceCover)
      quotesContent[2].premiumValue = IciciValue;

    }

    let val = digitPrice.invoiceCover ? digitPrice.invoiceCover?.toString().split("INR").pop() : 0
    let DigitValue = parseInt(quotesContent[5].premiumValue) + parseInt(val)
    quotesContent[5].premiumValue = DigitValue;

    let zunoVal = zunoPrice.invoiceCover;
    if (quotesContent[3].premiumValue) {
      quotesContent[3].premiumValue += Math.round(Number(zunoVal));
    }
    let HdfcValue = parseInt(quotesContent[6].premiumValue) + Math.round(hdfcPrice.invoiceCover)
    quotesContent[6].premiumValue = HdfcValue;

    // // tata
    // if (!carDetails.quotePackageName) {
    //   let TataValue = Number(tataPrice.invoiceCover)
    //   quotesContent[7].premiumValue += (TataValue);
    // }

    //United
    let UnitedValue = Number(unitedPrice.invoiceCover ? Math.round(unitedPrice.invoiceCover) : 0)
    quotesContent[8].premiumValue += (UnitedValue);
    //national
    let nationalValue = Number(nationalPrice.invoiceCover ? Math.round(nationalPrice.invoiceCover) : 0)
    quotesContent[9].premiumValue += (nationalValue);

  }

  if (checkboxTrueFalseObject.keyLockReplacement == true) {
    let premiumWithPa = parseInt(quotesContent[4].premiumValue) + Math.round(shreeRamPrice.keyLockReplacement)
    quotesContent[4].premiumValue = premiumWithPa

    let SbiValue = parseInt(quotesContent[1].premiumValue) + Math.round(sbiPrice.keyLockReplacement)
    quotesContent[1].premiumValue = SbiValue;

    if (carDetails?.quotePackageNameICICI == "" || carDetails?.quotePackageNameICICI == undefined || carDetails?.quotePackageNameICICI == null) {
      let IciciValue = parseInt(quotesContent[2].premiumValue) + Math.round(iciciPrice.keyLockReplacement)
      quotesContent[2].premiumValue = IciciValue;

    }

    let val = digitPrice.keyLockReplacement ? digitPrice.keyLockReplacement?.toString().split("INR").pop() : 0

    let zunoVal = zunoPrice.keyLockReplacement;
    if (quotesContent[3].premiumValue) {
      quotesContent[3].premiumValue += Math.round(Number(zunoVal));
    }

    let HdfcValue = parseInt(quotesContent[6].premiumValue) + Math.round(hdfcPrice.keyLockReplacement)
    quotesContent[6].premiumValue = HdfcValue;

    // // tata
    // if (!carDetails.quotePackageName) {
    //   let TataValue = Number(tataPrice.keyLockReplacement)
    //   quotesContent[7].premiumValue += (TataValue);
    // }

    //United
    let UnitedValue = Number(unitedPrice.keyLockReplacement ? Math.round(unitedPrice.keyLockReplacement) : 0)
    quotesContent[8].premiumValue += (UnitedValue);
    //national
    let nationalValue = Number(nationalPrice.keyLockReplacement ? Math.round(nationalPrice.keyLockReplacement) : 0)
    quotesContent[9].premiumValue += (nationalValue);

  }

  if (checkboxTrueFalseObject.ncbProtection == true) {
    let premiumWithPa = parseInt(quotesContent[4].premiumValue) + Math.round(shreeRamPrice.ncbProtection)
    quotesContent[4].premiumValue = premiumWithPa

    let SbiValue = parseInt(quotesContent[1].premiumValue) + Math.round(sbiPrice.ncbProtection)
    quotesContent[1].premiumValue = SbiValue;

    let IciciValue = parseInt(quotesContent[2].premiumValue) + Math.round(iciciPrice.ncbProtection)
    quotesContent[2].premiumValue = IciciValue;

    let val = digitPrice.ncbProtection ? digitPrice.ncbProtection?.toString().split("INR").pop() : 0
    let DigitValue = parseInt(quotesContent[5].premiumValue) + parseInt(val)
    quotesContent[5].premiumValue = Math.round(DigitValue);

    let zunoVal = zunoPrice.ncbProtection;
    if (quotesContent[3].premiumValue) {
      quotesContent[3].premiumValue += Math.round(Number(zunoVal));

    }
    let HdfcValue = parseInt(quotesContent[6].premiumValue) + Math.round(hdfcPrice.ncbProtection)
    quotesContent[6].premiumValue = HdfcValue;


    //United
    let UnitedValue = Number(unitedPrice.ncbProtection ? Math.round(unitedPrice.ncbProtection) : 0)
    quotesContent[8].premiumValue += (UnitedValue);
    //national
    let nationalValue = Number(nationalPrice.ncbProtection ? Math.round(nationalPrice.ncbProtection) : 0)
    quotesContent[9].premiumValue += (nationalValue);

  }

  if (checkboxTrueFalseObject.driverCover == true) {
    let premiumWithPa = parseInt(quotesContent[4].premiumValue) + Math.round(shreeRamPrice.driverCover)
    quotesContent[4].premiumValue = premiumWithPa

    let SbiValue = parseInt(quotesContent[1].premiumValue) + Math.round(sbiPrice.driverCover)
    quotesContent[1].premiumValue = SbiValue;


    let IciciValue = parseInt(quotesContent[2].premiumValue) + Math.round(iciciPrice.driverCover)
    quotesContent[2].premiumValue = IciciValue;


    let val = digitPrice.driverCover ? digitPrice.driverCover?.toString().split("INR").pop() : 0
    let DigitValue = parseInt(quotesContent[5].premiumValue) + parseInt(val)
    quotesContent[5].premiumValue = Math.round(DigitValue);

    let zunoVal = zunoPrice.driverCover;
    if (quotesContent[3].premiumValue) {
      quotesContent[3].premiumValue += Math.round(Number(zunoVal));

    }
    let HdfcValue = parseInt(quotesContent[6].premiumValue) + Math.round(hdfcPrice.driverCover)
    quotesContent[6].premiumValue = HdfcValue;

    //United
    let UnitedValue = Number(unitedPrice.driverCover ? Math.round(unitedPrice.driverCover) : 0)
    quotesContent[8].premiumValue += (UnitedValue);
    //national
    let nationalValue = Number(nationalPrice.driverCover ? Math.round(nationalPrice.driverCover) : 0)
    quotesContent[9].premiumValue += (nationalValue);

  }

  if (checkboxTrueFalseObject.tyreProtection == true) {
    let premiumWithPa = parseInt(quotesContent[4].premiumValue) + shreeRamPrice.tyreProtection
    quotesContent[4].premiumValue = premiumWithPa

    let SbiValue = parseInt(quotesContent[1].premiumValue) + Math.round(sbiPrice.tyreProtection)
    quotesContent[1].premiumValue = SbiValue;

    if (carDetails?.quotePackageNameICICI == "" || carDetails?.quotePackageNameICICI == undefined || carDetails?.quotePackageNameICICI == null) {
      let IciciValue = parseInt(quotesContent[2].premiumValue) + iciciPrice.tyreProtection
      quotesContent[2].premiumValue = IciciValue;

    }


    let zunoVal = zunoPrice.tyreProtection;
    if (quotesContent[3].premiumValue) {
      quotesContent[3].premiumValue += Math.round(Number(zunoVal));

    }
    let HdfcValue = parseInt(quotesContent[6].premiumValue) + Math.round(hdfcPrice.tyreProtection)
    quotesContent[6].premiumValue = HdfcValue;

    // // tata
    // if (!carDetails.quotePackageName) {
    //   let TataValue = Number(tataPrice.tyreProtection)
    //   quotesContent[7].premiumValue += (TataValue);
    // }

    //United
    let UnitedValue = Number(unitedPrice.tyreProtection ? Math.round(unitedPrice.tyreProtection) : 0)
    quotesContent[8].premiumValue += (UnitedValue);
    //national
    let nationalValue = Number(nationalPrice.tyreProtection ? Math.round(nationalPrice.tyreProtection) : 0)
    quotesContent[9].premiumValue += (nationalValue);

  }

  if (checkboxTrueFalseObject.lossofPersonalBelongings == true) {
    let premiumWithPa = parseInt(quotesContent[4].premiumValue) + shreeRamPrice.lossofPersonalBelongings
    quotesContent[4].premiumValue = premiumWithPa

    let SbiValue = parseInt(quotesContent[1].premiumValue) + Math.round(sbiPrice.lossofPersonalBelongings)
    quotesContent[1].premiumValue = SbiValue;
    if (carDetails?.quotePackageNameICICI == "" || carDetails?.quotePackageNameICICI == undefined || carDetails?.quotePackageNameICICI == null) {
      let IciciValue = parseInt(quotesContent[2].premiumValue) + Math.round(iciciPrice?.personalBelonging)
      quotesContent[2].premiumValue = IciciValue;

    }

    let zunoVal = Math.round(zunoPrice.lossofPersonalBelongings);
    if (quotesContent[3].premiumValue) {
      quotesContent[3].premiumValue += Math.round(Number(zunoVal));

    }

    let HdfcValue = parseInt(quotesContent[6].premiumValue) + Math.round(hdfcPrice?.personalBelonging)
    quotesContent[6].premiumValue = HdfcValue;

    // // tata
    // if (!carDetails.quotePackageName) {
    //   let TataValue = Number(tataPrice.personalBelonging)
    //   quotesContent[7].premiumValue += (TataValue);
    // }

    //United
    let UnitedValue = Number(unitedPrice.personalBelonging ? Math.round(unitedPrice.personalBelonging) : 0)
    quotesContent[8].premiumValue += (UnitedValue);
    //national
    let nationalValue = Number(nationalPrice.personalBelonging ? Math.round(nationalPrice.personalBelonging) : 0)
    quotesContent[9].premiumValue += (nationalValue);

  }

  if (checkboxTrueFalseObject.dailyAllowance == true) {
    let premiumWithPa = parseInt(quotesContent[4].premiumValue) + shreeRamPrice?.dailyAllowance
    quotesContent[4].premiumValue = premiumWithPa

    let SbiValue = parseInt(quotesContent[1].premiumValue) + Math.round(sbiPrice.dailyAllowance)
    quotesContent[1].premiumValue = SbiValue;

    let IciciValue = parseInt(quotesContent[2].premiumValue) + Math.round(iciciPrice.dailyAllowance)
    quotesContent[2].premiumValue = IciciValue;

    let val = digitPrice.dailyAllowance ? digitPrice.dailyAllowance?.toString().split("INR").pop() : 0
    let DigitValue = parseInt(quotesContent[5].premiumValue) + parseInt(val)
    quotesContent[5].premiumValue = DigitValue;

    let zunoVal = Math.round(zunoPrice.dailyAllowance);
    if (quotesContent[3].premiumValue) {
      quotesContent[3].premiumValue += Math.round(Number(zunoVal));
    }
    let HdfcValue = parseInt(quotesContent[6].premiumValue) + Math.round(hdfcPrice.dailyAllowance)
    quotesContent[6].premiumValue = HdfcValue;

    // // tata
    // if (!carDetails.quotePackageName) {
    //   let TataValue = Number(tataPrice.dailyAllowance)
    //   quotesContent[7].premiumValue += (TataValue);
    // }

    //United
    let UnitedValue = Number(unitedPrice.dailyAllowance ? Math.round(unitedPrice.dailyAllowance) : 0)
    quotesContent[8].premiumValue += (UnitedValue);
    //national
    let nationalValue = Number(nationalPrice.dailyAllowance ? Math.round(nationalPrice.dailyAllowance) : 0)
    quotesContent[9].premiumValue += (nationalValue);

  }

  if (checkboxTrueFalseObject.emiProtection == true) {
    let premiumWithPa = parseInt(quotesContent[4].premiumValue) + shreeRamPrice.emiProtection
    quotesContent[4].premiumValue = premiumWithPa

    let SbiValue = parseInt(quotesContent[1].premiumValue) + sbiPrice.emiProtection
    quotesContent[1].premiumValue = SbiValue;

    let IciciValue = parseInt(quotesContent[2].premiumValue) + iciciPrice.emiProtection
    quotesContent[2].premiumValue = IciciValue;

    let val = digitPrice.emiProtection ? digitPrice.emiProtection?.toString().split("INR").pop() : 0
    let DigitValue = parseInt(quotesContent[5].premiumValue) + Math.round(Number(val))
    quotesContent[5].premiumValue = DigitValue;

    let zunoVal = Math.round(zunoPrice.emiProtection);
    if (quotesContent[3].premiumValue) {
      quotesContent[3].premiumValue += Math.round(Number(zunoVal));
    }
    let HdfcValue = parseInt(quotesContent[6].premiumValue) + hdfcPrice.emiProtection
    quotesContent[6].premiumValue = HdfcValue;

    // // tata
    // if (!carDetails.quotePackageName) {
    //   let TataValue = Number(tataPrice.emiProtection)
    //   quotesContent[7].premiumValue += (TataValue);
    // }

    //United
    let UnitedValue = Number(unitedPrice.emiProtection ? Math.round(unitedPrice.emiProtection) : 0)
    quotesContent[8].premiumValue += (UnitedValue);
    //national
    let nationalValue = Number(nationalPrice.emiProtection ? Math.round(nationalPrice.emiProtection) : 0)
    quotesContent[9].premiumValue += (nationalValue);

  }

  if (checkboxTrueFalseObject.roadAssistance == true) {
    let premiumWithPa = parseInt(quotesContent[4].premiumValue) + shreeRamPrice.roadAssistance
    quotesContent[4].premiumValue = premiumWithPa

    let SbiValue = parseInt(quotesContent[1].premiumValue) + sbiPrice.roadAssistance
    quotesContent[1].premiumValue = SbiValue;

    let zunoVal = zunoPrice.roadAssistance;
    if (quotesContent[3].premiumValue) {
      quotesContent[3].premiumValue += Math.round(Number(zunoVal));
    }
    if (carDetails?.quotePackageNameICICI == "" || carDetails?.quotePackageNameICICI == undefined || carDetails?.quotePackageNameICICI == null) {
      let IciciValue = parseInt(quotesContent[2].premiumValue) + iciciPrice.roadAssistance
      quotesContent[2].premiumValue = IciciValue;
    }

    let HdfcValue = parseInt(quotesContent[6].premiumValue) + hdfcPrice.roadAssistance
    quotesContent[6].premiumValue = HdfcValue;

    // // tata
    // if (!carDetails.quotePackageName) {
    //   let TataValue = Number(tataPrice.roadAssistance)
    //   quotesContent[7].premiumValue += (TataValue);
    // }

    //United
    let UnitedValue = Number(unitedPrice.roadAssistance ? Math.round(unitedPrice.roadAssistance) : 0)
    quotesContent[8].premiumValue += (UnitedValue);
    //national
    let nationalValue = Number(nationalPrice.roadAssistance ? Math.round(nationalPrice.roadAssistance) : 0)
    quotesContent[9].premiumValue += (nationalValue);

  }

  if (checkboxTrueFalseObject.shreeramProtect == true) {
    let premiumWithPa = parseInt(quotesContent[4].premiumValue) + shreeRamPrice.shreeramProtect
    quotesContent[4].premiumValue = premiumWithPa
  }

  if (checkboxTrueFalseObject.passengerCover == true) {
    let premiumWithPa = parseInt(quotesContent[4].premiumValue) + shreeRamPrice.passengerCover
    quotesContent[4].premiumValue = premiumWithPa

    let SbiValue = parseInt(quotesContent[1].premiumValue) + sbiPrice.passengerCover
    quotesContent[1].premiumValue = SbiValue;

    let IciciValue = parseInt(quotesContent[2].premiumValue) + iciciPrice.passengerCover
    quotesContent[2].premiumValue = IciciValue;

    let val = digitPrice.passengerCover ? digitPrice.passengerCover?.toString().split("INR").pop() : 0
    let DigitValue = parseInt(quotesContent[5].premiumValue) + parseInt(val)
    quotesContent[5].premiumValue = DigitValue;

    let zunoVal = zunoPrice.passengerCover;
    if (quotesContent[3].premiumValue) {
      quotesContent[3].premiumValue += Math.round(Number(zunoVal));
    }
    let HdfcValue = parseInt(quotesContent[6].premiumValue) + hdfcPrice.passengerCover
    quotesContent[6].premiumValue = HdfcValue;

    //United
    let UnitedValue = Number(unitedPrice.passengerCover ? Math.round(unitedPrice.passengerCover) : 0)
    quotesContent[8].premiumValue += (UnitedValue);
    //national
    let nationalValue = Number(nationalPrice.passengerCover ? Math.round(nationalPrice.passengerCover) : 0)
    quotesContent[9].premiumValue += (nationalValue);

  }

  if (checkboxTrueFalseObject.accessories == true) {
    let premiumWithPa = parseInt(quotesContent[4].premiumValue) + shreeRamPrice.accessories
    quotesContent[4].premiumValue = premiumWithPa

    let SbiValue = parseInt(quotesContent[1].premiumValue) + sbiPrice.accessories
    quotesContent[1].premiumValue = SbiValue;

    let IciciValue = parseInt(quotesContent[2].premiumValue) + iciciPrice.accessories
    quotesContent[2].premiumValue = IciciValue;


    let val = typeof (digitPrice.accessories) == Number ? digitPrice.accessories?.toString().split("INR").pop() : 0
    let DigitValue = parseInt(quotesContent[5].premiumValue) + parseInt(val)
    quotesContent[5].premiumValue = DigitValue !== NaN ? DigitValue : 0;

    let zunoVal = zunoPrice.accessories;
    if (quotesContent[3].premiumValue) {
      quotesContent[3].premiumValue += Math.round(Number(zunoVal));
    }
    let HdfcValue = parseInt(quotesContent[6].premiumValue) + hdfcPrice.accessories
    quotesContent[6].premiumValue = HdfcValue;

    // // tata
    // if (!carDetails.quotePackageName) {
    //   let TataValue = Number(tataPrice.accessories)
    //   quotesContent[7].premiumValue += (TataValue);
    // }
    //national
    let nationalValue = Number(nationalPrice.accessories ? Math.round(nationalPrice.accessories) : 0)
    quotesContent[9].premiumValue += (nationalValue);

  }

  // Handle ICICI Plans Premium in planCard
  if (carDetails.quotePackageNameICICI == "RSA With Key 1") {
    let IciciValue1 = parseInt(quotesContent[2].premiumValue) + Math.round(iciciPrice.keyLockReplacement)
    quotesContent[2].premiumValue = IciciValue1;
    let IciciValue2 = parseInt(quotesContent[2].premiumValue) + Math.round(iciciPrice.consumables)
    quotesContent[2].premiumValue = IciciValue2;
    let IciciValue3 = parseInt(quotesContent[2].premiumValue) + iciciPrice.zeroDep
    quotesContent[2].premiumValue = IciciValue3;
    let IciciValue4 = parseInt(quotesContent[2].premiumValue) + iciciPrice.roadAssistance
    quotesContent[2].premiumValue = IciciValue4;

  }
  if (carDetails.quotePackageNameICICI == "RSA With Key 2") {
    let IciciValue1 = parseInt(quotesContent[2].premiumValue) + Math.round(iciciPrice.keyLockReplacement)
    quotesContent[2].premiumValue = IciciValue1;
    let IciciValue2 = parseInt(quotesContent[2].premiumValue) + Math.round(iciciPrice.consumables)
    quotesContent[2].premiumValue = IciciValue2;
    let IciciValue3 = parseInt(quotesContent[2].premiumValue) + iciciPrice.zeroDep
    quotesContent[2].premiumValue = IciciValue3;
    let IciciValue4 = parseInt(quotesContent[2].premiumValue) + iciciPrice.roadAssistance
    quotesContent[2].premiumValue = IciciValue4;
    let IciciValue5 = parseInt(quotesContent[2].premiumValue) + Math.round(iciciPrice.engineProtection)
    quotesContent[2].premiumValue = IciciValue5;

  }
  if (carDetails.quotePackageNameICICI == "RSA With Key 3") {
    let IciciValue1 = parseInt(quotesContent[2].premiumValue) + Math.round(iciciPrice.keyLockReplacement)
    quotesContent[2].premiumValue = IciciValue1;
    let IciciValue2 = parseInt(quotesContent[2].premiumValue) + Math.round(iciciPrice.consumables)
    quotesContent[2].premiumValue = IciciValue2;
    let IciciValue3 = parseInt(quotesContent[2].premiumValue) + iciciPrice.zeroDep
    quotesContent[2].premiumValue = IciciValue3;
    let IciciValue4 = parseInt(quotesContent[2].premiumValue) + iciciPrice.roadAssistance
    quotesContent[2].premiumValue = IciciValue4;
    let IciciValue5 = parseInt(quotesContent[2].premiumValue) + iciciPrice.tyreProtection
    quotesContent[2].premiumValue = IciciValue5;

  }
  if (carDetails.quotePackageNameICICI == "RSA With Key 4") {
    let IciciValue1 = parseInt(quotesContent[2].premiumValue) + Math.round(iciciPrice.keyLockReplacement)
    quotesContent[2].premiumValue = IciciValue1;
    let IciciValue2 = parseInt(quotesContent[2].premiumValue) + Math.round(iciciPrice.consumables)
    quotesContent[2].premiumValue = IciciValue2;
    let IciciValue3 = parseInt(quotesContent[2].premiumValue) + iciciPrice.zeroDep
    quotesContent[2].premiumValue = IciciValue3;
    let IciciValue4 = parseInt(quotesContent[2].premiumValue) + iciciPrice.roadAssistance
    quotesContent[2].premiumValue = IciciValue4;
    let IciciValue5 = parseInt(quotesContent[2].premiumValue) + iciciPrice.tyreProtection
    quotesContent[2].premiumValue = IciciValue5;
    let IciciValue6 = parseInt(quotesContent[2].premiumValue) + Math.round(iciciPrice.engineProtection)
    quotesContent[2].premiumValue = IciciValue6;

  }
  if (carDetails.quotePackageNameICICI == "RSA With Key 5") {
    let IciciValue1 = parseInt(quotesContent[2].premiumValue) + Math.round(iciciPrice.keyLockReplacement)
    quotesContent[2].premiumValue = IciciValue1;
    let IciciValue2 = parseInt(quotesContent[2].premiumValue) + Math.round(iciciPrice.consumables)
    quotesContent[2].premiumValue = IciciValue2;
    let IciciValue3 = parseInt(quotesContent[2].premiumValue) + iciciPrice.zeroDep
    quotesContent[2].premiumValue = IciciValue3;
    let IciciValue4 = parseInt(quotesContent[2].premiumValue) + iciciPrice.roadAssistance
    quotesContent[2].premiumValue = IciciValue4;
    let IciciValue5 = parseInt(quotesContent[2].premiumValue) + iciciPrice.tyreProtection
    quotesContent[2].premiumValue = IciciValue5;
    let IciciValue6 = parseInt(quotesContent[2].premiumValue) + Math.round(iciciPrice.engineProtection)
    quotesContent[2].premiumValue = IciciValue6;
    let IciciValue7 = parseInt(quotesContent[2].premiumValue) + Math.round(iciciPrice.invoiceCover)
    quotesContent[2].premiumValue = IciciValue7;

  }
  if (carDetails.quotePackageNameICICI == "RSA Plus 1") {
    let IciciValue1 = parseInt(quotesContent[2].premiumValue) + Math.round(iciciPrice.keyLockReplacement)
    quotesContent[2].premiumValue = IciciValue1;
    let IciciValue2 = parseInt(quotesContent[2].premiumValue) + Math.round(iciciPrice.consumables)
    quotesContent[2].premiumValue = IciciValue2;
    let IciciValue3 = parseInt(quotesContent[2].premiumValue) + iciciPrice.zeroDep
    quotesContent[2].premiumValue = IciciValue3;
    let IciciValue4 = parseInt(quotesContent[2].premiumValue) + iciciPrice.roadAssistance
    quotesContent[2].premiumValue = IciciValue4;
    let IciciValue5 = parseInt(quotesContent[2].premiumValue) + Math.round(iciciPrice?.personalBelonging)
    quotesContent[2].premiumValue = IciciValue5;


  }
  if (carDetails.quotePackageNameICICI == "RSA Plus 2") {
    let IciciValue1 = parseInt(quotesContent[2].premiumValue) + Math.round(iciciPrice.keyLockReplacement)
    quotesContent[2].premiumValue = IciciValue1;
    let IciciValue2 = parseInt(quotesContent[2].premiumValue) + Math.round(iciciPrice.consumables)
    quotesContent[2].premiumValue = IciciValue2;
    let IciciValue3 = parseInt(quotesContent[2].premiumValue) + iciciPrice.zeroDep
    quotesContent[2].premiumValue = IciciValue3;
    let IciciValue4 = parseInt(quotesContent[2].premiumValue) + iciciPrice.roadAssistance
    quotesContent[2].premiumValue = IciciValue4;
    let IciciValue5 = parseInt(quotesContent[2].premiumValue) + Math.round(iciciPrice?.personalBelonging)
    quotesContent[2].premiumValue = IciciValue5;
    let IciciValue6 = parseInt(quotesContent[2].premiumValue) + Math.round(iciciPrice.engineProtection)
    quotesContent[2].premiumValue = IciciValue6;


  }
  if (carDetails.quotePackageNameICICI == "RSA Plus 3") {
    let IciciValue1 = parseInt(quotesContent[2].premiumValue) + Math.round(iciciPrice.keyLockReplacement)
    quotesContent[2].premiumValue = IciciValue1;
    let IciciValue2 = parseInt(quotesContent[2].premiumValue) + Math.round(iciciPrice.consumables)
    quotesContent[2].premiumValue = IciciValue2;
    let IciciValue3 = parseInt(quotesContent[2].premiumValue) + iciciPrice.zeroDep
    quotesContent[2].premiumValue = IciciValue3;
    let IciciValue4 = parseInt(quotesContent[2].premiumValue) + iciciPrice.roadAssistance
    quotesContent[2].premiumValue = IciciValue4;
    let IciciValue5 = parseInt(quotesContent[2].premiumValue) + Math.round(iciciPrice?.personalBelonging)
    quotesContent[2].premiumValue = IciciValue5;
    let IciciValue6 = parseInt(quotesContent[2].premiumValue) + iciciPrice.tyreProtection
    quotesContent[2].premiumValue = IciciValue6;


  }
  if (carDetails.quotePackageNameICICI == "RSA Plus 4") {
    let IciciValue1 = parseInt(quotesContent[2].premiumValue) + Math.round(iciciPrice.keyLockReplacement)
    quotesContent[2].premiumValue = IciciValue1;
    let IciciValue2 = parseInt(quotesContent[2].premiumValue) + Math.round(iciciPrice.consumables)
    quotesContent[2].premiumValue = IciciValue2;
    let IciciValue3 = parseInt(quotesContent[2].premiumValue) + iciciPrice.zeroDep
    quotesContent[2].premiumValue = IciciValue3;
    let IciciValue4 = parseInt(quotesContent[2].premiumValue) + iciciPrice.roadAssistance
    quotesContent[2].premiumValue = IciciValue4;
    let IciciValue5 = parseInt(quotesContent[2].premiumValue) + Math.round(iciciPrice?.personalBelonging)
    quotesContent[2].premiumValue = IciciValue5;
    let IciciValue6 = parseInt(quotesContent[2].premiumValue) + iciciPrice.tyreProtection
    quotesContent[2].premiumValue = IciciValue6;
    let IciciValue7 = parseInt(quotesContent[2].premiumValue) + Math.round(iciciPrice.engineProtection)
    quotesContent[2].premiumValue = IciciValue7;
  }
  if (carDetails.quotePackageNameICICI == "RSA Plus 5") {
    let IciciValue1 = parseInt(quotesContent[2].premiumValue) + Math.round(iciciPrice.keyLockReplacement)
    quotesContent[2].premiumValue = IciciValue1;
    let IciciValue2 = parseInt(quotesContent[2].premiumValue) + Math.round(iciciPrice.consumables)
    quotesContent[2].premiumValue = IciciValue2;
    let IciciValue3 = parseInt(quotesContent[2].premiumValue) + iciciPrice.zeroDep
    quotesContent[2].premiumValue = IciciValue3;
    let IciciValue4 = parseInt(quotesContent[2].premiumValue) + iciciPrice.roadAssistance
    quotesContent[2].premiumValue = IciciValue4;
    let IciciValue5 = parseInt(quotesContent[2].premiumValue) + Math.round(iciciPrice?.personalBelonging)
    quotesContent[2].premiumValue = IciciValue5;
    let IciciValue6 = parseInt(quotesContent[2].premiumValue) + iciciPrice.tyreProtection
    quotesContent[2].premiumValue = IciciValue6;
    let IciciValue7 = parseInt(quotesContent[2].premiumValue) + Math.round(iciciPrice.engineProtection)
    quotesContent[2].premiumValue = IciciValue7;
    let IciciValue8 = parseInt(quotesContent[2].premiumValue) + Math.round(iciciPrice.invoiceCover)
    quotesContent[2].premiumValue = IciciValue8;
  }

  const [loadingState, setLoadingState] = useState(false);

  useEffect(() => {
    if (inspectDetails == true && (planType == 'Comprehensive' || planType == "Own Damage")) {
      setLoadingState(true)
    } else {
      setLoadingState(false)
    }
  }, [carDetails]);

  let filteredQuotes = quotesContent.filter(quote => {
    if (loadingState) {
      return quote.id == 1
    } else {
      return quotesContent
    }
  });

  let inspectionfilter = quotesContent.filter((e) => {
    return e.id == 1
  })

  filteredQuotes.sort((a, b) => {
    const premiumValueA = typeof a.premiumValue === 'string' ? parseFloat(a.premiumValue.replace(/,/g, '')) : a.premiumValue;
    const premiumValueB = typeof b.premiumValue === 'string' ? parseFloat(b.premiumValue.replace(/,/g, '')) : b.premiumValue;
    return premiumValueA - premiumValueB;
  });

  let sortedQuotes = filteredQuotes.concat(quotesContent.filter(quote => {
    const premiumValue = typeof quote.premiumValue === 'string' ? parseFloat(quote.premiumValue.replace(/,/g, '')) : quote.premiumValue;
    return premiumValue === 0 || isNaN(premiumValue) || quote.premiumValue === undefined;
  }));

  let filterContets = quotesContent.filter((e) => {
    if (loadingState) {
      return e.id == 1
    } else {
      return e.loadingSate;
    }
  })

  useEffect(() => {
    setNewSateLoder(true)
    setTimeout(() => {
      setNewSateLoder(false)
    }, 2000)
    setErrorPlan(false)
    let id = setTimeout(() => {
      setErrorPlan(true)
    }, 10000)
    return (() => {
      clearTimeout(id)
    })
  }, [
    planType,
    checkboxTrueFalseObject?.passengerCover,
    checkboxTrueFalseObject?.accessories,
    carDetails.idv,
    carDetails.PolicyTypeValue,
    carDetails.idvValue,
    carDetails.policyExpDate,
    carDetails.fuelType,
    carDetails.make,
    carDetails.varient,
    carDetails.prevNcb,
    carDetails.make,
    carDetails.fuel,
    carDetails.varient,
    carDetails.cc,
    carDetails.regDate,
    carDetails.prevPolicyType,
    carDetails.policyRegDate
  ])

  quotesContent = sortedQuotes;

  function openPackageModal(val) {
    if (carDetails?.isNilDepInPrevPolicy === "" && (val == "tata")) {
      setShowNilDepModal(true);
      setIsActiveFromPlanCard(true);
    } else if (carDetails?.isNilDepInPrevPolicy === "" && val == "digit") {
      setShowNilDepModal(true);
      setIsActiveFromPlanCard(true);
    } else if (carDetails?.isNilDepInPrevPolicy === "" && val == "icici") {
      setShowNilDepModal(true);
      setIsActiveFromPlanCard(true);
    }

    else if (val == "tata") {
      setShowPackageModal(true);
    }
    else if (val == "digit") {
      setShowDigPackageModal(true)
    } else if (val == "icici") {
      setShowICICIPackageModal(true)
    }
  }

  function closeModal() {
    setShowPackageModal(false);
  }

  function closeModalForDigit() {
    setShowDigPackageModal(false)
  }

  function closeModalForICICI() {
    setShowICICIPackageModal(false)
  }

  const triggerPackageModal = (companyName) => {
    dispatch(getCompanyName({
      companyName: companyName
    }))
    if (companyName === "tata") {
      openPackageModal("tata");
    }
    if (companyName === "digit") {
      openPackageModal("digit");
    }
    if (companyName === "icici") {
      openPackageModal("icici");
    }
  }

  let LOPBforICII = () => {
    if (carDetails.quotePackageNameICICI == "RSA Plus 1" || carDetails.quotePackageNameICICI == "RSA Plus 2" || carDetails.quotePackageNameICICI == "RSA Plus 1" || carDetails.quotePackageNameICICI == "RSA Plus 3" || carDetails.quotePackageNameICICI == "RSA Plus 4" || carDetails.quotePackageNameICICI == "RSA Plus 5") {
      return true
    } else {
      return false
    }
  }

  let EngineProtectionforICII = () => {
    if (carDetails.quotePackageNameICICI == "RSA With Key 2" || carDetails.quotePackageNameICICI == "RSA With Key 4" || carDetails.quotePackageNameICICI == "RSA With Key 5" || carDetails.quotePackageNameICICI == "RSA Plus 2" || carDetails.quotePackageNameICICI == "RSA Plus 4" || carDetails.quotePackageNameICICI == "RSA Plus 5") {
      return true
    } else {
      return false
    }
  }

  let InvoiceCoverforICII = () => {
    if (carDetails.quotePackageNameICICI == "RSA With Key 5" || carDetails.quotePackageNameICICI == "RSA Plus 5") {
      return true
    } else {
      return false
    }
  }

  let TyreProtectionforICII = () => {
    if (carDetails.quotePackageNameICICI == "RSA With Key 3" || carDetails.quotePackageNameICICI == "RSA With Key 4" || carDetails.quotePackageNameICICI == "RSA With Key 5" || carDetails.quotePackageNameICICI == "RSA Plus 3" || carDetails.quotePackageNameICICI == "RSA Plus 4" || carDetails.quotePackageNameICICI == "RSA Plus 5") {
      return true
    } else {
      return false
    }
  }

  

  return (
    <>
      <section className="plancard_main_container">
        <>
          {/* *************** DYNAMIC - PLAN CARD'S *************** */}

          {
            filteredQuotes?.map((data, key) => {
              return (
                ! data.loadingSate  &&
                <div key={coutPlanCards++} className="plancard_card">
                  <div className="padding_breakup_container padding_breakup_container_bg">
                    <div className="plancard_card_img_holder hide-in-mobile">
                      <img src={data.companyImg} alt="" srcSet="" />
                    </div>
                    <div className="premiumContainer_pc hide-in-mobile">
                      {
                        planType !== "Third Party" ? (
                          <p className="idv_title_pc">
                            Cover Value(IDV) :{" "}
                            {data.loadingSate ? (
                              <SkeletonLoader variant="rectangular" width={120} height={15} />
                            ) : (
                              <span>₹ {data.idv.toLocaleString('en-IN')}</span>
                            )}
                          </p>
                        ) : null
                      }
                      {data.loadingSate ? (
                        <ButtonLoader />
                      ) : (
                        <>
                          <Botton
                            text={`₹ ${data.premiumValue.toLocaleString('en-IN')}`}
                            className="buyNowBtn_pc"
                            name="Plan Card"
                            spanTxt="Buy Now"
                            event={data.handleBuyNow}
                          />
                        </>
                      )}
                    </div>

                    <div className="premiumContainer_mobile-container">
                      <img src={data.companyImg} alt="" srcSet="" />
                      {
                        planType !== "Third Party" ? (
                          <p className="idv_title_pc idv_title_pc-mobile">
                            Cover Value(IDV) :{" "}
                            {data.loadingSate ? (
                              <SkeletonLoader variant="rectangular" width={120} height={15} />
                            ) : (
                              <span>₹ {data.idv.toLocaleString('en-IN')}</span>
                            )}
                          </p>
                        ) : null
                      }
                      {data.loadingSate ? (
                        <ButtonLoader />
                      ) : (
                        <>
                          <Botton
                            text={`₹ ${data.premiumValue.toLocaleString('en-IN')}`}
                            // text="₹ 1,00,000"
                            className="buyNowBtn_pc buyNowBtn_pc-mobile"
                            name="Plan Card"
                            spanTxt="Buy Now"
                            event={(e) => triggerVerifyTab(data.companyImg, carDetails, data.companyName)}
                          />
                        </>
                      )}
                    </div>

                    {
                      planType !== "Third Party" && data.isInspection && (
                        <div className="inspection_tab">
                          <img src={inspection} alt="" srcSet="" />
                          <p>Inspection Required</p>
                        </div>
                      )
                    }

                    {
                      (inspectDetails === true && !ackoisPiRequired) && data.showInspectionTab && data.companyName === "acko" && planType !== "Third Party" && (
                        <div className="inspection_tab inspection_tab-green">
                          <img src={noInspection} alt="" srcSet="" />
                          <p>No Inspection Required</p>
                        </div>
                      )
                    }

                    {
                      ((data.companyName === "digit") && planType !== "Third Party") && (
                        <div
                          className={`selectPlan-tab-mobile ${carDetails?.quotePackageNameDigit !== "" ? 'selectPlan-tab-mobile-active' : ''} `}
                          onClick={(e) => triggerPackageModal(data.companyName)} >
                          <p> {carDetails?.quotePackageNameDigit !== "" ? carDetails?.quotePackageNameDigit : "Select Plan"} </p>
                          <img src={rightArrow} />
                        </div>
                      )
                    }

                    {
                      ((data.companyName === "icici") && planType !== "Third Party" && iciciPrice.zeroDep) && (
                        <div
                          className={`selectPlan-tab-mobile ${carDetails?.quotePackageNameICICI !== "" ? 'selectPlan-tab-mobile-active' : ''} `}
                          onClick={(e) => triggerPackageModal(data.companyName)} >
                          <p> {carDetails?.quotePackageNameICICI !== "" ? carDetails?.quotePackageNameICICI : "Select Plan"} </p>
                          <img src={rightArrow} />
                        </div>
                      )
                    }

                    {
                      ((data.companyName === "tata") && planType !== "Third Party") && (
                        <div
                          className={`selectPlan-tab-mobile ${carDetails?.quotePackageName !== "" ? 'selectPlan-tab-mobile-active' : ''} `}
                          onClick={(e) => triggerPackageModal(data.companyName)} >
                          <p> {carDetails?.quotePackageName !== "" ? carDetails?.quotePackageName : "Select Plan"} </p>
                          <img src={rightArrow} />
                        </div>
                      )
                    }

                    <div className="breakup_container">

                      {data.loadingSate ? (
                        <SkeletonLoader variant="rectangular" height={15} />
                      ) : (
                        <div className="planHeadValueContainer hide-in-mobile">
                          <div className="planHead">
                            <p>
                              Basic Price  {
                                data.basicPremiumValue ? (
                                  <span>
                                    ₹{" "}
                                    {data.basicPremiumValue}
                                  </span>
                                ) : (<span>0</span>)
                              }
                            </p>
                          </div>
                        </div>
                      )}

                      {/* paCover */}

                      {planType !== "Own Damage" ? (
                        <>
                          {checkboxTrueFalseObject.paCover ? (
                            <>
                              {data.loadingSate ? (
                                <SkeletonLoader variant="rectangular" height={15} />
                              ) : (
                                <div className="planHeadValueContainer">
                                  <div className="planHead">
                                    <p>
                                      Personal Accident Cover
                                      <span>
                                        ₹
                                        {data.companyName == "digit" ? parseInt(data.paCoverValue?.toString().split("INR").pop()) : data.paCoverValue}
                                      </span>
                                    </p>
                                  </div>
                                  <div className="result_pc">
                                    <img
                                      src={data.paCoverValue ? success : fail}
                                      alt=""
                                      srcSet=""
                                    />
                                  </div>
                                </div>
                              )}
                            </>
                          ) : null}
                        </>
                      ) : null
                      }

                      {/* externalFittedCNG */}

                      {getBodyData?.data?.isCngLpgVehicleType ? (
                        <>
                          {data.loadingSate ? (
                            <SkeletonLoader variant="rectangular" height={15} />
                          ) : (
                            <div className="planHeadValueContainer">
                              <div className="planHead">
                                <p>External Fitted CNG</p>
                              </div>
                              <div className="result_pc">
                                <img
                                  src={data?.isExternalFittedCng ? success : fail}
                                  alt=""
                                  srcSet=""
                                />
                              </div>
                            </div>
                          )}
                        </>
                      ) : null}


                      {/* zeroDepreciation */}

                      {
                        planType !== "Third Party" && (
                          <>
                            {checkboxTrueFalseObject.zeroDep || data.zeroDep || (data?.plan?.zeroDep && data.packagesNameTata) || (data?.packagesNameICIC) ? (
                              <>
                                {data.loadingSate ? (
                                  <SkeletonLoader variant="rectangular" height={15} />
                                ) : (
                                  <div className="planHeadValueContainer">
                                    <div className="planHead">
                                      <p>
                                        Zero Depreciation
                                        <span className="bold_premium">
                                          ₹{" "}
                                          {/* { data.companyName == "digit" ? parseInt(data.zeroDepValue?.toString().split("INR").pop()) : data.zeroDepValue} */}
                                          {data.id == 3 && planType !== "Bundled" && checkboxTrueFalseObject.consumables && checkboxTrueFalseObject.roadAssistance ? data.zeroDepValue : data.id == 3 && planType == "Bundled" ? data.zeroDepValue : data.companyName == "digit" ? parseInt(data.zeroDepValue?.toString().split("INR").pop()) : data.zeroDepValue}
                                        </span>
                                      </p>
                                    </div>
                                    <div className="result_pc">
                                      <img
                                        // src={(data.zeroDepValue == 0 || !data.zeroDepValue) ? fail : success}

                                        // src={ data.id == 3 && checkboxTrueFalseObject.consumables && checkboxTrueFalseObject.roadAssistance && data.zeroDepValue  ? success : data.id == 3 ? fail : data.zeroDepValue  ? success : fail}
                                        src={(data.zeroDepValue == 0 || !data.zeroDepValue) ? fail : data.id == 3 && data.packagesNameICIC && data.zeroDepValue ? success : data.id == 3 && checkboxTrueFalseObject.consumables && checkboxTrueFalseObject.roadAssistance && data.zeroDepValue && planType !== "Bundled" ? success : data.id == 3 && data.zeroDepValue && checkboxTrueFalseObject.consumables && checkboxTrueFalseObject.roadAssistance ? success : data.id == 3 && planType !== "Bundled" ? fail : success}
                                        alt=""
                                        srcSet=""
                                      />
                                    </div>
                                  </div>
                                )}
                              </>
                            ) : null}
                          </>
                        )
                      }

                      {/* motorProtection */}

                      {
                        planType !== "Third Party" && (
                          <>
                            {checkboxTrueFalseObject.shreeramProtect ? (
                              <>
                                {data.loadingSate ? (
                                  <SkeletonLoader variant="rectangular" height={15} />
                                ) : (
                                  <div className="planHeadValueContainer">
                                    <div className="planHead">
                                      <p>Motor Protection</p>
                                    </div>
                                    <div className="result_pc">
                                      <img
                                        src={data.companyName !== SHREERAM ? fail : success}
                                        alt=""
                                        srcSet=""
                                      />
                                    </div>
                                  </div>
                                )}
                              </>
                            ) : null}
                          </>
                        )
                      }

                      {/* EngProtect */}

                      {
                        planType !== "Third Party" && (
                          <>
                            {checkboxTrueFalseObject.engineProtection || (data?.plan?.engineProtection && data.packagesNameTata) || data.engineProtection || data.engProtectValuePlan ? (
                              <>
                                {data.loadingSate ? (
                                  <SkeletonLoader variant="rectangular" height={15} />
                                ) : (
                                  <div className="planHeadValueContainer">
                                    <div className="planHead">
                                      <p>Engine Protector</p>
                                    </div>
                                    <div className="result_pc">
                                      <img
                                        src={data.engProtectValue && (data.id !== 5 && data.id !== 3) ? success : data.id == 5 && data.zeroDepValue && checkboxTrueFalseObject.zeroDep == true && data.engProtectValue ? success : data.id == 5 && data.engProtectValue && checkboxTrueFalseObject.zeroDep == false ? fail : data.id == 5 && data.consumableValue ? success : data.id == 3 && carDetails?.quotePackageNameICICI == "" && checkboxTrueFalseObject.engineProtection && data.engProtectValue ? success : data.id == 3 && !EngineProtectionforICII() && checkboxTrueFalseObject.engineProtection ? fail : data.id == 3 && EngineProtectionforICII() ? success : fail}

                                        alt=""
                                        srcSet=""
                                      />
                                    </div>
                                  </div>
                                )}
                              </>
                            ) : null}
                          </>
                        )
                      }

                      {/* Consumbales */}

                      {
                        planType !== "Third Party" && (
                          <>
                            {checkboxTrueFalseObject.consumables || data.consumables || (data?.plan?.consumables && data.packagesNameTata) || (data?.packagesNameICIC) ? (
                              <>
                                {data.loadingSate ? (
                                  <SkeletonLoader variant="rectangular" height={15} />
                                ) : (
                                  <div className="planHeadValueContainer">
                                    <div className="planHead">
                                      <p>Consumable</p>
                                    </div>
                                    <div className="result_pc">
                                      <img
                                        // src={(data.consumableValue == 0 || !data.consumableValue) ? fail :   data.consumableValue && data.id !== 5 ? success : data.id == 5 && data.zeroDepValue && checkboxTrueFalseObject.zeroDep && data.consumableValue && planType == "Bundled" ? success : data.id == 5 && data.consumableValue && checkboxTrueFalseObject.zeroDep == false ? fail : data.id == 5 && data.consumableValue && data.zeroDepValue ? success : fail}
                                        src={(data.consumableValue == 0 || !data.consumableValue) ? fail : data.id == 3 && data.packagesNameICIC && data.zeroDepValue ? success : data.id == 3 && checkboxTrueFalseObject.zeroDep && checkboxTrueFalseObject.roadAssistance && data.consumableValue && planType !== "Bundled" ? success : data.id == 3 && data.consumableValue && checkboxTrueFalseObject.zeroDep && checkboxTrueFalseObject.roadAssistance ? success : data.id == 3 && planType !== "Bundled" ? fail : data.consumableValue && data.id !== 5 ? success : data.id == 5 && data.zeroDepValue && checkboxTrueFalseObject.zeroDep && data.consumableValue && planType == "Bundled" ? success : data.id == 5 && data.consumableValue && checkboxTrueFalseObject.zeroDep == false ? fail : data.id == 5 && data.consumableValue && data.zeroDepValue ? success : fail}
                                      />
                                    </div>
                                  </div>
                                )}
                              </>
                            ) : null
                            }
                          </>
                        )
                      }

                      {/* keyAndLock */}

                      {
                        planType !== "Third Party" && (
                          <>
                            {data.isKeyLockChecked || data.isKeyLockCheckedDig || (data?.plan?.keyLockReplacement && data.packagesNameTata) || (data?.packagesNameICIC) ? (
                              <>
                                {data.loadingSate ? (
                                  <SkeletonLoader variant="rectangular" height={15} />
                                ) : (
                                  <div className="planHeadValueContainer">
                                    <div className="planHead">
                                      <p>Key and Lock Replacement</p>
                                    </div>
                                    <div className="result_pc">
                                      <img
                                        src={data.isKeyLockValue ? success : fail}
                                        alt=""
                                        srcSet=""
                                      />
                                    </div>
                                  </div>
                                )}
                              </>
                            ) : null
                            }
                          </>
                        )
                      }

                      {/* InvoiceCover */}

                      {
                        planType !== "Third Party" && (
                          <>
                            {
                              checkboxTrueFalseObject.invoiceCover || data.invoiceCover || (data?.plan?.invoiceCover && data.packagesNameTata) || data.invoiceCoverValuePlan ? (
                                <>
                                  {data.loadingSate ? (
                                    <SkeletonLoader variant="rectangular" height={15} />
                                  ) : (
                                    <div className="planHeadValueContainer">
                                      <div className="planHead">
                                        <p>Invoice Cover</p>
                                      </div>
                                      <div className="result_pc">
                                        <img
                                          src={data.invoiceCoverValue && data.id !== 3 ? success : data.id == 3 && carDetails?.quotePackageNameICICI == "" && checkboxTrueFalseObject.invoiceCover && data.invoiceCoverValue ? success : data.id == 3 && !InvoiceCoverforICII() && checkboxTrueFalseObject.invoiceCover ? fail : data.id == 3 && InvoiceCoverforICII() ? success : fail}
                                          alt=""
                                          srcSet=""
                                        />
                                      </div>
                                    </div>
                                  )}
                                </>
                              ) : null
                            }
                          </>
                        )
                      }

                      {/* RSA */}

                      {
                        planType !== "Third Party" && (
                          <>
                            {data.isRoadAssitanceChecked || data.isRoadAssitanceChecked || (data?.plan?.roadAssistance && data.packagesNameTata) || (data?.packagesNameICIC) ? (
                              <>
                                {data.loadingSate ? (
                                  <SkeletonLoader variant="rectangular" height={15} />
                                ) : (
                                  <div className="planHeadValueContainer">
                                    <div className="planHead">
                                      <p>24x7 Roadside Assistance</p>
                                    </div>
                                    <div className="result_pc">
                                      <img
                                        src={data.roadAssitanceValue ? success : fail}
                                        alt=""
                                        srcSet=""
                                      />
                                    </div>
                                  </div>
                                )}
                              </>
                            ) : null
                            }
                          </>
                        )
                      }

                      {/* tyreProtection */}

                      {
                        planType !== "Third Party" && (
                          <>
                            {
                              checkboxTrueFalseObject.tyreProtection || data.tyreProtectionCheck || (data?.plan?.tyreProtection && data.packagesNameTata) || data.tyreProtectionValuePlan ? (
                                <>
                                  {data.loadingSate ? (
                                    <SkeletonLoader variant="rectangular" height={15} />
                                  ) : (
                                    <div className="planHeadValueContainer">
                                      <div className="planHead">
                                        <p>Tyre Protection</p>
                                      </div>
                                      <div className="result_pc">
                                        <img
                                          src={data.tyreProtectionValue && data.id !== 3 ? success : data.id == 3 && carDetails?.quotePackageNameICICI == "" && checkboxTrueFalseObject.tyreProtection && data.tyreProtectionValue ? success : data.id == 3 && !TyreProtectionforICII() && checkboxTrueFalseObject.tyreProtection ? fail : data.id == 3 && TyreProtectionforICII() ? success : fail}
                                          alt=""
                                          srcSet=""
                                        />
                                      </div>
                                    </div>
                                  )}
                                </>
                              ) : null
                            }
                          </>
                        )
                      }

                      {/* lossOfPersonalBelongings */}

                      {
                        planType !== "Third Party" && (
                          <>
                            {checkboxTrueFalseObject.lossofPersonalBelongings || data.isKeyLockCheckedDig || (data?.plan?.lossofPersonalBelongings && data.packagesNameTata) || data.personalBelongingPlan ? (
                              <>
                                {data.loadingSate ? (
                                  <SkeletonLoader variant="rectangular" height={15} />
                                ) : (
                                  <div className="planHeadValueContainer">
                                    <div className="planHead">
                                      <p>Loss of Personal Belongings</p>
                                    </div>
                                    <div className="result_pc">
                                      <img
                                        src={data.personalBelongingValue && data.id !== 3 ? success : data.id == 3 && carDetails?.quotePackageNameICICI == "" && checkboxTrueFalseObject.lossofPersonalBelongings && data.personalBelongingValue ? success : data.id == 3 && !LOPBforICII() && checkboxTrueFalseObject.lossofPersonalBelongings ? fail : data.id == 3 && LOPBforICII() ? success : fail}
                                        alt=""
                                        srcSet=""
                                      />
                                    </div>
                                  </div>
                                )}
                              </>
                            ) : null
                            }
                          </>
                        )
                      }

                      {/* dailyAllownce */}

                      {
                        planType !== "Third Party" && (
                          <>
                            {checkboxTrueFalseObject.dailyAllowance ? (
                              <>
                                {data.loadingSate ? (
                                  <SkeletonLoader variant="rectangular" height={15} />
                                ) : (
                                  <div className="planHeadValueContainer">
                                    <div className="planHead">
                                      <p>Daily Allowance</p>
                                    </div>
                                    <div className="result_pc">
                                      <img src={data.dailyAllowanceValue ? success : fail} />
                                    </div>
                                  </div>
                                )}
                              </>
                            ) : null}
                          </>
                        )
                      }

                      {/* passengerCover */}

                      {
                        planType !== "Own Damage" && (
                          <>
                            {checkboxTrueFalseObject.passengerCover ? (
                              <>
                                {data.loadingSate ? (
                                  <SkeletonLoader variant="rectangular" height={15} />
                                ) : (
                                  <div className="planHeadValueContainer">
                                    <div className="planHead">
                                      <p>Passenger Cover</p>
                                    </div>
                                    <div className="result_pc">
                                      <img src={data.passengerCoverValue ? success : fail} />
                                    </div>
                                  </div>
                                )}
                              </>
                            ) : null}
                          </>
                        )
                      }

                      {/* NCB-protection */}

                      {
                        planType !== "Third Party" && (
                          <>
                            {checkboxTrueFalseObject.ncbProtection || (data?.plan?.ncbProtection && data.packagesNameTata) ? (
                              <>
                                {data.loadingSate ? (
                                  <SkeletonLoader variant="rectangular" height={15} />
                                ) : (
                                  <div className="planHeadValueContainer">
                                    <div className="planHead">
                                      <p>NCB Protection Cover</p>
                                    </div>
                                    <div className="result_pc">
                                      <img
                                        src={data.ncbProtectionValue ? success : fail}
                                        alt=""
                                        srcSet=""
                                      />
                                    </div>
                                  </div>
                                )}
                              </>
                            ) : null}
                          </>
                        )
                      }

                      {/* accessories */}

                      {
                        planType !== "Third Party" && (
                          <>
                            {checkboxTrueFalseObject.accessories || (data?.plan?.accessories && data.packagesNameTata) ? (
                              <>
                                {data.loadingSate ? (
                                  <SkeletonLoader variant="rectangular" height={15} />
                                ) : (
                                  <div className="planHeadValueContainer">
                                    <div className="planHead">
                                      <p>Accessories</p>
                                    </div>
                                    <div className="result_pc">
                                      <img
                                        src={data.accessoriesValue ? success : fail}
                                        alt=""
                                        srcSet=""
                                      />
                                    </div>
                                  </div>
                                )}
                              </>
                            ) : null}
                          </>
                        )
                      }

                      {/* driverCover */}

                      {
                        planType !== "Own Damage" && (
                          <>
                            {checkboxTrueFalseObject.driverCover ? (
                              <>
                                {data.loadingSate ? (
                                  <SkeletonLoader variant="rectangular" height={15} />
                                ) : (
                                  <div className="planHeadValueContainer">
                                    <div className="planHead">
                                      <p>Driver Cover</p>
                                    </div>
                                    <div className="result_pc">
                                      <img src={data.driverCoverValue ? success : fail} />
                                    </div>
                                  </div>
                                )}
                              </>
                            ) : null}
                          </>
                        )
                      }

                    </div >
                  </div >
                  <div className="bottomOptions_planCard bottomOptions_planCard_bg">
                    <div className="option_div_pc option_div_pc_border">
                      {data.showPlanDetailsTab ? (
                        <PlanDetails
                          QuickQuote={data.quickQuote}
                          triggerTab="Plan Details"
                          handleBuyNow={data.handleBuyNow}
                          companyImg={data.companyImg}
                          companyName={data.companyName}
                          vehicleBreakupPrice={data.vehicleBreakupPrice}
                          bodyData={getBodyData}
                          setShowBottomTab={setShowBottomTab}
                          carDetails={carDetails}
                          test={test}
                          setDigitAddon={setDigitAddon}
                          DigitAddon={DigitAddon}
                          OnClickAddonsplan={OnClickAddonsplan}
                          allIDVValues={allIDVValues}
                        />
                      ) : null}
                    </div>
                    <div className="option_div_pc">
                      <p>Cashless Garages</p>
                    </div>
                  </div>
                </div>
              )
            })
          }

          {
            inspectDetails !== true && filteredQuotes.map((e) => {
              if ((!ErrorPlan || CalendarDeactiveFlag) && e?.loadingSate) {
                return (
                  <div className="plancard_card">
                    <div className="padding_breakup_container">
                      <div className="plancard_card_img_holder hideInMobile">
                        <img src={e.companyImg} />
                      </div>

                      <div className="premiumContainer_pc hideInMobile">
                        <p className="idv_title_pc">
                          Cover Value(IDV) :
                          <SkeletonLoader variant="rectangular" width={120} height={15} />
                        </p>
                        <div className="premiumContainer_pc">
                          <ButtonLoader />
                        </div>
                        <div className="breakup_container">
                          <SkeletonLoader variant="rectangular" height={15} />
                        </div>
                        <div className="breakup_container">
                          <SkeletonLoader variant="rectangular" height={15} />
                        </div>
                        <div className="bottomOptions_planCard">
                          <div className="option_div_pc option_div_pc_border">
                          </div>
                        </div>
                      </div>

                      <div className="premiumContainer_mobile-container">
                        <img src={e.companyImg} alt="" srcSet="" />
                        {
                          planType !== "Third Party" ? (
                            <p className="idv_title_pc idv_title_pc-mobile">
                              Cover Value(IDV) :{" "}
                              <SkeletonLoader variant="rectangular" width={120} height={15} />
                            </p>
                          ) : null
                        }
                        <ButtonLoader className="btn-loader-size" />
                      </div>

                    </div>
                  </div>
                )
              }
            })
          }

          {
            (inspectDetails === true && filteredQuotes[0].loadingSate == true) &&
            <div className="plancard_card">
              <div className="padding_breakup_container">
                <div className="plancard_card_img_holder hideInMobile">
                  <img src={filteredQuotes[0].companyImg} />
                </div>

                <div className="premiumContainer_pc hideInMobile">
                  <p className="idv_title_pc">
                    Cover Value(IDV) :
                    <SkeletonLoader variant="rectangular" width={120} height={15} />
                  </p>
                  <div className="premiumContainer_pc">
                    <ButtonLoader />
                  </div>
                  <div className="breakup_container">
                    <SkeletonLoader variant="rectangular" height={15} />
                  </div>
                  <div className="breakup_container">
                    <SkeletonLoader variant="rectangular" height={15} />
                  </div>
                  <div className="bottomOptions_planCard">
                    <div className="option_div_pc option_div_pc_border">
                    </div>
                  </div>
                </div>

                <div className="premiumContainer_mobile-container">
                  <img src={filteredQuotes[0].companyImg} alt="" srcSet="" />
                  {
                    planType !== "Third Party" ? (
                      <p className="idv_title_pc idv_title_pc-mobile">
                        Cover Value(IDV) :{" "}
                        <SkeletonLoader variant="rectangular" width={120} height={15} />
                      </p>
                    ) : null
                  }
                  <ButtonLoader className="btn-loader-size" />
                </div>

              </div>
            </div>
          }
        </>
      </section >

      {/* TATA PACKAGE MODAL */}

      <Modal
        isOpen={ShowPackageModal}
        onRequestClose={closeModal}
        style={customStyles}
      >
        <PackageModal
          packageData={tataPlanPackages}
          setCarDetails={setCarDetails}
          carDetails={carDetails}
          closeModal={closeModal}
          carDetailsCopyState={carDetailsCopyState}
          setcarDetailsCopyState={setcarDetailsCopyState}
          test={test}
          tataQuoteLoading={tataQuoteLoading}
        />
      </Modal>

      {/* DIGIT PACKAGE MODAL */}

      <Modal
        isOpen={ShowDigPackageModal}
        onRequestClose={closeModalForDigit}
        style={customStyles}
      >
        <DigitPackageModal
          packageData={DigitPlan}
          setCarDetails={setCarDetails}
          carDetails={carDetails}
          closeModal={closeModalForDigit}
          carDetailsCopyState={carDetailsCopyState}
          setcarDetailsCopyState={setcarDetailsCopyState}
          test={test}
          tataQuoteLoading={digitQuoteLoading}
        />
      </Modal>

      {/* ICICI PACKAGE MODAL */}

      <Modal
        isOpen={ShowICICIPackageModal}
        onRequestClose={closeModalForICICI}
        style={customStyles}
      >
        <ICICIPackageModal
          packageData={ICICIPlan}
          setCarDetails={setCarDetails}
          carDetails={carDetails}
          closeModal={closeModalForICICI}
          carDetailsCopyState={carDetailsCopyState}
          setcarDetailsCopyState={setcarDetailsCopyState}
          test={test}
          tataQuoteLoading={iciciQuoteLoading}
        />
      </Modal>

      {/* which Resposes are not success */}
      {loadingState && filterContets[0].isLoading == true && <PlancardError quotesContent={filterContets} />}
      {(ErrorPlan && !CalendarDeactiveFlag) && !loadingState && <PlancardError quotesContent={filterContets} />}
    </>
  );
};

export default Plancard;