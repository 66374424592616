import React, { useState, useEffect } from "react";
import "./GetRegNo.css";
import irdai from "../../Assets/Images/irdai.png";
import ProductSelect from "./ProductSelect";
import FormContainer from "./formContainer";
import car from "../../Assets/Images/car.svg";
import bike from "../../Assets/Images/bike.svg";
import AOS from "aos";
import "aos/dist/aos.css";
import { useSelector } from "react-redux";
import MobileSelectedProduct from "./MobileSelectedProduct";

const GetRegNo = (props) => {
  const { style, setIsMobileMode, IsMobileMode } = props;

  const [CarRegNo, setCarRegNo] = useState("");
  const [ShowCarContent, setShowCarContent] = useState(false);
  const [ShowProduct, setShowProduct] = useState("car");
  const [ProductTabClicked, setProductTabClicked] = useState(false);
  const [ShowProductMobile, setShowProductMobile] = useState("");

  let dealerDetails = useSelector((state) => {
    return state?.dealerData?.data
  });

  localStorage.clear();
  sessionStorage.clear();

  const handleProduct = (product) => {
    setShowProduct(product);
    setCarRegNo("");
  };

  const handleMobileContent = (product) => {
    setShowProduct(product);
    setIsMobileMode(true);
  };

  const handleVehicleNumber = (value) => {

    const cleanValue = value.replace(/[^\w]/g, '').toUpperCase();
    if (cleanValue.length <= 12) {
      setCarRegNo(cleanValue);
      console.log(cleanValue, "cleanValue44")
    }
  };

  const HandleCarFormPopUp = () => {
    setShowCarContent(false);
  };

  return (
    <>
      {
        IsMobileMode ? (
          <MobileSelectedProduct
            carIcon={car}
            bikeIcon={bike}
            ShowProduct={ShowProduct}
            setIsMobileMode={setIsMobileMode}
            stateForMobileContent={ShowCarContent}
            setShowCarContent={setShowCarContent}
            productValue={ShowProduct}
            ShowProductMobile={ShowProductMobile}
            ProductTabClicked={ProductTabClicked}
            handleVehicleNumber={(e) => handleVehicleNumber(e)}
            vehicleNumber={CarRegNo}
            irdai={irdai}
            irdaiContentOne={irdaiContentOne}
            irdaiContentTwo={irdaiContentTwo}
            mobileCLickEvent={(e) => HandleCarFormPopUp(e)}
          />
        ) : (
          <div className="getRegNo_container">
            <div className="getRegNo_parent maxWidth ">
              <div className="getRegNo_form">
                <ProductSelect
                  clickEvent={handleMobileContent}
                  handleProduct={handleProduct}
                  productValue={ShowProduct}
                  ShowProductMobile={ShowProductMobile}
                  ShowCarContent={ShowCarContent}
                  setProductTabClicked={setProductTabClicked}
                />

                {/* desktop */}

                <div className="width">
                  {
                    ShowProduct === "car" && (
                      <div className="active_prod_btn prod_btn mobile-product-btn">
                        <img src={car} alt="" srcSet="" />
                        <div>
                          <span>Car </span> Insurance
                        </div>
                      </div>
                    )
                  }
                  {
                    ShowProduct === "bike" && (
                      <div className="active_prod_btn prod_btn mobile-product-btn">
                        <img src={bike} alt="" srcSet="" />
                        <div>
                          <span>Bike </span> Insurance
                        </div>
                      </div>
                    )
                  }
                  <FormContainer
                    stateForMobileContent={ShowCarContent}
                    setShowCarContent={setShowCarContent}
                    productValue={ShowProduct}
                    ProductTabClicked={ProductTabClicked}
                    handleVehicleNumber={(e) => handleVehicleNumber(e)}
                    vehicleNumber={CarRegNo}
                    irdai={irdai}
                    irdaiContentOne={irdaiContentOne}
                    irdaiContentTwo={irdaiContentTwo}
                    mobileCLickEvent={(e) => HandleCarFormPopUp(e)}
                    dealerDetails={dealerDetails}
                    ShowProduct={ShowProduct}

                  />
                </div>

                <div className="irdai_container_head showInMobile irdai_container_head_mobile  ">
                  <img src={irdai} alt="" srcSet="" />
                  <p className="small_text irdai_small_text">
                    Licensed approved by IRDAI Direct Broker Licence No. 762
                  </p>
                </div>

              </div>
            </div>
          </div>
        )
      }


    </>
  );
};

const irdaiContentOne = [
  {
    number: 40,
    inSpan: "+",
    description: "Insurer companies",
  },
  {
    number: 10,
    inSpan: "Lakh +",
    description: "Customers",
  },
];

const irdaiContentTwo = [
  {
    number: 25,
    inSpan: "K +",
    description: "Claims served",
  },
  {
    number: 100,
    inSpan: " +",
    description: "experts   " + "",
  },
];

export default GetRegNo;
