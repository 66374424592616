import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import CheckBox from "../Checkbox/CheckBox";
import InputFields from "../InputFields/InputFields";
import Calender from "../InputFields/Calender";
import Botton from "../Button/Button";
import { useLocation, Link, useNavigate, useHistory } from "react-router-dom";
import { SBI, ICICI, DIGIT, ZUNO, ACKO, SHREERAM, TATA, HDFC, UNITED,NATIONAL } from "../../utils/commonUtils";
import backIcon from "../../Assets/Images/backIcon.svg";
import DropDown from "../DropDown/DropDown";
import grayArrow from "../../Assets/Images/grayArrow.svg"
import RadioButton from "../RadioButton/RadioButton";
import AckoCreative from "../Widgets/AckoCreative";
import CustomDateField from "../InputFields/CustomDateField/CustomDateField";
import CustomCalendar from "../InputFields/Calendar/Calendar";
import ButtonLoader from "../Pages/Loader/Button Loader/ButtonLoader";

const ProposalOne = (props) => {
  const {
    emailValue,
    phoneValue,
    panValue,
    gstValue,
    HandleInputChange,
    handleSubmitForm,
    ownerValue,
    compNameValue,
    formData,
    Edit,
    setEdit,
    errors,
    errors2,
    companyName,
    sbiAutoKycData,
    dateValue,
    isPaCoverChecked,
    ShowNilDepState,
    isZeroDepChecked,
    handleTataNilDep,
    ZeroDepValue,
    bodyData,
    handleDobClone,
    saveCalendarValue,
    cancelCalendarValue,
    ShowDobCalendar,
    setShowDobCalendar,
    DobClone,
    businessType,
    mappingAllData,
    type,
    vehicleType,
    phonestatussloading,
    checkphone
  } = props;

  const [ParamaterForRoute, setParamaterForRoute] = useState("ownerDetail");
  const [ShowPaExclusionReason, setShowPaExclusionReason] = useState(false);
  const [MaxDateValue, setMaxDateValue] = useState("");
  const search = useLocation().search;
  const id = new URLSearchParams(search).get("id");

  useEffect(() => {
    const date = new Date()
    setMaxDateValue(date)
    if (formData.carOwner == "isCompany") {
      setParamaterForRoute("PersonalDetails");
      formData.paExclusionReason = "Name of Company/Institution"
    } else {
      setParamaterForRoute("ownerDetail");
      if (!isPaCoverChecked) {
        formData.paExclusionReason = "Already CPA Policy Exists"
      }
      if (isPaCoverChecked) {
        formData.paExclusionReason = ""
      }
    }

  }, [formData.carOwner]);

  if (!isPaCoverChecked && formData.carOwner !== "isCompany") {
    formData.paExclusionReason = "Already CPA Policy Exists"
  }

  const navigate = useNavigate();

  const paExclusionReasons = [
    { label: "Name of Company/Institution", value: "Name of Company/Institution" },
    { label: "Not have eff. DL With Decl. Letter", value: "Not have eff. DL With Decl. Letter" },
    { label: "Already CPA Policy Exists", value: "Already CPA Policy Exists" },
  ]

  const handlePopUps = () => {
    if (ShowPaExclusionReason) {
      setShowPaExclusionReason(false);
    }
  };

  // let vehicleType = bodyData?.data?.vehicleType;
  // console.log(bodyData,"jhghfduyr");



  // console.log(navigate,"khjhgjhgiao")


  let transactionStatus = useSelector((state) => {
    return state.getTransactionStatus;
  });

  let errorsLength = Object.keys(errors);
  const [errorState, setErroState] = useState(false)

  useEffect(() => {
    if (errorsLength > 0) {
      setErroState(true)
    }
  }, [errorsLength])




  return (
    <>
      <div>
        <div className="titleContainer">
          <p>Owner Details</p>
          <p>All fields are mandatory</p>
        </div>
        <form className="formSection-pform" action="" onClick={handlePopUps} onSubmit={(e) => { e.preventDefault(); }}>
          {/* blank-checkBox-div */}
          <div className="checkBox_form">
            <CheckBox
              label="Vehicle Is Owned By Company ?"
              className="small_text small_textModal checkBox-label"
              classNameTwo=""
              value=""
              changeEvent={(e) => HandleInputChange(e, "carOwner")}
              name="carOwner"
              id="carOwner"
              checked={formData?.carOwner === "isCompany" ? true : false}
            />

          </div>

          {ownerValue === "individualOwner" ? (
            <div className="input_group_pform">
              <div className="inputGropOne_form inputGrop ">
                <InputFields
                  value={formData.firstName}
                  placeholder="First Name"
                  id="firstName"
                  type="text"
                  name="firstName"
                  hanldeInput={errors}
                  errState={errors?.firstName ? true : false}
                  errMsg={errors?.firstName}
                  errClassName="input-err"
                  onChangeEvent={(e) => HandleInputChange(e)}
                  isDisabled={
                    companyName === SBI &&
                      sbiAutoKycData?.kycStatus === "CKYCSuccess"
                      ? true
                      : false
                  }
                />
              </div>
              <div className="inputGropOne_form inputGrop ">
                <InputFields
                  value={formData.lastName}
                  placeholder="Last Name"
                  id="lastName"
                  type="text"
                  name="lastName"
                  errState={errors?.lastName ? true : false}
                  errMsg={errors?.lastName}
                  errClassName="input-err"
                  onChangeEvent={(e) => HandleInputChange(e)}
                  isDisabled={
                    companyName === SBI &&
                      sbiAutoKycData?.kycStatus === "CKYCSuccess"
                      ? true
                      : false
                  }
                />
              </div>
            </div>
          ) : (
            <div className="input_group_pform">
              <div className="inputGropOne_form inputGrop ">
                <InputFields
                  value={compNameValue}
                  placeholder="Company Name"
                  id="companyName"
                  type="text"
                  name="companyName"
                  hanldeInput={errors}
                  errState={errors?.companyName ? true : false}
                  errMsg={errors?.companyName}
                  errClassName="input-err"
                  onChangeEvent={(e) => HandleInputChange(e)}
                  isDisabled={
                    companyName === SBI &&
                      sbiAutoKycData?.kycStatus === "CKYCSuccess"
                      ? true
                      : false
                  }
                />
              </div>
              <div className="inputGropOne_form inputGrop ">
                <InputFields
                  value={phoneValue}
                  placeholder="Phone Number"
                  id="phoneNumber"
                  type="number"
                  name="phoneNumber"
                  hanldeInput={errors}
                  errState={errors?.phoneNumber ? errors?.phoneNumber : checkphone ? true : false}
                  errMsg={errors?.phoneNumber ? errors?.phoneNumber : checkphone && !errors?.phoneNumber ? " Phone Number already Exist (Duplicate Number)" : ""}
                  errClassName="input-err"
                  onChangeEvent={(e) => HandleInputChange(e)}
                  isDisabled={
                    companyName === SBI &&
                      sbiAutoKycData?.kycStatus === "CKYCSuccess"
                      ? true
                      : false
                  }
                />
              </div>
            </div>
          )}

          <div className="input_group_pform">
            <div className="inputGropOne_form inputGrop ">
              <InputFields
                value={emailValue}
                placeholder="Email ID"
                id="email"
                type="email"
                name="email"
                hanldeInput={errors}
                errState={errors?.email ? true : false}
                errMsg={errors?.email}
                errClassName="input-err"
                onChangeEvent={(e) => HandleInputChange(e)}
                isDisabled={
                  companyName === SBI &&
                    sbiAutoKycData?.kycStatus === "CKYCSuccess" &&
                    sbiAutoKycData?.email !== ""
                    ? true
                    : false
                }
              />
            </div>
            <div className="inputGropOne_form inputGrop ">
              {
                (ownerValue !== "individualOwner" && (companyName == ICICI || companyName == DIGIT || companyName == ZUNO || companyName == SBI || companyName == TATA || companyName == HDFC || companyName == UNITED || companyName==NATIONAL)) && (
                  <div className="inputGropOne_form inputGrop ">
                    <label htmlFor="dobPform">
                      <CustomDateField
                        className="pForm_datePicker pForm_datePicker-tab "
                        btnId="doiCopyBtn"
                        textId="doiCopyTxt"
                        imgId="doiCopyImg"
                        dateValue={DobClone}
                        placeholder="Date of Incorporation"
                        placeholderId="doiPlaceholderId"
                        calendarReveal={ShowDobCalendar}
                        setCalendarReveal={setShowDobCalendar}
                      />
                    </label>
                    <p className="err-msg">{errors?.dob ? errors?.dob : ""}</p>
                  </div>
                )
              }


              {
                (ownerValue !== "individualOwner" && companyName === SHREERAM && businessType !== "New") && (
                  <div className="input_group_pform" style={{ marginTop: "-0.6rem" }}>
                    <div className="inputGropOne_form inputGrop inputGrop_caseTwo ">
                      <p className="customLabel_pform" style={{ fontSize: '0.7rem' }}>Nil Depreciation in previous policy?</p>
                      <div className="radioContainer-pform">
                        <RadioButton
                          label="Yes"
                          className=""
                          id="YesZerDep"
                          groupName="isNilDepInPrevPolicy"
                          value={true}
                          changeEvent={(e) => HandleInputChange(e)}
                          isChecked={formData?.isNilDepInPrevPolicy === "true" ? true : false}
                        />
                        <RadioButton
                          label="No"
                          className=""
                          id="NoZerDep"
                          groupName="isNilDepInPrevPolicy"
                          value={false}
                          changeEvent={(e) => HandleInputChange(e)}
                          isChecked={formData?.isNilDepInPrevPolicy === "false" ? true : false}
                        />
                      </div>
                      <p className="err-msg">{errors?.isNilDepInPrevPolicy ? errors.isNilDepInPrevPolicy : ""}</p>
                      <p className="err-msg">
                        {(ShowNilDepState != true && ShowNilDepState != null) && ("if you have not taken zero deprecitation in previous policy, you can't include zero depreciation(engine protection, consumables) in your current policy.")}
                      </p>
                    </div>
                    <div className="inputGropOne_form inputGrop inputGrop_caseTwo ">
                    </div>
                  </div>
                )
              }

              {ownerValue == "individualOwner" &&
                (
                  <InputFields
                    value={phoneValue}
                    placeholder="Phone Number"
                    id="phoneNumber"
                    type="number"
                    name="phoneNumber"
                    hanldeInput={errors}
                    errState={errors?.phoneNumber ? errors?.phoneNumber : checkphone ? true : false}
                    errMsg={errors?.phoneNumber ? errors?.phoneNumber : checkphone && !errors?.phoneNumber ? " Phone Number already Exist (Duplicate Number)" : ""}
                    errClassName="input-err"
                    onChangeEvent={(e) => HandleInputChange(e)}
                    isDisabled={
                      companyName === SBI &&
                        sbiAutoKycData?.kycStatus === "CKYCSuccess"
                        ? true
                        : false
                    }
                  />
                )
              }
            </div>
          </div>

          {companyName == TATA && (
            <div className="input_group_pform">

              {companyName == TATA ?
                <div className="inputGropOne_form inputGrop ">
                  <InputFields
                    value={panValue}
                    placeholder="PAN Number"
                    id="kycPanNumber"
                    type="text"
                    name="kycPanNumber"
                    className="uppercaseText"
                    hanldeInput={errors}
                    onChangeEvent={(e) => HandleInputChange(e)}
                    errState={errors?.kycPanNumber ? true : false}
                    errMsg={errors?.kycPanNumber}
                    errClassName="input-err"
                  />
                </div>
                :
                <div className="inputGropOne_form inputGrop ">
                </div>
              }
              <div className="inputGropOne_form inputGrop ">
              </div>
            </div>
          )}

          {ownerValue !== "individualOwner" && companyName == UNITED && (
            <div className="input_group_pform" style={{ marginBottom: "-1.4rem" }}>
              <div className="inputGropOne_form inputGrop ">
                <InputFields
                  value={gstValue}
                  placeholder="Gst Number (optional)"
                  id="gstNumber"
                  type="text"
                  name="gstNumber"
                  className="uppercaseText"
                  errState={errors?.gstNumber ? true : false}
                  hanldeInput={errors}
                  errMsg={errors?.gstNumber}
                  errClassName="input-err"
                  onChangeEvent={(e) => HandleInputChange(e)}
                />
              </div>
              <div></div>
              {/* {
                (companyName == SHREERAM && !isPaCoverChecked) && (
                  <div className="inputGropOne_form inputGrop ">
                    <DropDown
                      dropDownMenu={paExclusionReasons}
                      value={formData?.paExclusionReason}
                      name="paExclusionReason"
                      classNameForHead="headTxtClassPform"
                      Head="Select PA Exclusion Reason"
                      label="PA Cover Reason"
                      arrowInGray={grayArrow}
                      inputId="paExclusionReason"
                      clickEvent={(e) => console.log(e)}
                      headClick={(e) =>
                        setShowPaExclusionReason(!ShowPaExclusionReason)
                      }
                      revealOptions={ShowPaExclusionReason}
                      afterTrueClass="dropDown_container NoBorderRadius"
                      className="dropDown_container"
                      valueSelectionEvent={HandleInputChange}
                      revealOptionsClasses=" dropDownOptions showdropDownOptions "
                      classNameTwo="dropDownOptions"
                      dropDownHeadClass="dropDownHead"
                      idForHead="relHead"
                      idForHeadTxt="relHeadTxt"
                      idForArrow="relHeadArrow"
                      dropdownId="relListDp"
                      idForParentObj="relParentDp"
                      errState={errors?.paExclusionReason ? true : false}
                      errMsg={errors?.paExclusionReason}
                      errClassName="input-err"
                    />
                  </div>
                )
              }
              {companyName == ICICI || companyName == DIGIT || companyName == ZUNO || companyName == SBI || companyName == TATA || companyName == HDFC ? (
                <div className="inputGropOne_form inputGrop ">
                  <label htmlFor="dobPform">
                    <CustomDateField
                      className="pForm_datePicker pForm_datePicker-tab "
                      btnId="doiCopyBtn"
                      textId="doiCopyTxt"
                      imgId="doiCopyImg"
                      dateValue={DobClone}
                      placeholder="Date of Incommmmrporation"
                      placeholderId="doiPlaceholderId"
                      calendarReveal={ShowDobCalendar}
                      setCalendarReveal={setShowDobCalendar}
                    />
                  </label>
                </div>
              ) : (
                <>
                  {
                    (companyName == SHREERAM && isPaCoverChecked) && (
                      <div className="inputGropOne_form inputGrop "></div>
                    )
                  }
                </>
              )} */}
            </div>
          )}

          {/* Third Party */}

          {
            companyName == TATA &&
              isZeroDepChecked &&
              bodyData?.data?.PolicyTypeValue !== "Third Party" &&
              ZeroDepValue !== 0 &&
              vehicleType === "2W" ? (
              <div className="input_group_pform">
                <div className="inputGropOne_form inputGrop inputGrop_caseTwo ">
                  <p className="customLabel_pform">Nil Depreciation in previous policy?</p>
                  <div className="radioContainer-pform">
                    <RadioButton
                      label="Yes"
                      className=""
                      id="YesZerDep"
                      groupName="isNilDepInPrevPolicy"
                      value={true}
                      changeEvent={(e) => HandleInputChange(e)}
                      isChecked={formData?.isNilDepInPrevPolicy === "true" ? true : false}
                    />
                    <RadioButton
                      label="No"
                      className=""
                      id="NoZerDep"
                      groupName="isNilDepInPrevPolicy"
                      value={false}
                      changeEvent={(e) => HandleInputChange(e)}
                      isChecked={formData?.isNilDepInPrevPolicy === "false" ? true : false}
                    />
                  </div>
                  <p className="err-msg">{errors?.isNilDepInPrevPolicy ? errors.isNilDepInPrevPolicy : ""}</p>
                  <p className="err-msg" >
                    {(ShowNilDepState != true && ShowNilDepState != null) && ("if you have not taken zero deprecitation in previous policy, you can't include zero depreciation in your current policy.")}
                  </p>
                </div>
                <div className="inputGropOne_form inputGrop"></div>
              </div>
            ) : null
          }

          {/* isPaCoverChecked, isZeroDepChecked, Third Party */}

          {
            (ownerValue == "individualOwner" && companyName == SHREERAM) && (
              <>
                {/* {
                  !isPaCoverChecked && !isZeroDepChecked && (
                    <div className="input_group_pform">
                      <div className="inputGropOne_form inputGrop ">
                        <DropDown
                          type="submit"
                          dropDownMenu={paExclusionReasons}
                          value={formData?.paExclusionReason}
                          name="paExclusionReason"
                          classNameForHead="headTxtClassPform"
                          Head="Select PA Exclusion Reason"
                          label="PA Exclusion"
                          arrowInGray={grayArrow}
                          inputId="paExclusionReason"
                          clickEvent={(e) => console.log(e)}
                          headClick={(e) =>
                            setShowPaExclusionReason(!ShowPaExclusionReason)
                          }
                          revealOptions={ShowPaExclusionReason}
                          afterTrueClass="dropDown_container NoBorderRadius"
                          className="dropDown_container"
                          valueSelectionEvent={HandleInputChange}
                          revealOptionsClasses=" dropDownOptions showdropDownOptions "
                          classNameTwo="dropDownOptions"
                          dropDownHeadClass="dropDownHead"
                          idForHead="relHead"
                          idForHeadTxt="relHeadTxt"
                          idForArrow="relHeadArrow"
                          dropdownId="relListDp"
                          idForParentObj="relParentDp"
                          errState={errors?.paExclusionReason ? true : false}
                          errMsg={errors?.paExclusionReason}
                          errClassName="input-err"
                        />
                      </div>
                      <div className="inputGropOne_form inputGrop inputGrop_caseTwo ">
                      </div>
                    </div>
                  )
                } */}

                {
                  isPaCoverChecked && (isZeroDepChecked && businessType !== "New") && (
                    <div className="input_group_pform">
                      <div className="inputGropOne_form inputGrop inputGrop_caseTwo">
                        <p className="customLabel_pform">Nillll Depreciation in previous policy?</p>
                        <div className="radioContainer-pform">
                          <RadioButton
                            label="Yes"
                            className=""
                            id="YesZerDep"
                            groupName="isNilDepInPrevPolicy"
                            value={true}
                            changeEvent={(e) => HandleInputChange(e)}
                            isChecked={formData?.isNilDepInPrevPolicy === "true" ? true : false}
                          />
                          <RadioButton
                            label="No"
                            className=""
                            id="NoZerDep"
                            groupName="isNilDepInPrevPolicy"
                            value={false}
                            changeEvent={(e) => HandleInputChange(e)}
                            isChecked={formData?.isNilDepInPrevPolicy === "false" ? true : false}
                          />
                        </div>
                        <p className="err-msg">{errors?.isNilDepInPrevPolicy ? errors.isNilDepInPrevPolicy : ""}</p>
                        <p className="err-msg" >
                          {(ShowNilDepState != true && ShowNilDepState != null) && ("if you have not taken zero deprecitation in previous policy, you can't include zero depreciation(engine protection, consumables) in your current policy.")}
                        </p>
                      </div>
                      <div className="inputGropOne_form inputGrop">
                      </div>
                    </div>
                  )
                }
                {/* 
                {
                  !isPaCoverChecked && (isZeroDepChecked && businessType !== "New") && (
                    <div className="input_group_pform">
                      <div className="inputGropOne_form inputGrop ">
                        <DropDown
                          type="submit"
                          dropDownMenu={paExclusionReasons}
                          value={formData?.paExclusionReason}
                          name="paExclusionReason"
                          classNameForHead="headTxtClassPform"
                          Head="Select PA Exclusion Reason"
                          label="PA Exclusion"
                          arrowInGray={grayArrow}
                          inputId="paExclusionReason"
                          clickEvent={(e) => console.log(e)}
                          headClick={(e) =>
                            setShowPaExclusionReason(!ShowPaExclusionReason)
                          }
                          revealOptions={ShowPaExclusionReason}
                          afterTrueClass="dropDown_container NoBorderRadius"
                          className="dropDown_container"
                          valueSelectionEvent={HandleInputChange}
                          revealOptionsClasses=" dropDownOptions showdropDownOptions "
                          classNameTwo="dropDownOptions"
                          dropDownHeadClass="dropDownHead"
                          idForHead="relHead"
                          idForHeadTxt="relHeadTxt"
                          idForArrow="relHeadArrow"
                          dropdownId="relListDp"
                          idForParentObj="relParentDp"
                          errState={errors?.paExclusionReason ? true : false}
                          errMsg={errors?.paExclusionReason}
                          errClassName="input-err"
                        />
                      </div>
                      <div className="inputGropOne_form inputGrop inputGrop_caseTwo ">
                        {
                          mappingAllData[0].PolicyTypeValue !== "Third Party" && (
                            <>
                              <p className="customLabel_pform">Nil Depreciation in previous policy?</p>
                              <div className="radioContainer-pform">
                                <RadioButton
                                  label="Yes"
                                  className=""
                                  id="YesZerDep"
                                  groupName="isNilDepInPrevPolicy"
                                  value={true}
                                  changeEvent={(e) => HandleInputChange(e)}
                                  isChecked={formData?.isNilDepInPrevPolicy === "true" ? true : false}
                                />
                                <RadioButton
                                  label="No"
                                  className=""
                                  id="NoZerDep"
                                  groupName="isNilDepInPrevPolicy"
                                  value={false}
                                  changeEvent={(e) => HandleInputChange(e)}
                                  isChecked={formData?.isNilDepInPrevPolicy === "false" ? true : false}
                                />
                              </div>
                              <p className="err-msg">{errors?.isNilDepInPrevPolicy ? errors.isNilDepInPrevPolicy : ""}</p>
                              <p className="err-msg" >
                                {(ShowNilDepState != true && ShowNilDepState != null) && ("if you have not taken zero deprecitation in previous policy, you can't include zero depreciation(engine protection, consumables) in your current policy.")}
                              </p>
                            </>
                          )
                        }

                      </div>
                    </div>
                  )
                } */}
              </>
            )
          }

          <section className="btnContainer_pform btnContainer_pform_collapsible">
            <Botton
              text="Cancel"
              className="button btn_pForm  secondry_btn"
              event={() => navigate(vehicleType == "2W" ? "/View-Plans-Bike?id=" + id : "/View-Plans?id=" + id)}
            />

            {transactionStatus?.isLoading || phonestatussloading ?
              <ButtonLoader className="btn_pForm" />
              : <Botton
                text="Save"
                type="submit"
                className="button btn_pForm"
                event={(e) =>
                  handleSubmitForm(Edit ? "kycDetails" : ParamaterForRoute, e, "editOwnerDetail")
                }
              />}
          </section>
        </form>

        {
          ShowDobCalendar && (
            <CustomCalendar
              value={DobClone}
              headText="Enter Date of Incorporation"
              name="doiCopy"
              btnId="doiCopyBtn"
              textId="doiCopyTxt"
              imgId="doiCopyImg"
              placeholderId="doiPlaceholderId"
              revealState={ShowDobCalendar}
              errMsg={errors?.dob}
              errClassName="input-err"
              setRevealState={setShowDobCalendar}
              handleCopiedDetails={handleDobClone}
              cancelCalendarValue={cancelCalendarValue}
              saveCalendarValue={saveCalendarValue}
              onClose={(e) => setShowDobCalendar(false)}
              maxDate={MaxDateValue}
            //  maxDate={maxDobDate}
            />
          )
        }

        {
          companyName === "acko" && (
            <AckoCreative />
          )
        }

      </div>
    </>
  );
};

export default ProposalOne;
