import React, { useEffect, useState } from "react";
import activeRadio from "../../Assets/Images/radioActiveSvg.svg";
import radio from "../../Assets/Images/radioSvg.svg";
import tick from "../../Assets/Images/success.svg";
import arrow from "../../Assets/Images/arrow.svg";
import Botton from "../Button/Button";
import { useSelector, useDispatch } from "react-redux";
import { carDetailsforProposal } from "../../utils/commonUtils";
import SkeletonLoader from "../Pages/Loader/Skeleton loader/SkeletonLoader";
let TotalPackage = 0
let seTplanType = []
let planforDigit = []

const ICICIPackageModal = (props) => {
    const {
        packageData,
        setCarDetails,
        carDetails,
        closeModal,
        carDetailsCopyState,
        setcarDetailsCopyState,
        test,
        tataQuoteLoading,
    } = props;
    const getBodyData = useSelector((state) => {
        return state?.bodyData;
    });


    const ICICIPlan = useSelector((state) => {
        return state?.iciciQuickQuote;
    });
    const Pro = useSelector((state) => {
        return state?.iciciQuickQuote2;
    });
    const DPro = useSelector((state) => {
        return state?.iciciQuickQuote3;
    });
    const DCpro = useSelector((state) => {
        return state?.iciciQuickQuote4;
    });
    const DCEpro = useSelector((state) => {
        return state?.iciciQuickQuote5;
    });
    const DCT = useSelector((state) => {
        return state?.iciciQuickQuote6;
    });
    const DCET = useSelector((state) => {
        return state?.iciciQuickQuote7;
    });
    const DCRTI = useSelector((state) => {
        return state?.iciciQuickQuote8;
    });
    const DCERTI = useSelector((state) => {
        return state?.iciciQuickQuote9;
    });
    const DCTRTI = useSelector((state) => {
        return state?.iciciQuickQuote10;
    });
    const DCETRTI = useSelector((state) => {
        return state?.iciciQuickQuote11;
    });
    let DigitQoute = []

    if (ICICIPlan.data) {
        if (ICICIPlan?.data?.data?.quote?.riskDetails?.keyProtect && ICICIPlan?.data?.data?.quote?.riskDetails?.zeroDepreciation && ICICIPlan?.data?.data?.quote?.riskDetails?.consumables && ICICIPlan?.data?.data?.quote?.riskDetails?.roadSideAssistance) {
            const vehicleBreakup = carDetailsforProposal("icici", ICICIPlan, getBodyData, test, 'RSA With Key 1');
            DigitQoute.push({ DisplayName: "RSA With Key 1", planName: "RSA With Key 1", netPremium: vehicleBreakup?.DuePremium })
        }
    }

    if (ICICIPlan.data) {
        if (ICICIPlan?.data?.data?.quote?.riskDetails?.keyProtect && ICICIPlan?.data?.data?.quote?.riskDetails?.zeroDepreciation && ICICIPlan?.data?.data?.quote?.riskDetails?.consumables && ICICIPlan?.data?.data?.quote?.riskDetails?.roadSideAssistance && ICICIPlan?.data?.data?.quote?.riskDetails?.engineProtect

        ) {
            const vehicleBreakup = carDetailsforProposal("icici", ICICIPlan, getBodyData, test, 'RSA With Key 2');
            DigitQoute.push({ DisplayName: "RSA With Key 2", planName: "RSA With Key 2", netPremium: vehicleBreakup?.DuePremium })
        }
    }
    if (ICICIPlan.data) {
        if (ICICIPlan?.data?.data?.quote?.riskDetails?.keyProtect && ICICIPlan?.data?.data?.quote?.riskDetails?.zeroDepreciation && ICICIPlan?.data?.data?.quote?.riskDetails?.consumables && ICICIPlan?.data?.data?.quote?.riskDetails?.roadSideAssistance && ICICIPlan?.data?.data?.quote?.riskDetails?.tyreProtect) {
            const vehicleBreakup = carDetailsforProposal("icici", ICICIPlan, getBodyData, test, 'RSA With Key 3');
            DigitQoute.push({ DisplayName: "RSA With Key 3", planName: "RSA With Key 3", netPremium: vehicleBreakup?.DuePremium })
        }
    }
    if (ICICIPlan.data) {
        if (ICICIPlan?.data?.data?.quote?.riskDetails?.keyProtect && ICICIPlan?.data?.data?.quote?.riskDetails?.zeroDepreciation && ICICIPlan?.data?.data?.quote?.riskDetails?.consumables && ICICIPlan?.data?.data?.quote?.riskDetails?.roadSideAssistance && ICICIPlan?.data?.data?.quote?.riskDetails?.tyreProtect && ICICIPlan?.data?.data?.quote?.riskDetails?.consumables && ICICIPlan?.data?.data?.quote?.riskDetails?.roadSideAssistance && ICICIPlan?.data?.data?.quote?.riskDetails?.engineProtect) {
            const vehicleBreakup = carDetailsforProposal("icici", ICICIPlan, getBodyData, test, 'RSA With Key 4');
            DigitQoute.push({ DisplayName: "RSA With Key 4", planName: "RSA With Key 4", netPremium: vehicleBreakup?.DuePremium })
        }
    }
    if (ICICIPlan.data) {
        if (ICICIPlan?.data?.data?.quote?.riskDetails?.keyProtect && ICICIPlan?.data?.data?.quote?.riskDetails?.zeroDepreciation && ICICIPlan?.data?.data?.quote?.riskDetails?.consumables && ICICIPlan?.data?.data?.quote?.riskDetails?.roadSideAssistance && ICICIPlan?.data?.data?.quote?.riskDetails?.tyreProtect && ICICIPlan?.data?.data?.quote?.riskDetails?.consumables && ICICIPlan?.data?.data?.quote?.riskDetails?.roadSideAssistance && ICICIPlan?.data?.data?.quote?.riskDetails?.engineProtect && ICICIPlan?.data?.data?.quote?.riskDetails?.
            returnToInvoice
        ) {
            const vehicleBreakup = carDetailsforProposal("icici", ICICIPlan, getBodyData, test, 'RSA With Key 5');
            DigitQoute.push({ DisplayName: "RSA With Key 5", planName: "RSA With Key 5", netPremium: vehicleBreakup?.DuePremium })
        }
    }

    // Plan 2 for ICICI
    if (ICICIPlan.data && ICICIPlan.data.msg == "done") {
        if (ICICIPlan?.data?.data?.quote?.riskDetails?.keyProtect && ICICIPlan?.data?.data?.quote?.riskDetails?.zeroDepreciation && ICICIPlan?.data?.data?.quote?.riskDetails?.consumables && ICICIPlan?.data?.data?.quote?.riskDetails?.roadSideAssistance && ICICIPlan?.data?.data?.quote?.riskDetails?.lossOfPersonalBelongings) {
            const vehicleBreakup = carDetailsforProposal("icici", ICICIPlan, getBodyData, test, 'RSA Plus 1');
            DigitQoute.push({ DisplayName: "RSA Plus 1", planName: "RSA Plus 1", netPremium: vehicleBreakup?.DuePremium })
        }
    }
    if (ICICIPlan.data) {
        if (ICICIPlan?.data?.data?.quote?.riskDetails?.keyProtect && ICICIPlan?.data?.data?.quote?.riskDetails?.zeroDepreciation && ICICIPlan?.data?.data?.quote?.riskDetails?.consumables && ICICIPlan?.data?.data?.quote?.riskDetails?.roadSideAssistance && ICICIPlan?.data?.data?.quote?.riskDetails?.lossOfPersonalBelongings && ICICIPlan?.data?.data?.quote?.riskDetails?.engineProtect) {
            const vehicleBreakup = carDetailsforProposal("icici", ICICIPlan, getBodyData, test, 'RSA Plus 2');
            DigitQoute.push({ DisplayName: "RSA Plus 2", planName: "RSA Plus 2", netPremium: vehicleBreakup?.DuePremium })
        }
    }
    if (ICICIPlan.data) {
        if (ICICIPlan?.data?.data?.quote?.riskDetails?.keyProtect && ICICIPlan?.data?.data?.quote?.riskDetails?.zeroDepreciation && ICICIPlan?.data?.data?.quote?.riskDetails?.consumables && ICICIPlan?.data?.data?.quote?.riskDetails?.roadSideAssistance && ICICIPlan?.data?.data?.quote?.riskDetails?.lossOfPersonalBelongings && ICICIPlan?.data?.data?.quote?.riskDetails?.tyreProtect) {
            const vehicleBreakup = carDetailsforProposal("icici", ICICIPlan, getBodyData, test, 'RSA Plus 3');
            DigitQoute.push({ DisplayName: "RSA Plus 3", planName: "RSA Plus 3", netPremium: vehicleBreakup?.DuePremium })
        }
    }
    if (ICICIPlan.data) {
        if (ICICIPlan?.data?.data?.quote?.riskDetails?.keyProtect && ICICIPlan?.data?.data?.quote?.riskDetails?.zeroDepreciation && ICICIPlan?.data?.data?.quote?.riskDetails?.consumables && ICICIPlan?.data?.data?.quote?.riskDetails?.roadSideAssistance && ICICIPlan?.data?.data?.quote?.riskDetails?.lossOfPersonalBelongings && ICICIPlan?.data?.data?.quote?.riskDetails?.tyreProtect && ICICIPlan?.data?.data?.quote?.riskDetails?.engineProtect) {
            const vehicleBreakup = carDetailsforProposal("icici", ICICIPlan, getBodyData, test, 'RSA Plus 4');
            DigitQoute.push({ DisplayName: "RSA Plus 4", planName: "RSA Plus 4", netPremium: vehicleBreakup?.DuePremium })
        }
    }
    if (ICICIPlan.data) {
        if (ICICIPlan?.data?.data?.quote?.riskDetails?.keyProtect && ICICIPlan?.data?.data?.quote?.riskDetails?.zeroDepreciation && ICICIPlan?.data?.data?.quote?.riskDetails?.consumables && ICICIPlan?.data?.data?.quote?.riskDetails?.roadSideAssistance && ICICIPlan?.data?.data?.quote?.riskDetails?.lossOfPersonalBelongings && ICICIPlan?.data?.data?.quote?.riskDetails?.tyreProtect && ICICIPlan?.data?.data?.quote?.riskDetails?.engineProtect && ICICIPlan?.data?.data?.quote?.riskDetails?.returnToInvoice) {
            const vehicleBreakup = carDetailsforProposal("icici", ICICIPlan, getBodyData, test, 'RSA Plus 5');
            DigitQoute.push({ DisplayName: "RSA Plus 5", planName: "RSA Plus 5", netPremium: vehicleBreakup?.DuePremium })
        }
    }


    const [ShowClaimList, setShowClaimList] = useState(false);
    const [planType, seTplanTypeDig] = useState([])


    //    packageData[0]?.payload?.data?.quote?.["Motor-Quick Quote"]?.contract?.coverages?.add

    const handleDropdown = () => {
        if (ShowClaimList) {
            setShowClaimList(false);
        }
    }

    // const sortedPlanKeys = Object.keys(packageData).sort((a, b) => {
    //     const numA = parseInt(a.substring(1));
    //     const numB = parseInt(b.substring(1));
    //     return numA - numB;
    // });

    const totalNetPremiums = {};

    // for (const planKey in packageData) {
    //     if (packageData?.hasOwnProperty(planKey)) {
    //         const packageDetails = packageData[planKey];
    //         const vehicleBreakup = carDetailsforProposal("digit", packageDetails, getBodyData, test);
    //         console.log( vehicleBreakup,'vehicleBreakup');
    //         // let totalNetPremium = vehicleBreakup.DuePremium;
    //         // const finalTotalPremium = totalNetPremium;
    //         // totalNetPremiums[planKey] = Math.round(finalTotalPremium);
    //     }
    // }

    // const countOfTataPackages = sortedPlanKeys?.length;

    const planNames = {
        P1: "RSA",
        P2: "Pro",
        P3: "D Pro",
        P4: "DC Pro",
        P5: "DCE Pro",
        P6: "DCT Pro",
        P7: "DCET Pro",
        P8: "DC-RTI Pro",
        P9: "DCE-RTI Pro",
        P10: "DCT-RTI Pro",
        P11: "DCET-RTI Pro",
    };


    const handleSelectedPlan = (planName) => {
        if (planName === carDetailsCopyState?.quotePackageNameICICICopy) {
            setcarDetailsCopyState((prevItems) => ({
                ...prevItems,
                quotePackageNameICICICopy: "",
            }));
        } else {
            setcarDetailsCopyState((prevItems) => ({
                ...prevItems,
                quotePackageNameICICICopy: planName,
            }));
        }
    };

    const setPlanPackage = (planName) => {
    
        setCarDetails((prevItems) => ({
            ...prevItems,
            quotePackageNameICICI: carDetailsCopyState?.quotePackageNameICICICopy,
        }));
        if(carDetailsCopyState?.quotePackageNameICICICopy){
            closeModal();       
        }
        
    };

    const resetPlanPackage = () => {
        setcarDetailsCopyState((prevItems) => ({
            ...prevItems,
            quotePackageNameICICICopy: carDetails?.quotePackageNameICICI,
        }));
        closeModal();
    }


    const planAddons = {
        "RSA": ["Road Side Assistance"],
        "RSA With Key 1": [
            "Zero Depreciation",
            "Consumable Expenses",
            "Road Side Assistance",
            " Including Key Replacement",

        ],


        "RSA With Key 2": [
            "Zero Depreciation",
            "Consumable Expenses",
            "Engine Secure",
            "Road Side Assistance",
            " Including Key Replacement",
        ],
        "RSA With Key 3": [
            "Zero Depreciation",
            "Consumable Expenses",
            "Tyre Protect",
            "Road Side Assistance",
            " Including Key Replacement",
        ],
        "RSA With Key 4": [
            "Zero Depreciation",
            "Consumable Expenses",
            "Engine Secure",
            "Tyre Protect",
            "Road Side Assistance",
            " Including Key Replacement",
        ],
        "RSA With Key 5": [
            "Zero Depreciation",
            "Consumable Expenses",
            "Engine Secure",
            "Tyre Protect",
            "Return to Invoice",
            "Road Side Assistance",
            " Including Key Replacement",
        ],
        "RSA Plus 1": [
            "Zero Depreciation",
            "Consumable Expenses",
            "Road Side Assistance",
            "Loss Of Personal Belongings",
            "Key Replacement",
        ],
        "RSA Plus 2": [
            "Zero Depreciation",
            "Consumable Expenses",
            "Engine Secure",
            "Road Side Assistance",
            "Loss Of Personal Belongings",
            "Key Replacement",
        ],

        "RSA Plus 3": [
            "Zero Depreciation",
            "Consumable Expenses",
            "Tyre Protect",
            "Road Side Assistance",
            "Loss Of Personal Belongings",
            "Key Replacement",
        ],
        "RSA Plus 4": [
            "Zero Depreciation",
            "Consumable Expenses",
            "Tyre Protect",
            "Engine Secure",
            "Road Side Assistance",
            "Loss Of Personal Belongings",
            "Key Replacement",
        ],
        "RSA Plus 5": [
            "Zero Depreciation",
            "Consumable Expenses",
            "Engine Secure",
            "Tyre Protect",
            "Return to Invoice",
            "Road Side Assistance",
            "Loss Of Personal Belongings",
            "Key Replacement",
        ],
        P12: [
            "Road Side Assistance",
            "Key Replacement",
            "Loss Of Personal Belongings",
        ],
        P13: [
            "Zero Depreciation",
            "Return to Invoice",
            "Road Side Assistance",
            "Consumable Expenses",
            "Tyre Protect",
            "Key Replacement",
            "Loss Of Personal Belongings",
        ],
    };

    const handleNumberOfClaim = (e) => {
        const { id, name, value } = e?.target || {};
        setCarDetails((prevItems) => ({
            ...prevItems,
            [name]: value,
        }))
    }
    let uniq = [...new Set(seTplanType)];

    const handleZeroDepValue = (selectedValue) => {
        setCarDetails((prevItems) => ({
            ...prevItems,
            isNilDepInPrevPolicy: selectedValue,
        }))
    }

    return (
        <>
            <div className="package-container full-height" onClick={handleDropdown} >
                <div className="head-addon-modal head-package-modal">
                    <div>
                        <p>ICICI Plans</p>
                        <p style={{ color: "#5086FF", fontSize: ".7rem" }} >({DigitQoute?.length} Plans Available)</p>
                    </div>
                    {
                        // ICICIPlan?.data?.data?.quote?.riskDetails?.zeroDepreciation ? (
                        //     <div className="dp-main-container" >
                        //         <div className="dp-main-head" onClick={() => setShowClaimList(!ShowClaimList)} >
                        //             <p> {carDetails?.numberOfClaim === "99" ? "Unlimited" : carDetails?.numberOfClaim}  Claim</p>
                        //             <p>Apply on Zero Dep</p>
                        //             <img src={arrow} />
                        //         </div>
                        //         {
                        //             ShowClaimList && (
                        //                 <div className="dp-main-options">
                        //                     <button type="button" name="numberOfClaim" value="1" onClick={(e) => handleNumberOfClaim(e)} > 1 Claim </button>
                        //                     <button type="button" name="numberOfClaim" value="2" onClick={(e) => handleNumberOfClaim(e)} > 2 Claim</button>
                        //                     {/* <button type="button" name="numberOfClaim" value="99" onClick={(e) => handleNumberOfClaim(e)} > Unlimited Claim </button> */}
                        //                 </div>
                        //             )
                        //         }
                        //     </div>
                        // ) : null
                    }

                </div>
                <div className="package-container-modal" >
                    <div className="switch-container-modal">
                        <div className="textnilldeppervious">Zero Dep in your previous policy?  </div>
                        <div className="switchBtn-container" >
                            <button className={carDetails?.isNilDepInPrevPolicy=="true"?"active-switchclass": "Non-active-switch-class"  } id="activeStwitchbtn" onClick={() => handleZeroDepValue("true")} >Yes</button>
                            <button className={carDetails?.isNilDepInPrevPolicy=="false"?"active-switchclass": "Non-active-switch-class"  } id="noactiveStwitchbtn"onClick={() => handleZeroDepValue("false")} >No</button>
                        </div>
                    </div>
                    {DigitQoute?.map((planKey) => (
                        <div
                            className={
                                carDetailsCopyState?.quotePackageNameICICICopy == planKey.planName
                                    ? "plan-package-container active-plan-package-container"
                                    : "plan-package-container"
                            }
                            key={planKey}
                        >
                            <div
                                className="plan-package-head"
                                onClick={() => handleSelectedPlan(planKey.planName)}
                            >
                                <div className="radio-container-package-popup">
                                    <img
                                        src={
                                            carDetailsCopyState?.quotePackageNameICICICopy ==
                                                planKey.planName
                                                ? activeRadio
                                                : radio
                                        }
                                        alt="radio"
                                    />
                                    <p>{planKey.DisplayName}</p>
                                </div>
                                <div className="radio-container-package-plan">
                                    {
                                        tataQuoteLoading ? (
                                            <SkeletonLoader height={30} />
                                        ) : (
                                            <>
                                                <p>₹ {planKey.netPremium}</p>
                                                <p>Inc GST</p>
                                            </>
                                        )
                                    }
                                </div>
                            </div>
                            {carDetailsCopyState?.quotePackageNameICICICopy ==
                                planKey.planName && (
                                    <div className="plan-breakup-package">
                                        {planAddons[planKey.planName]?.map((addon, index) => (
                                            <div className="addons-plan-tab" key={index}>
                                                <img src={tick} alt="tick" />
                                                <p>{addon}</p>
                                            </div>
                                        ))}
                                    </div>
                                )}
                        </div>
                    ))}
                </div>
                <div className="packahe-modal-btn-container">
                    <Botton
                        text="Cancel"
                        className="secondry_btn btn-packageModals"
                        event={resetPlanPackage}
                    />
                    <Botton
                        text={!carDetailsCopyState?.quotePackageNameICICICopy?"Select Plan":"Proceed"}
                        className={!carDetailsCopyState?.quotePackageNameICICICopy? "button btn-packageModals noSelectedPlan":"button btn-packageModals"}
                        event={setPlanPackage}
                    />
                </div>
            </div>
        </>
    );
};

export default ICICIPackageModal;
