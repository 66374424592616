import React, { useEffect, useState } from "react";
import user from "../../Assets/Images/userIcon.svg";
import InputFields from "../InputFields/InputFields";
import Botton from "../Button/Button";
import DropDown from "../DropDown/DropDown";
import grayArrow from "../../Assets/Images/grayArrow.svg";
import loader2 from "../../Assets/Loader/Loader.gif";
import "../RegNumberForm/loaderMain.css";
import { useLocation, Link, useNavigate, useHistory } from "react-router-dom";
import backIcon from "../../Assets/Images/backIcon.svg";
import NewUploadimg from '../../Assets/Images/NewUploadimg.svg'
import uploadiconnew from '../../Assets/Images/uploadiconnew.svg'
import UploadedIcon from '../../Assets/Images/UploadedIcon.svg'
import REupload from '../../Assets/Images/REupload.svg'
import sucess from '../../Assets/Images/sucess.svg'
import uploadimgNew from '../../Assets/Images/uploadimgNew.svg'
import kycDocImg from '../../Assets/Images/kycDocImg.svg'
import close from "../../Assets/Images/Close.svg";
import profile from "../../Assets/Images/profile.svg"
import { useSelector } from "react-redux";
import ButtonLoader from "../Pages/Loader/Button Loader/ButtonLoader";
import Modal from "react-modal"
import { Button } from "@mui/base";
import { NATIONAL } from "../../utils/commonUtils";

const KycForm = (props) => {
  const {
    kycAadharNumber,
    kycPanNumber,
    Image,
    kycAadharImg,
    kycAadharImgBack,
    kycPanImg,
    HandleInputChange,
    handleKycToggle,
    kycStatus,
    AadharImgName,
    AadharImgBackName,
    PanImgName,
    CINImgName,
    ProfileImgName,
    kycAPI,
    handleBackBtn,
    AutoKYCrejected,
    isDisabledButton,
    CompanyName,
    carOwner,
    bodyData,
    errors,
    typeOfKycDocument,
    companyName,
    CertificateOfIncorporationNumber,
    formData,
    setFormData,
    setAutoKYCrejected,
    setIsAutoKyc,
    modalIsOpenfail,
    setmodalIsOpenfail,
    setErrors,
    setmodalIsOpenAdhar,
    modalIsOpenAdhar,
    setmodalIsOpen,
    setIsDisabledButton,
    setmodalIsOpenfailAdhar,
    modalIsOpenfailAdhar,
    modalIsOpens,
    handlekycdata,
    setShreeRamKyc,
    shreeRamKyc,
    shreeRamKycCorp,
    failCheker,
    setFailCheker,
    setShreeRamKyccorp,
    setShowSuccesMsg,
    ShowSuccesMsg,
  } = props;

  const navigate = useNavigate();
  const [KYCmethod, setKYCmethod] = useState(null);
  const [ShowKYClist, setShowKYClist] = useState(false);
  const [myerror, setmyerror] = useState({})
  const search = useLocation().search;
  const transactionId = new URLSearchParams(search).get("id");
  const [closeModalfail, setcloseModalfail] = useState(false);
  const [openReEnterbtn, setopenReEnterbtn] = useState(false);

  const handleKycMethod = (e) => {
    setKYCmethod(e.target.value);
    HandleInputChange(e);
  };

  let vehicleType = bodyData?.data?.vehicleType;

  let transactionStatus = useSelector((state) => {
    return state.getTransactionStatus;
  });

  useEffect(() => {
    if (formData?.typeOfKycDocument === "PAN Card" && companyName == "icici") {
      setmodalIsOpen(false)
    }
    if (formData?.typeOfKycDocument === "Aadhar Card" && companyName == "icici") {
      setmodalIsOpenAdhar(false)
    }
  }, [formData?.typeOfKycDocument])

  const styles = {
    ...(ShowKYClist && !formData?.typeOfKycDocument && companyName!=="digit" && companyName!=="zuno" ? { marginTop: "50px" } : {})
  };

  
  const isMobile = window.innerWidth <= 610;
  const pancardboxstyle = {
    marginTop: !ShowKYClist ? (isMobile ? "-47px" : "-17px") : (isMobile ? "15px" : "25px")
  };


  const Shreeramstyle = {
    marginTop: (companyName == 'shreeram') ? (isMobile  ? "-54px" : "-18px"):(isMobile ? "-15px" : "-18px" )
  }



  useEffect(()=>{

  },[isMobile])

  const customStyles = {
    content: window.innerWidth <= 610
      ? {
        top: "auto",
        left: "0",
        right: "0",
        bottom: "0",
        margin: "0",
        transform: "none",
        borderRadius: "10px 10px 0 0",
        padding: "20px",
        height: "auto",
      }
      : {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        borderRadius: "10px",
        padding: "20px",
        height: "auto",
      },
  };

  // IMP: here if company is other than sbi then we need both pan and aadhar in dropdown in all case
  const myStyle = {
    marginTop: ShowKYClist ? "25px" : "-25px"
  }
  const styleforborderuploadfile = {
    border: errors.kycAadharImg ? "3px dashed #FF464D" : ""
  }
  const styleforborderuploadfile2 = {
    border: errors.kycAadharImgBack ? "3px dashed #FF464D" : ""
  }
  const styleforborderuploadfile3 = {
    border: errors.kycPanImg ? "3px dashed #FF464D" : ""
  }
  const styleforborderuploadfile4 = {
    border: errors.CertificateOfIncorporationFile ? "3px dashed #FF464D" : ""
  }
  const styleforborderuploadfile5 = {
    border: errors.kycPanImg
      ? "3px dashed #FF464D" : ""
  }

  const Mystyle = {
    marginTop: !AutoKYCrejected ? "0px" : "0px"
  }

  const Mystyles = {
    marginTop: (AutoKYCrejected && carOwner !== "isCompany" && (CompanyName == "icici"||companyName=='national')) ? "-25px" : "-75px"
  }
  console.log(Mystyles, "sddfgdsfgsdf");

  let kycMethodsList;

  if (CompanyName == "sbi") {
    if (!AutoKYCrejected) {
      if (carOwner === "individualOwner") {
        kycMethodsList = [
          { label: "PAN Card", value: "PAN Card" },
          { label: "Aadhar Card", value: "Aadhar Card" },
        ];
      } else {
        kycMethodsList = [
          { label: "PAN Card", value: "PAN Card" }
        ];
      }
    } else {
      if (carOwner === "individualOwner") {
        kycMethodsList = [{ label: "Aadhar Card", value: "Aadhar Card" }];
      } else {
        kycMethodsList = [
          {
            label: "Certificate of Incorporation",
            value: "Certificate of Incorporation",
          },
        ];
      }
    }
  }
   else if (CompanyName == "shreeram" || CompanyName == "icici"  ) {
    if (carOwner === "individualOwner") {
      kycMethodsList = [
        { label: "PAN Card", value: "PAN Card" },
        { label: "Aadhar Card", value: "Aadhar Card" },
      ];
    } else {
      kycMethodsList = [
        { label: "PAN Card", value: "PAN Card" },
        {
          label: "Certificate of Incorporation",
          value: "Certificate of Incorporation",
        },
      ];
    }
  } else if (CompanyName == "digit" || CompanyName == "acko") {
    if (carOwner === "individualOwner") {
      kycMethodsList = [
        { label: "PAN Card", value: "PAN Card" },
      ];
    } else {
      kycMethodsList = [
        { label: "PAN Card", value: "PAN Card" }
      ];
    }
  } else if (CompanyName == "zuno" || CompanyName == "united") {
    if (!AutoKYCrejected) {
      if (carOwner === "individualOwner") {
        kycMethodsList = CompanyName == "zuno" ? [
          { label: "PAN Card", value: "PAN Card" },
        ] : kycMethodsList = [
          { label: "PAN Card", value: "PAN Card" },
          { label: "Aadhar Card", value: "Aadhar Card" },
        ];
      } else {
        kycMethodsList = [
          { label: "PAN Card", value: "PAN Card" }
        ];
      }
    }
  } else if (CompanyName == "tata") {
    if (!AutoKYCrejected) {
      if (carOwner === "individualOwner") {
        kycMethodsList = [
          { label: "Aadhar Card", value: "Aadhar Card" },
        ];
      } else {
        kycMethodsList = [
          {
            label: "Certificate of Incorporation",
            value: "Certificate of Incorporation",
          },
        ];
      }
    } else if (AutoKYCrejected) {
      if (carOwner === "individualOwner") {
        kycMethodsList = [{ label: "Aadhar Card", value: "Aadhar Card" }];
      } else {
        kycMethodsList = [
          {
            label: "Certificate of Incorporation",
            value: "Certificate of Incorporation",
          },
        ];
      }
    }
  }
  else if (companyName=="national") {
    if (carOwner === "individualOwner") {
      kycMethodsList = [
        { label: "PAN Card", value: "PAN Card" },
        { label: "Aadhar Card", value: "Aadhar Card" },
      ];
    } else {
      kycMethodsList = [
        { label: "PAN Card", value: "PAN Card" },
        {
          label: "GST Number",
          value: "GST Number",
        },
      ];
    }
  } 
  console.log(shreeRamKyc, shreeRamKycCorp, companyName, "companyNamecompanyName");

  useEffect(() => {
    if (AutoKYCrejected && carOwner === "individualOwner") {
      setKYCmethod("Aadhar Card");
    } else if (AutoKYCrejected && carOwner !== "individualOwner") {
      setKYCmethod("Certificate of Incorporation");
    }
  }, [AutoKYCrejected]);

  const handlePopUp = () => {
    if (ShowKYClist) {
      setShowKYClist(false);
    }
  };
  const ValidateShreeramPan = (() => {
    const newErrors = {};
    let regpan = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;
    if (


      formData.kycPanNumber &&
      !regpan.test(formData.kycPanNumber)
    ) {
      // invalid aadhar card number
      newErrors.kycPanNumber = "Pan number is in invalid format";
    }
    if (!formData.kycPanImg || formData.kycPanImg === "undefined") {
      newErrors.kycPanImg = "Pan Image is required";

    }
    if (!formData.kycPanNumber) {
      newErrors.kycPanNumber = "Pan number is required";

    }
    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  })

  useEffect(() => {
    if (isDisabledButton) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }

    return () => {
      document.body.style.overflow = '';
    };
  }, [isDisabledButton]);

  const HandleOpenFailPan = (() => {

    if (!AutoKYCrejected) {
      handleKycToggle()
    }
  })



  const OpenHandleAdhar = (() => {
    setFormData((prevItems) => ({
      ...prevItems,
      typeOfKycDocument: "Aadhar Card",
    }));

    setopenReEnterbtn(true)

  })


  const CloseBtn = (() => {
    setmodalIsOpenfail(false)
    setIsAutoKyc(true)
    setAutoKYCrejected(false);
  })


  const handleBackKycBtn = (() => {
    setShreeRamKyc(false)
    setShreeRamKyccorp(false)


  })


  useEffect(() => {
    if (CompanyName == "Shreeram") {

      if (formData?.kycPanImgFileType) {
        setShreeRamKyc(true)
      }
    }

  }, [formData])



  console.log(typeOfKycDocument, "kycMethodsList");

  return (
    <>
      {/* For Pan Card faild */}
      <Modal
        isOpen={modalIsOpenfail}
        onRequestClose={closeModalfail}
        style={customStyles}
      >
        <>
          <div className="main-kyc">
            <img src={close} onClick={CloseBtn} alt="" srcSet="" style={{ cursor: "pointer" }} />
            <div className="kyc-icici">
              <img src={kycDocImg} alt="KycDocimg" />
              <p>PAN Verification Failed!</p>
            </div>
            <div className="kyc-main-second">
              {<div className="inputfield-des" >
                <p style={{ marginBottom: "8px" }}>{formData.carOwner == "isCompany" ? CompanyName==NATIONAL? "Enter GST Number" :"Enter CIN Number" : "Enter Aadhar Number"}</p>
                {formData.carOwner !== "isCompany" ? <>
                  <InputFields
                    type="number"
                    name="kycAadharNumber"
                    containerID="xyv"
                    label="XXXX XXXX XXXX"
                    className={`acko_kyc_input acko_kyc_input_case_two`}
                    value={kycAadharNumber}
                    onChangeEvent={(e) => HandleInputChange(e)}
                    errState={errors?.kycAadharNumber ? true : false}
                    errMsg={errors?.kycAadharNumber}
                    errClassName="input-err"
                  /> </> :
                  <>
                    {/* <InputFields
                      type="text"
                      name="CertificateOfIncorporationNumber"
                      id="CertificateOfIncorporationNumber"
                      containerID="xyv"
                      label="XXXX XXXX XXXX"
                      className="acko_kyc_input acko_kyc_input_case_two"
                      value={CertificateOfIncorporationNumber}
                      onChangeEvent={(e) => HandleInputChange(e)}
                      errState={errors?.CertificateOfIncorporationNumber ? true : false}
                      errMsg={errors?.CertificateOfIncorporationNumber}
                      errClassName="input-err"
                    /> */}
                    <InputFields
                    type="text"
                    containerID="xyv"
                    name={CompanyName==NATIONAL ?"gstNumber":"CertificateOfIncorporationNumber"}
                    id="CertificateOfIncorporationNumber"
                    // id="kycPanNumberAcko"
                    label="XXXX XXXX XXXX"
                    // placeholder="XXXXXXXXXXXXXXX"
                    className="acko_kyc_input acko_kyc_input_case_two"
                    value={ companyName==NATIONAL ? formData?. gstNumber: CertificateOfIncorporationNumber}
                    onChangeEvent={(e) => HandleInputChange(e)}
                    errState={errors?.CertificateOfIncorporationNumber ? true : false}
                    errMsg={errors?.CertificateOfIncorporationNumber}
                    errClassName="input-err"
                  />
                    </>
                }
              </div>
              }
              <Botton
                text={formData.carOwner == "isCompany" ? CompanyName==NATIONAL? "Validate GST": "Validate CIN " : "Validate Aadhar"}
                className="button btn_pForm btnforKyc"
                id="btnforKyc"
                // event={ReAutokycPan}
                event={handleKycToggle}
              />
            </div>
          </div>
        </>
      </Modal>

      {/* For uploade the image of document into the kyc  */}
      <Modal
        isOpen={ShowSuccesMsg}
        style={customStyles}
      >
        <div className="uploaded_success_container">
          <img src={sucess} />
          <p>
            Your file has been uplaoded <br /> successfully.
          </p>
        </div>
      </Modal>



      <div id="cover-spin" style={{ display: isDisabledButton ? "" : "none" }}>
        <img src={loader2} className="gifLoader"></img>
      </div>


      <div className="titleContainer">
        <p></p>
        <p>All fields are mandatory</p>
      </div>

      <form action="" className="kycFormContainer" onClick={handlePopUp}>
        <div className="kyc_container_photo_conteent  mg-fix">
          {Image ? (
            <div className="kyc_container_photo_img">
              {/* <img src={Image} className="profileImg-kyc" alt="" srcSet="" /> */}
            </div>
          ) : (
            // <div className="label-container" >
            //   <label
            //     htmlFor="uploadImg"
            //     className='kyc_container_photo'
            //   >
            //     <input
            //       type="file"
            //       className="hideRealUploadBtn"
            //       id="uploadImg"
            //       accept=".pdf, .jpg, .jpeg, .png"
            //       name="kycProfileImg"
            //       onChange={(e) => HandleInputChange(e)}
            //       hidden
            //       disabled="disabled"
            //     />
            //     <img src={user} alt="" srcSet="" />
            //     {/* <p>
            //       Upload your <br /> Photo.
            //     </p> */}
            //   </label>
            //   {/* {errors.kycProfileImg ? (
            //     <p className="err-msg">{errors.kycProfileImg}</p>
            //   ) : (
            //     ""
            //   )} */}
            // </div>
            ""
          )}

          <div className="kyc_container_content">
            <div className="kyc_info_container">
              <p className="title_kyc_pf">Update KYC</p>
              <p className="small_text">
                From January 1, 2023, KYC authentication has been a mandatory
                process to purchase insurance policies from all insurance
                providers in India.
              </p>
              <p className="title_kyc_pf">
                Your details are safe with us. You can complete your KYC in 1
                min.
              </p>
            </div>
          </div>

          <p className="bottomInfo_kyc">
            {ProfileImgName ? (
              <span className="kyc-succes-upload">Uploaded</span>
            ) : (
              ""
            )}
          </p>
        </div>

        {/* CHOOSE KYC METHODS */}

        <div className="input_group_pform input_group_pform-flex " style={Mystyle}>
          <div className="inputGropOne_form inputGrop " style={{ marginTop: "20px" }} >
            {!AutoKYCrejected ? <p className="textcolo">Choose your KYC</p> : ""}
            {AutoKYCrejected && (companyName === "icici" || companyName=='national' ) && carOwner != "individualOwner" ?
              (
                ''
              ) :
              (companyName === 'shreeram' ? '' :
                <DropDown
                  dropDownMenu={AutoKYCrejected && typeOfKycDocument !== "C"  || CompanyName=="digit" || CompanyName=="zuno" ? null : kycMethodsList}
                  value={companyName=='digit' || CompanyName=="zuno" ? "Pan Card": typeOfKycDocument}
                  name="typeOfKycDocument"
                  classNameForHead="headTxtClassPform"
                  Head={
                    AutoKYCrejected
                      ? "Select Method To Upload Document"
                      : "Select Kyc Method"
                  }
                  label={
                    AutoKYCrejected ? "Select Kyc Method" : "Select Kyc Method"
                  }
                  labelclass="rmvLeft"
                  dropDownColor="true"
                  arrowInGray={( CompanyName=="digit" || CompanyName=="zuno") ? "HideArrowforDigitandZuno":grayArrow}
                  inputId="nomineeRelation"
                  clickEvent={(e) => console.log(e)}
                  headClick={(e) => setShowKYClist(!ShowKYClist)}
                  revealOptions={ShowKYClist}
                  afterTrueClass="dropDown_container NoBorderRadius"
                  className="dropDown_container"
                  valueSelectionEvent={handleKycMethod}
                  KYCmethod={KYCmethod}
                  revealOptionsClasses=" dropDownOptions showdropDownOptions "
                  classNameTwo="dropDownOptions"
                  dropDownHeadClass="dropDownHead"
                  idForHead="relHead"
                  idForHeadTxt="relHeadTxt"
                  idForArrow="relHeadArrow"
                  dropdownId="relListDp"
                  idForParentObj="relParentDp"
                  errState={errors?.typeOfKycDocument ? true : false}
                  errMsg={errors?.typeOfKycDocument}
                  errClassName="input-err"
                />)}
          </div>


          {/* {AutoKYCrejected && */}
          <div className="inputGropOne_form inputGrop " style={{ marginTop: "20px" }}>
            {
              (AutoKYCrejected && formData?.carOwner !== "isCompany") &&
                formData?.typeOfKycDocument === "Aadhar Card" ? (
                <div className="inputGropOne_form inputGrop " >
                  <InputFields
                    type="number"
                    name="kycAadharNumber"
                    containerID="xyv"
                    label="XXXX XXXX XXXX"
                    // id="kycPanNumberAcko"
                    placeholder="xxxxx1234xdfc"
                    className={`acko_kyc_input acko_kyc_input_case_two`}
                    value={kycAadharNumber}
                    onChangeEvent={(e) => HandleInputChange(e)}
                    errState={errors?.kycAadharNumber ? true : false}
                    errMsg={errors?.kycAadharNumber}
                    errClassName="input-err"
                  />
                </div>
              ) : ""
            }

            {
              AutoKYCrejected &&
                formData?.typeOfKycDocument === "PAN Card" ? (
                <div className="inputGropOne_form inputGrop ">
                  <InputFields
                    value={kycPanNumber}
                    placeholder="PAN Number"
                    id="kycPanNumbe"
                    containerID="xyv"
                    label="XXXX XXXX XXXX"
                    type="text"
                    name="kycPanNumber"
                    className="uppercaseText"
                    onChangeEvent={(e) => HandleInputChange(e)}
                    errState={errors?.kycPanNumber ? true : false}
                    errMsg={errors?.kycPanNumber}
                    errClassName="input-err"
                  />
                </div>
              ) : ""
            }
          </div>
          {/* // } */}
        </div>




        {/* Here i chane some code for kyc upload */}
        <div className="input_group_pform" style={Shreeramstyle}>
          {
            formData?.carOwner == "individualOwner" && companyName == "shreeram" ?
              !shreeRamKyc ?

                <div className="inputGropOne_form inputGrop ">
                  <p className="text-bold" style={{ marginBottom: "20px" }} >Enter PAN Number</p>
                  <InputFields
                    value={kycPanNumber}
                    placeholder="PAN Number"
                    id="kycPanNumberr"
                    containerID="xyv"
                    label="XXXX XXXX XXXX"
                    type="text"
                    name="kycPanNumber"
                    className="uppercaseText"
                    onChangeEvent={(e) => HandleInputChange(e)}
                    errState={errors?.kycPanNumber ? true : false}
                    errMsg={errors?.kycPanNumber}
                    errClassName="input-err"
                  />
                </div>
                : <div className="input_group_pform input_group_pform-flex " >
                  <div className="inputGropOne_form inputGrop ">
                    <p className="text-bold" style={{ marginBottom: "20px" }} >Enter Aadhar Number</p>
                    <InputFields
                      value={kycAadharNumber}
                      placeholder="Aadhar Number"
                      id="kycAadharNumberr"
                      containerID="xyv"
                      label="XXXX XXXX XXXX"
                      type="number"
                      name="kycAadharNumber"
                      onChangeEvent={(e) => HandleInputChange(e)}
                      errState={errors?.kycAadharNumber ? true : false}
                      errMsg={errors?.kycAadharNumber}
                      errClassName="input-err"
                    />
                  </div>
                  <div className="inputGropOne_form inputGrop ">
                    <p className="text-bold" style={{ marginBottom: "20px" }} >Enter Father Name</p>
                    <InputFields
                      value={formData.fathersName}
                      placeholder="Father Name"
                      id="fathersName"

                      // label="Fahter Name"
                      type="text"
                      name="fathersName"
                      onChangeEvent={(e) => HandleInputChange(e)}
                      errState={errors?.fathersName ? true : false}
                      errMsg={errors?.fathersName}
                      errClassName="input-err"
                    />
                  </div>
                </div>

              : !shreeRamKycCorp && companyName == "shreeram" ?
                <div className="inputGropOne_form inputGrop ">
                  <p className="text-bold" style={{ marginBottom: "20px" }} >Enter PAN Number</p>
                  <InputFields
                    value={kycPanNumber}
                    placeholder="PAN Number"
                    id="kycPanNumberr"
                    containerID="xyv"
                    label="XXXX XXXX XXXX"
                    type="text"
                    name="kycPanNumber"
                    className="uppercaseText"
                    onChangeEvent={(e) => HandleInputChange(e)}
                    errState={errors?.kycPanNumber ? true : false}
                    errMsg={errors?.kycPanNumber}
                    errClassName="input-err"
                  />
                </div>
                : companyName == "shreeram" &&
                <div className="inputGropOne_form inputGrop ">
                  <p className="text-bold" style={{ marginBottom: "20px" }} >Enter Incorporation Number</p>
                  <InputFields
                    value={CertificateOfIncorporationNumber}
                    // placeholder="Certificate of Incorporation Number"
                    id="kycPanNumberr"
                    label="XXXX XXXX XXXX"
                    containerID="xyv"
                    type="text"
                    name="CertificateOfIncorporationNumber"
                    className="uppercaseText"
                    onChangeEvent={(e) => HandleInputChange(e)}
                    errState={errors?.CertificateOfIncorporationNumber ? true : false}
                    errMsg={errors?.CertificateOfIncorporationNumber}
                    errClassName="input-err"
                  />
                </div>

          }
        </div> {
           <div className="input_group_pform  ">
            <div className="inputGropOne_form inputGrop">
              {(CompanyName=="digit"  || CompanyName=="zuno") &&
              <InputFields
              type="text"
              name="kycPanNumber"
              containerID="xyv"
              label="XXXX XXXX XXXX"
              // id="kycPanNumberAcko"
              // placeholder="xxxxx1234xdfc"
              className={`acko_kyc_input acko_kyc_input_case_two`}
              value={kycPanNumber}
              onChangeEvent={(e) => HandleInputChange(e)}
              errState={errors?.kycPanNumber ? true : false}
              errMsg={errors?.kycPanNumber}
              errClassName="input-err"
            />
              }
          
        </div>
        <div></div>
        </div>
        }

        {KYCmethod !== null && !AutoKYCrejected && (
          <div className="input_group_pform  " style={pancardboxstyle}>
            {formData?.typeOfKycDocument === "Aadhar Card" ? (
              <InputFields
                type="number"
                name="kycAadharNumber"
                containerID="xyv"
                label="XXXX XXXX XXXX"
                className={`acko_kyc_input acko_kyc_input_case_two`}
                value={kycAadharNumber}
                onChangeEvent={(e) => HandleInputChange(e)}
                errState={errors?.kycAadharNumber ? true : false}
                errMsg={errors?.kycAadharNumber}
                errClassName="input-err"
              />
            ) : (
              formData?.typeOfKycDocument === "PAN Card" ?                    //change for some design mistake 
                <InputFields
                  type="text"
                  name="kycPanNumber"
                  containerID="xyv"
                  label="XXXX XXXX XXXX"
                  // id="kycPanNumberAcko"
                  // placeholder="xxxxx1234xdfc"
                  className={`acko_kyc_input acko_kyc_input_case_two`}
                  value={kycPanNumber}
                  onChangeEvent={(e) => HandleInputChange(e)}
                  errState={errors?.kycPanNumber ? true : false}
                  errMsg={errors?.kycPanNumber}
                  errClassName="input-err"
                />
                :
                <div className="inputGropOne_form inputGrop ">
                  <InputFields
                    type="text"
                    containerID="xyv"
                    name={CompanyName==NATIONAL ?"gstNumber":"CertificateOfIncorporationNumber"}
                    id="CertificateOfIncorporationNumber"
                    // id="kycPanNumberAcko"
                    label="XXXX XXXX XXXX"
                    // placeholder="XXXXXXXXXXXXXXX"
                    className="acko_kyc_input acko_kyc_input_case_two"
                    value={ companyName==NATIONAL ? formData?. gstNumber: CertificateOfIncorporationNumber}
                    onChangeEvent={(e) => HandleInputChange(e)}
                    errState={errors?.CertificateOfIncorporationNumber ? true : false}
                    errMsg={errors?.CertificateOfIncorporationNumber}
                    errClassName="input-err"
                  />

                </div>

            )}
            <div></div>
          </div>
        )}

        {companyName === "shreeram" && (
          <>
            {carOwner == "individualOwner" && (
              <>
                <div className="input_group_pform input_group_pform-flex " style={{ gap: "3.7rem" }}>
                  {
                    !shreeRamKyc &&
                    <>
                      {
                        formData?.kycPanImgFileType ?
                          <div className="inputGropOne_form inputGrop " >
                            <p className="kyc_label_acko  textbld" >Upload PAN Photo</p>
                            <div className="inputGropOne_form inputGrop inputGropKyc-custom_acko_kyc ">
                              <p style={{ color: "#19B920" }}>Photo Uploaded Successfully</p>
                              <input
                                type="file"
                                className="hideRealUploadBtn"
                                id="uploadImg-PAN"
                                accept="image/*, application/pdf"
                                name="kycPanImg"
                                onChange={(e) => HandleInputChange(e)}
                                hidden
                              />
                              <label
                                className="customLabel-kyc_acko2"
                                htmlFor="uploadImg-PAN"
                              >
                                <p className="btn-kyc_acko"> <img src={REupload} /> Re-Upload File</p>
                              </label>
                            </div>
                          </div>
                          :
                          <div className="inputGropOne_form inputGrop ">
                            <p className="kyc_label_acko  textbld" >Upload PAN Photo</p>
                            <div className="inputGropOne_form inputGrop inputGropKyc-custom_acko_kyc " style={styleforborderuploadfile5}>
                              <p>PDF, JPEG, PNG or JPG formats only</p>
                              <input
                                type="file"
                                className="hideRealUploadBtn"
                                id="uploadImg-PAN"
                                accept="image/*, application/pdf"
                                name="kycPanImg"
                                onChange={(e) => HandleInputChange(e)}
                                hidden
                              />
                              <label
                                className="customLabel-kyc_acko"
                                htmlFor="uploadImg-PAN"
                              >
                                <p className="btn-kyc_acko"> <img src={NewUploadimg} /> Upload File</p>
                              </label>
                            </div>
                          </div>
                      }
                    </>
                  }
                  <div className="inputGropOne_form inputGrop ">
                  </div>
                </div>
                <div className="input_group_pform input_group_pform-flex " style={{ gap: "3.7rem", marginTop: "-63px" }}>
                  <div className="inputGropOne_form inputGrop ">
                  </div>
                </div>


                <div className="input_group_pform input_group_pform-flex "  >
                  {
                    shreeRamKyc && <>
                      {
                        formData?.kycAadharImg ?
                          <div className="inputGropOne_form inputGrop " >
                            <p className="kyc_label_acko  textbld" >Upload Aadhar Front Photo </p>
                            <div className="inputGropOne_form inputGrop inputGropKyc-custom_acko_kyc ">
                              <p style={{ color: "#19B920" }}>Photo Uploaded Successfully</p>
                              <input
                                type="file"
                                className="hideRealUploadBtn"
                                id="uploadImg-Aadhar"
                                accept="image/*, application/pdf"
                                name="kycAadharImg"
                                onChange={(e) => HandleInputChange(e)}
                                hidden
                              />
                              <label
                                className="customLabel-kyc_acko2"
                                htmlFor="uploadImg-Aadhar"
                              >
                                <p className="btn-kyc_acko"> <img src={REupload} /> Re-Upload File</p>
                              </label>
                            </div>
                          </div>
                          :
                          <div className="inputGropOne_form inputGrop ">
                            <p className="kyc_label_acko  textbld" >Upload Aadhar Front Photo</p>
                            <div className="inputGropOne_form inputGrop inputGropKyc-custom_acko_kyc " style={styleforborderuploadfile}>
                              <p>PDF, JPEG, PNG or JPG formats only</p>
                              <input
                                type="file"
                                className="hideRealUploadBtn"
                                id="uploadImg-Aadhar"
                                accept="image/*, application/pdf"
                                name="kycAadharImg"
                                onChange={(e) => HandleInputChange(e)}
                                hidden
                              />
                              <label
                                className="customLabel-kyc_acko"
                                htmlFor="uploadImg-Aadhar"
                              >
                                <p className="btn-kyc_acko"> <img src={NewUploadimg} /> Upload File</p>
                              </label>
                            </div>
                          </div>
                      }
                    </>
                  }


                  {
                    shreeRamKyc &&
                    <>
                      {
                        formData?.kycAadharImgBack ?
                          <div className="inputGropOne_form inputGrop " >
                            <p className="kyc_label_acko  textbld" >Upload Aadhar Back Photo </p>
                            <div className="inputGropOne_form inputGrop inputGropKyc-custom_acko_kyc ">
                              <p style={{ color: "#19B920" }}>Photo Uploaded Successfully</p>
                              <input
                                type="file"
                                className="hideRealUploadBtn"
                                id="uploadImg-Aadhar-Back"
                                accept="image/*, application/pdf"
                                name="kycAadharImgBack"
                                onChange={(e) => HandleInputChange(e)}
                                hidden
                              />
                              <label
                                className="customLabel-kyc_acko2"
                                htmlFor="uploadImg-Aadhar-Back"
                              >
                                <p className="btn-kyc_acko"> <img src={REupload} /> Re-Upload File</p>
                              </label>
                            </div>
                          </div>
                          :
                          <div className="inputGropOne_form inputGrop ">
                            <p className="kyc_label_acko  textbld" >Upload Aadhar Back Photo</p>
                            <div className="inputGropOne_form inputGrop inputGropKyc-custom_acko_kyc " style={styleforborderuploadfile2}>
                              <p>PDF, JPEG, PNG or JPG formats only</p>
                              <input
                                type="file"
                                className="hideRealUploadBtn"
                                id="uploadImg-Aadhar-Back"
                                accept="image/*, application/pdf"
                                name="kycAadharImgBack"
                                onChange={(e) => HandleInputChange(e)}
                                hidden
                              />
                              <label
                                className="customLabel-kyc_acko"
                                htmlFor="uploadImg-Aadhar-Back"
                              >
                                <p className="btn-kyc_acko"> <img src={NewUploadimg} /> Upload File</p>
                              </label>
                            </div>
                          </div>
                      }
                    </>
                  }
                </div>
              </>
            )}
            {carOwner != "individualOwner" && (      // Adding for CIN  KYC 
              <>
                <div className="input_group_pform input_group_pform-flex ">
                  {!shreeRamKycCorp &&
                    <>
                      <div className="inputGropOne_form inputGrop fdfjkhsha " style={{ gap: "3.8rem" }}>
                        {
                          formData?.kycPanImgFileType ?
                            <div className="inputGropOne_form inputGrop " >
                              <p className="kyc_label_acko  textbld" >Upload PAN Photo</p>
                              <div className="inputGropOne_form inputGrop inputGropKyc-custom_acko_kyc ">
                                <p style={{ color: "#19B920" }}>Photo Uploaded Successfully</p>
                                <input
                                  type="file"
                                  className="hideRealUploadBtn"
                                  id="uploadImg-PAN"
                                  accept="image/*, application/pdf"
                                  name="kycPanImg"
                                  onChange={(e) => HandleInputChange(e)}
                                  hidden
                                />
                                <label
                                  className="customLabel-kyc_acko2"
                                  htmlFor="uploadImg-PAN"
                                >
                                  <p className="btn-kyc_acko"> <img src={REupload} /> Re-Upload File</p>
                                </label>
                              </div>
                            </div>
                            :
                            <div className="inputGropOne_form inputGrop ">
                              <p className="kyc_label_acko  textbld" >Upload PAN Photo</p>
                              <div className="inputGropOne_form inputGrop inputGropKyc-custom_acko_kyc " style={styleforborderuploadfile5}>
                                <p>PDF, JPEG, PNG or JPG formats only</p>
                                <input
                                  type="file"
                                  className="hideRealUploadBtn"
                                  id="uploadImg-PAN"
                                  accept="image/*, application/pdf"
                                  name="kycPanImg"
                                  onChange={(e) => HandleInputChange(e)}
                                  hidden
                                />
                                <label
                                  className="customLabel-kyc_acko"
                                  htmlFor="uploadImg-PAN"
                                >
                                  <p className="btn-kyc_acko"> <img src={NewUploadimg} /> Upload File</p>
                                </label>
                              </div>
                            </div>
                        }
                      </div>
                      <div></div>
                    </>
                  }

                  {shreeRamKycCorp &&
                    <>
                      <div className="inputGropOne_form inputGrop fdfjkhsha " style={{ gap: "3.7rem" }}>
                        {
                          formData?.CertificateOfIncorporationFileFileType
                            ?
                            <div className="inputGropOne_form inputGrop " >
                              <p className="kyc_label_acko  textbld" >Upload Certificate Of Incorporation Photo</p>
                              <div className="inputGropOne_form inputGrop inputGropKyc-custom_acko_kyc ">
                                <p style={{ color: "#19B920" }}>Photo Uploaded Successfully</p>
                                <input
                                  type="file"
                                  className="hideRealUploadBtn"
                                  id="uploadImg-CIN"
                                  accept="image/*, application/pdf"
                                  name="CertificateOfIncorporationFile"
                                  onChange={(e) => HandleInputChange(e)}
                                  hidden
                                />
                                <label
                                  className="customLabel-kyc_acko2"
                                  htmlFor="uploadImg-CIN"
                                >
                                  <p className="btn-kyc_acko"> <img src={REupload} /> Re-Upload File</p>
                                </label>
                              </div>
                            </div>
                            :
                            <div className="inputGropOne_form inputGrop ">
                              <p className="kyc_label_acko  textbld" >Upload Certificate of Incorporation Photo</p>
                              <div className="inputGropOne_form inputGrop inputGropKyc-custom_acko_kyc " style={styleforborderuploadfile4}>
                                <p>PDF, JPEG, PNG or JPG formats only</p>
                                <input
                                  type="file"
                                  className="hideRealUploadBtn"
                                  id="uploadImg-CIN"
                                  accept="image/*, application/pdf"
                                  name="CertificateOfIncorporationFile"
                                  onChange={(e) => HandleInputChange(e)}
                                  hidden
                                />
                                <label
                                  className="customLabel-kyc_acko"
                                  htmlFor="uploadImg-CIN"
                                >
                                  <p className="btn-kyc_acko"> <img src={NewUploadimg} /> Upload File</p>
                                </label>
                              </div>
                            </div>
                        }
                      </div>
                      <div></div>
                    </>
                  }
                </div>
              </>
            )}
          </>
        )}

        {AutoKYCrejected && companyName === "icici" && carOwner != "individualOwner" && (
          <div className="kyc-dummy" >

            <div className="input_group_pform  input_group_pform-kyc " style={{ marginTop: "-50px" }}>

              <div className="kyc-inp-with-err" >
                <p className="text-bold" style={{ marginBottom: "19px" }}>Enter Certificate Of Incorporation</p>
                <div>
                  <InputFields
                    value={CertificateOfIncorporationNumber}
                    label="XXXX XXXX XXXX"
                    // id="CertificateOfIncorporationNumber "
                    type="text"
                    containerID="xyv"
                    name="CertificateOfIncorporationNumber"
                    className="uppercaseText"
                    onChangeEvent={(e) => HandleInputChange(e)}
                    errState={
                      errors?.CertificateOfIncorporationNumber
                        ? true
                        : false
                    }
                    errMsg={errors?.CertificateOfIncorporationNumber}
                    errClassName="input-err"
                  />
                </div>
                <div></div>
              </div>

              <div className="inputGropOne_form inputGrop " style={{ marginTop: "-1px" }}>
                <p className="text-bold" style={{ marginBottom: "19px" }}>Enter PAN Number</p>
                <div>
                  <InputFields
                    value={kycPanNumber}
                    label="XXXX XXXX XXXX"
                    id="kycPanNumbe"
                    type="text"
                    name="kycPanNumber"
                    containerID="xyv"
                    className="uppercaseText"
                    onChangeEvent={(e) => HandleInputChange(e)}
                    errState={errors?.kycPanNumber ? true : false}
                    errMsg={errors?.kycPanNumber}
                    errClassName="input-err"
                  />
                </div>
                <div></div>
              </div>

            </div>
            <div className="input_group_pform input_group_pform-kyc ">

              <div className="kyc-inp-with-err">
                {
                  formData?.CertificateOfIncorporationFileFileType
                    ?
                    <div className="inputGropOne_form inputGrop " >
                      <p className="kyc_label_acko  textbld" >Upload Certificate Of Incorporation Photo</p>
                      <div className="inputGropOne_form inputGrop inputGropKyc-custom_acko_kyc ">
                        <p style={{ color: "#19B920" }}>Photo Uploaded Successfully</p>
                        <input
                          type="file"
                          className="hideRealUploadBtn"
                          id="uploadImg-CIN"
                          accept="image/*, application/pdf"
                          name="CertificateOfIncorporationFile"
                          onChange={(e) => HandleInputChange(e)}
                          hidden
                        />
                        <label
                          className="customLabel-kyc_acko2"
                          htmlFor="uploadImg-CIN"
                        >
                          <p className="btn-kyc_acko"> <img src={REupload} /> Re-Upload File</p>
                        </label>
                      </div>
                    </div>
                    :
                    <div className="inputGropOne_form inputGrop ">
                      <p className="kyc_label_acko  textbld" >Upload Certificate of Incorporation Photo</p>
                      <div className="inputGropOne_form inputGrop inputGropKyc-custom_acko_kyc " style={styleforborderuploadfile4}>
                        <p>PDF, JPEG, PNG or JPG formats only</p>
                        <input
                          type="file"
                          className="hideRealUploadBtn"
                          id="uploadImg-CIN"
                          accept="image/*, application/pdf"
                          name="CertificateOfIncorporationFile"
                          onChange={(e) => HandleInputChange(e)}
                          hidden
                        />
                        <label
                          className="customLabel-kyc_acko"
                          htmlFor="uploadImg-CIN"
                        >
                          <p className="btn-kyc_acko"> <img src={NewUploadimg} /> Upload File</p>
                        </label>
                      </div>
                    </div>
                }
              </div>

              <div className="inputGropOne_form inputGrop " >
                {
                  formData?.kycPanImgFileType ?
                    <div className="inputGropOne_form inputGrop " >
                      <p className="kyc_label_acko  textbld" >Upload PAN Photo</p>
                      <div className="inputGropOne_form inputGrop inputGropKyc-custom_acko_kyc ">
                        <p style={{ color: "#19B920" }}>Photo Uploaded Successfully</p>
                        <input
                          type="file"
                          className="hideRealUploadBtn"
                          id="uploadImg-PAN"
                          accept="image/*, application/pdf"
                          name="kycPanImg"
                          onChange={(e) => HandleInputChange(e)}
                          hidden
                        />
                        <label
                          className="customLabel-kyc_acko2"
                          htmlFor="uploadImg-PAN"
                        >
                          <p className="btn-kyc_acko"> <img src={REupload} /> Re-Upload File</p>
                        </label>
                      </div>
                    </div>
                    :
                    <div className="inputGropOne_form inputGrop ">
                      <p className="kyc_label_acko  textbld" >Upload PAN Photo</p>
                      <div className="inputGropOne_form inputGrop inputGropKyc-custom_acko_kyc " style={styleforborderuploadfile3}>
                        <p>PDF, JPEG, PNG or JPG formats only</p>
                        <input
                          type="file"
                          className="hideRealUploadBtn"
                          id="uploadImg-PAN"
                          accept="image/*, application/pdf"
                          name="kycPanImg"
                          onChange={(e) => HandleInputChange(e)}
                          hidden
                        />
                        <label
                          className="customLabel-kyc_acko"
                          htmlFor="uploadImg-PAN"
                        >
                          <p className="btn-kyc_acko"> <img src={NewUploadimg} /> Upload File</p>
                        </label>
                      </div>
                    </div>
                }
              </div>
            </div>
          </div>
        )}

        {typeOfKycDocument !== "" && (
          <>
            {AutoKYCrejected && (

              <div className="input_group_pform input_group_pform-kyc " style={AutoKYCrejected && (CompanyName == "icici"|| companyName=='national') ? Mystyles : myStyle}>
                {formData?.typeOfKycDocument === "Aadhar Card" && companyName != "shreeram" && companyName != "tata" && (
                  <>
                    <div className="input_group_pform input_group_pform-flex ">
                      {
                        <>
                          {carOwner == "individualOwner" ?
                            formData?.kycAadharImg ?
                              <div className="inputGropOne_form inputGrop " >
                                <p className="kyc_label_acko  textbld" >Upload Aadhar Front Photo</p>
                                <div className="inputGropOne_form inputGrop inputGropKyc-custom_acko_kyc ">
                                  <p style={{ color: "#19B920" }}>Photo Uploaded Successfully</p>
                                  <input
                                    type="file"
                                    className="hideRealUploadBtn"
                                    id="uploadImg-Aadhar"
                                    accept="image/*, application/pdf"
                                    name="kycAadharImg"
                                    onChange={(e) => HandleInputChange(e)}
                                    hidden
                                  />
                                  <label
                                    className="customLabel-kyc_acko2"
                                    htmlFor="uploadImg-Aadhar"
                                  >
                                    <p className="btn-kyc_acko"> <img src={REupload} /> Re-Upload File</p>
                                  </label>
                                </div>
                              </div>
                              :
                              <div>
                                <div className="inputGropOne_form inputGrop ">
                                  <p className="kyc_label_acko  textbld" >Upload Aadhar Front Photo</p>
                                  <div className="inputGropOne_form inputGrop inputGropKyc-custom_acko_kyc " style={styleforborderuploadfile}>
                                    <p>PDF, JPEG, PNG or JPG formats only</p>
                                    <input
                                      type="file"
                                      className="hideRealUploadBtn"
                                      id="uploadImg-Aadhar"
                                      accept="image/*, application/pdf"
                                      name="kycAadharImg"
                                      onChange={(e) => HandleInputChange(e)}
                                      hidden
                                    />
                                    <label
                                      className="customLabel-kyc_acko"
                                      htmlFor="uploadImg-Aadhar"
                                    >
                                      <p className="btn-kyc_acko"> <img src={NewUploadimg} /> Upload File</p>
                                    </label>
                                  </div>
                                </div>
                              </div> : ""
                          }
                        </>
                      }

                      {
                        <>
                          {carOwner == "individualOwner" ?
                            formData?.kycAadharImgBack ?
                              <div className="inputGropOne_form inputGrop " >
                                <p className="kyc_label_acko  textbld" >Upload Aadhar Back Photo</p>
                                <div className="inputGropOne_form inputGrop inputGropKyc-custom_acko_kyc ">
                                  <p style={{ color: "#19B920" }}>Photo Uploaded Successfully</p>
                                  <input
                                    type="file"
                                    className="hideRealUploadBtn"
                                    id="uploadImg-Aadhar-Back"
                                    accept="image/*, application/pdf"
                                    name="kycAadharImgBack"
                                    onChange={(e) => HandleInputChange(e)}
                                    hidden
                                  />
                                  <label
                                    className="customLabel-kyc_acko2"
                                    htmlFor="uploadImg-Aadhar-Back"
                                  >
                                    <p className="btn-kyc_acko"> <img src={REupload} /> Re-Upload File</p>
                                  </label>
                                </div>
                              </div>
                              :
                              <div>
                                <div className="inputGropOne_form inputGrop " >
                                  <p className="kyc_label_acko  textbld" >Upload Aadhar Back Photo</p>
                                  <div className="inputGropOne_form inputGrop inputGropKyc-custom_acko_kyc " style={styleforborderuploadfile2}>
                                    <p>PDF, JPEG, PNG or JPG formats only</p>
                                    <input
                                      type="file"
                                      className="hideRealUploadBtn"
                                      id="uploadImg-Aadhar-Back"
                                      accept="image/*, application/pdf"
                                      name="kycAadharImgBack"
                                      onChange={(e) => HandleInputChange(e)}
                                      hidden
                                    />
                                    <label
                                      className="customLabel-kyc_acko"
                                      htmlFor="uploadImg-Aadhar-Back"
                                    >
                                      <p className="btn-kyc_acko"> <img src={NewUploadimg} /> Upload File</p>
                                    </label>
                                  </div>
                                </div>
                              </div> : ""
                          }
                        </>
                      }
                    </div>
                  </>
                )}

                {
                  formData?.typeOfKycDocument === "Aadhar Card" && companyName === "tata" && (
                    <div className="input_group_pform input_group_pform-kyc input_group_pform-kyc-tata">
                      <div id="">
                        <div className="inputGropOne_form inputGrop inputGropKyc-custom ">
                          <p> Aadhar Photo</p>
                          <input
                            type="file"
                            className="hideRealUploadBtn"
                            id="uploadImg-Aadhar"
                            accept=".pdf, .jpg, .jpeg, .png"
                            name="kycAadharImg"
                            onChange={(e) => HandleInputChange(e)}
                            hidden
                          />
                          <label
                            className="customLabel-kyc"
                            htmlFor="uploadImg-Aadhar"
                          >F
                            <p className="btn-kyc-">upload</p>
                          </label>
                        </div>

                        <div style={{ display: "flex", alignItems: "center", gap: "0.4rem" }}>
                          <p>{AadharImgName ? AadharImgName : (kycAadharImg != "undefined" && kycAadharImg) ? "Uploaded" : ""}</p>
                          {(AadharImgName || (kycAadharImg != "undefined" && kycAadharImg)) && <img src={UploadedIcon} />
                          }
                        </div>
                        {errors.kycAadharImg ? (
                          <p className="err-msg">{errors.kycAadharImg}</p>
                        ) : (
                          ""
                        )}
                      </div>
                      <div>
                      </div>
                    </div>
                  )
                }

                {(formData?.typeOfKycDocument === "PAN Card" && CompanyName !== "shreeram") &&
                  (!AutoKYCrejected || CompanyName != "sbi" || CompanyName != "icici") ? (
                  <>
                    <div id="">


                      {/* {                       "here disable the pan card  uploder for icic ""                                                
                        formData?.kycPanImgFileType && CompanyName!=="icici"?
                          <div class="uploadedd-container">
                            <img src={uploadimgNew} alt="" />

                            <p>Photo Uploaded Successfully.</p>
                            <p><span>Wrong File? </span><label className="uploadedd-container2"><a class="text-button">Upload Again</a>
                              <input
                                type="file"
                                className="hideRealUploadBtn"
                                id="uploadImg-PAN"
                                accept="image/*, application/pdf"
                                name="kycPanImg"
                                onChange={(e) => HandleInputChange(e)}
                              /></label></p>
                          </div>
                          :
                          <label class="upload-container">
                            <img src={uploadiconnew} alt="" className="img-align" />
                            <input
                              type="file"
                              className="hideRealUploadBtn"
                              id="uploadImg-PAN"
                              accept="image/*, application/pdf"
                              name="kycPanImg"
                              onChange={(e) => HandleInputChange(e)}

                            />
                            <p>Upload PAN Photo 2</p>
                            <span>File cannot be more than 1MB. (JPG or PNG)</span>

                          </label>
                      } */}

                    </div>

                    {/* <div className="inputGropOne_form inputGrop " style={{marginTop:"5px"}}>
                      <InputFields
                        value={kycPanNumber}
                        placeholder="PAN Number"
                        id="kycPanNumbe"
                        type="text"
                        name="kycPanNumber"
                        className="uppercaseText"
                        onChangeEvent={(e) => HandleInputChange(e)}
                        errState={errors?.kycPanNumber ? true : false}
                        errMsg={errors?.kycPanNumber}
                        errClassName="input-err"
                      />
                    </div> */}
                  </>
                ) : (formData?.typeOfKycDocument === "Certificate of Incorporation" && CompanyName != "icici") ? (
                  <>
                    <div id="">
                      <div className="inputGropOne_form inputGrop inputGropKyc-custom ">
                        <p>Certificate of Incorporation</p>
                        <input
                          type="file"
                          className="hideRealUploadBtn"
                          id="uploadImg-CIN"
                          accept=".pdf, .jpg, .jpeg, .png"
                          name="CertificateOfIncorporationFile"
                          onChange={(e) => HandleInputChange(e)}
                          hidden
                        />
                        <label
                          className="customLabel-kyc"
                          htmlFor="uploadImg-CIN"
                        >
                          <p className="btn-kyc-">upload</p>
                        </label>
                      </div>
                      <div style={{ display: "flex", alignItems: "center", gap: "0.4rem" }}>
                        <p>{CINImgName}</p>
                        {CINImgName && <img src={UploadedIcon} />
                        }
                      </div>
                      {errors.CertificateOfIncorporationFile ? (
                        <p className="err-msg">
                          {errors.CertificateOfIncorporationFile}
                        </p>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="inputGropOne_form inputGrop ">
                      <InputFields
                        value={CertificateOfIncorporationNumber}
                        // placeholder="Certificate of Incorporation Number"
                        label="XXXX XXXX XXXX"
                        id="CertificateOfIncorporationNumber"
                        type="text"
                        name="CertificateOfIncorporationNumber"
                        className="uppercaseText"
                        onChangeEvent={(e) => HandleInputChange(e)}
                        errState={
                          errors?.CertificateOfIncorporationNumber
                            ? true
                            : false
                        }
                        errMsg={errors?.CertificateOfIncorporationNumber}
                        errClassName="input-err"
                      />
                    </div>
                  </>
                ) : (
                  ""
                )}
              </div>
            )}
          </>
        )}

        <section className="btnContainer_pform" style={styles}>
          <Botton
            text="Back"
            className="button btn_pForm  secondry_btn"
            event={() => (shreeRamKyc || shreeRamKycCorp) && companyName == "shreeram" ? handleBackKycBtn() : handleBackBtn("kycDetails")}
          />
          {transactionStatus?.isLoading ?
            <ButtonLoader className="btn_pForm" />
            : <Botton
              text={(!shreeRamKyc || !shreeRamKycCorp) && companyName == "shreeram" ? "Next" : isDisabledButton ? "Loading..." : "Update KYC"}
              className="button btn_pForm"
              isDisabledButton={isDisabledButton}
              event={(!shreeRamKyc || !shreeRamKycCorp) && companyName == "shreeram" ? handlekycdata : handleKycToggle}
            />}
        </section>
      </form >
    </>
  );
};

export default KycForm;
