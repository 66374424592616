import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import caution from "../../Assets/Images/cautionImg.svg"
import Botton from "../Button/Button";
import { closestMatch } from "closest-match";
import "./ProposalForm.css";
import ProposalOne from "./ProposalOne";
import ProposalTwo from "./ProposalTwo";
import PropsalThree from "./PropsalThree";
import PropsalFour from "./PropsalFour";
import PremiumContainerBike from "./PremiumContainerBike";
import KycForm from "./KycForm";
import Stepper from "../Stepper/Stepper";
import Collapsible from "../Collapsible/Collapsible";
import { paymentSendURLAction, paymentWebhookAction } from "../../redux/Actions/paymentWebhookAction";
import { getCompanyName } from "../../redux/Reducers/companyNameReducer";
import { alladdOnDataDataAction } from "../../redux/Reducers/addOnsDataReducer";
import { transactionURLAction } from "../../redux/Actions/TransactionAction";
import {
  sbiKyc,
  sbiKycAuto,
  sbiFullQuoteAction,
  sbiPolicyGenerateAction,
} from "../../redux/Actions/sbiApiAction";
import {
  iciciKyc,
  iciciKycAuto,
  iciciQuickQuoteAction,
} from "../../redux/Actions/iciciApiAction";
import {
  addManualKycDataAction,
  addAutoKycDataAction
} from "../../redux/Reducers/iciciApiReducer";
import {
  // hdfcKyc,
  hdfcKycAuto,
  hdfcQuickQuoteAction,
} from "../../redux/Actions/hdfcApiAction";
import {
  hdfcKycAutoReducer,
  addhdfcAutoKycDataAction
} from "../../redux/Reducers/hdfcApiReducer";
import { allBodyDataAction } from "../../redux/Reducers/bodyDataReducer";
import {
  bikeDetailsforProposal,
  sbiAutoKycDataMethod,
  rtoState,
  ICICI,
  HDFC,
  ACKO,
  SHREERAM,
  TATA
} from "../../utils/commonUtils";
import {
  getStateAction,
  getCityAction,
  getInsurerList,
} from "../../redux/Actions/stateDetails";
import Inspection from "./Inspection";
import { sbiQuickQuoteAction } from "../../redux/Actions/sbiApiAction";
import { shreeRamQuickQuoteAction } from "../../redux/Actions/shreeRamApiAction";
import { digitQuickQuoteAction } from "../../redux/Actions/digitApiAction";
import {
  zunoQuickQuoteAction,
  zunoKycCustomerAction,
  zunoManualKycAction,
  zunoInsertBodydAction
} from "../../redux/Actions/zunoApiAction";
import {
  resetManualKycQuoteAction,
} from "../../redux/Reducers/zunoApiReducer";
import {
  tataAutoKycAction,
  tataManualKycAction,
  tataProposalAction,
  tataQuickQuoteAction,
  tataOtpVerifyAction,
  tataOnlinePaymentAction
} from "../../redux/Actions/tataApiAction";
import {
  resettataManualKycAction,
  tataAutoKycReducer,
  tataProposalReducer,
} from "../../redux/Reducers/tataApiReducer";
import loader2 from "../../Assets/Loader/Loader.gif";
import Navbar from "../Navbar/Navbar";
import backIcon from "../../Assets/Images/backIcon.svg";
import { ackoQuickQuoteAction, ackocheckPaymentStatusAction } from "../../redux/Actions/ackoApiAction";
import Modal from "react-modal";
import OtpModal from "../Modal/OtpModal";
import { phonevalidationAction } from "../../redux/Actions/shareLinkAction.js";


const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

Modal.setAppElement("#root");

const ProposalForm = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let isZeroDepChecked = '';

  const InspectionStatus = useSelector((state) => {
    return state.inspectDetails?.data?.isInspectionReq;
  });

  const {
    bottomFooter,
    collapsibleTitle,
    headerCollapsible,
    insurerLogo
  } = props;

  const search = useLocation().search;
  const id = new URLSearchParams(search).get("id");
  const transactionId = new URLSearchParams(search).get("transaction_id");
  const zunoKycId = new URLSearchParams(search).get("kId");
  const [StepperValue, setStepperValue] = useState(1);
  const [ShowKycInputs, setShowKycInputs] = useState(false);
  const [ShowForm, setShowForm] = useState("ownerDetail"); //FormDetails kycDetails ownerDetail PersonalDetails AddressDetails VehicleDetails
  const [ShowCityDropdown, setShowCityDropdown] = useState(false);
  // make it true when auto kyc rejected
  const [AadharImgName, setAadharImgName] = useState("");
  const [AadharImgBackName, setAadharImgBackName] = useState("");
  const [PanImgName, setPanImgName] = useState("");
  const [CINImgName, setCINImgName] = useState("");
  const [ProfileImgName, setProfileImgName] = useState("");
  const [isAutoKyc, setIsAutoKyc] = useState(true); // todo - true
  const [isKycSuccess, setIsKycSuccess] = useState(false);
  const [Edit, setEdit] = useState(false);
  const [isDisabledButton, setIsDisabledButton] = useState(false);
  const [errors, setErrors] = useState({});
  const [Image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [triggerApi, setTriggerApi] = useState(true);
  const [DobClone, setDobClone] = useState(null);
  const [ShowDobCalendar, setShowDobCalendar] = useState(false)
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [StoreTataOtp, setStoreTataOtp] = useState(null);
  const [ShowNilDepState, setShowNilDepState] = useState(null);
  const [ZeroDepValue, setZeroDepValue] = useState(null);
  const [checkphone, setcheckphone] = useState(false)
  const [phonestatussloading, SetPhonestatussloading] = useState(false)
  const [carNoPopup, setCarNopopup] = useState(false)
  const [ShowPaymentLink, setShowPaymentLink] = useState(false);

  function openModal() {
    setIsOpen(true);
  }
  const customStylesForMobile = {
    content: {
      top: "auto",
      left: "0",
      right: "0",
      bottom: "0",
      margin: "0",
      transform: "none",
      borderRadius: "10px 10px 0 0",
      padding: "20px",
      height: "auto",
    },
  };
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  function closeModal(otp) {
    if (otp.length == 6) {
      setIsOpen(false);
    }

  }
  const phonevalidationFlag = useSelector((state) => {
    return state.isPhoneNumberValid
  })
  useEffect(() => {
    setcheckphone(phonevalidationFlag?.data?.data?.multipleNumer)
  }, [])
  useEffect(() => {
    if (bodyData?.data?.PolicyTypeValue !== "Bundled" && bodyData?.data?.typeOfBusiness !== "New" && bodyData?.data?.vehicleNumber?.length < 9) {
      setCarNopopup(true)
      setTimeout(() => {
        navigate("/View-Plans?id=" + id);
      }, 4000)
    }
  }, [bodyData?.data?.vehicleNumber])
  const location = useLocation();

  let allCarDetails = location?.state?.allCarDetails;
  let CompanyImage = location?.state?.logo;

  let bodyData = useSelector((state) => {
    return state.bodyData;
  });
  const CompanyName = useSelector((state) => {
    return state.companyName?.data?.companyName;
  });

  const [AutoKYCrejected, setAutoKYCrejected] = useState(
    CompanyName == "sbi" || CompanyName == "icici" || CompanyName == "digit" || CompanyName == "tata"
      || CompanyName == "acko" || CompanyName == "hdfc"
      ? false
      : true
  );
  let test = []

  let kycAutoData = useSelector((state) => {
    return state.abiKycAutoData;
  });

  let iciciKycAutoData = useSelector((state) => {
    return state.iciciKycAutoData;
  });

  let hdfcKycAutoData = useSelector((state) => {
    return state.hdfcKycAutoData;
  });

  const [hdfcKycData, sethdfcKycData] = useState("");

  let kycManualData = useSelector((state) => {
    return state.sbiKycData;
  });

  let iciciKycManualData = useSelector((state) => {
    return state.iciciKycData;
  });

  let sbiFullQuoteData = useSelector((store) => {
    return store?.sbiFullQuote;
  });

  let iciciFullQuoteData = useSelector((store) => {
    return store?.iciciProposal;
  });

  let hdfcFullQuoteData = useSelector((store) => {
    return store?.hdfcProposal;
  });

  const getPreviousAddonData = useSelector((state) => {
    return state?.addOnData?.data;
  });

  const sbiQuickQuoteData = useSelector((state) => {
    return state?.sbiQuickQuote;
  });

  const iciciQuickQuoteData = useSelector((state) => {
    return state?.iciciQuickQuote;
  });

  const hdfcQuickQuoteData = useSelector((state) => {
    return state?.hdfcQuickQuote;
  });

  // Zuno auto & manual
  let zunoKycAutoData = useSelector((state) => {
    return state.zunoKyc;
  });

  let zunoKycManualData = useSelector((state) => {
    return state.zunoManualKyc;
  });

  // tata api
  const tataQuickQuote = useSelector((state) => {
    return state?.tataQuickQuote;
  });

  const tataProposalRes = useSelector((state) => {
    return state?.tataProposalQuote;
  });

  const tataAutoKyc = useSelector((state) => {
    return state?.tataAutoKyc;
  });

  const tatamanualKyc = useSelector((state) => {
    return state?.tataManualKyc;
  });



  useEffect(() => {
    let divElement = document.getElementById("PropsalFormContainer_child-formFields");

    if (window.innerWidth < 610) {
      if (divElement) {
        divElement.scrollIntoView({ behavior: 'smooth' });
      }
    } else {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
    return () => { };
  }, [ShowForm, ShowKycInputs]);

  const stateListResponse = useSelector((state) => {
    return state?.getStateDetails?.data?.data?.rtocity;
  });

  const cityListResponse = useSelector((state) => {
    return state?.getCityDetails?.data?.data?.rtocity;
  });

  let stateList = stateListResponse?.map((data, index) => ({
    label: data?.state_name,
    value: data?.state_name,
  }));

  let cityList = cityListResponse?.map((data, index) => ({
    label: data?.city_name,
    value: data?.city_name,
  }));

  let result = cityList?.map((city) => city?.value);
  let sbiAutoKycData = sbiAutoKycDataMethod(kycAutoData, result);

  let rtoStateName = rtoState.filter((data) => {
    return (
      data?.stateId?.toUpperCase() == sbiAutoKycData?.stateCode?.toUpperCase()
    );
  });
  let [pincode, setpincode] = useState(localStorage.getItem("pincode"));

  let propsalData = {
    carOwner: "individualOwner",
    // ownerName: "",
    firstName: sbiAutoKycData?.firstName || "",
    fathersName: null,
    lastName: sbiAutoKycData?.lastName || "",
    appointeeName: "",
    appointeeRelation: "",
    paExclusionReason: "",
    companyName: sbiAutoKycData?.companyName || "",
    email: sbiAutoKycData?.email || "",
    // phoneNumber: sbiAutoKycData?.phoneNumber || "",
    isNilDepInPrevPolicy: "",
    phoneNumber: (bodyData?.data?.mobileNo ? Number(bodyData?.data?.mobileNo) : (sbiAutoKycData?.phoneNumber ? sbiAutoKycData?.phoneNumber : "")),
    alternatePhoneNumber: "",
    gstNumber: "",
    gender: "",
    maritalStatus: "",
    // dob: sbiAutoKycData?.dob.setDate(sbiAutoKycData?.dob.getDate() + 1) || "",
    dob: sbiAutoKycData?.dob || "",
    nomineeName: "",
    nomineeAge: "",
    nomineeRelation: "",
    addressOne: sbiAutoKycData?.addressOne || "",
    addressTwo: sbiAutoKycData?.addressTwo || "",
    state: rtoStateName[0]?.state_name || "",
    city: sbiAutoKycData?.closestMatchCity || "",
    pincode: (pincode ? pincode : (sbiAutoKycData?.pincode ? sbiAutoKycData?.pincode : "")),
    prevPolicyNumber: "",
    prevOdPolicyNumber: "",
    prevPolicyNameInsurer: "",
    prevOdPolicyNameInsurer: "",
    chassisNumber: "",
    engineNumber: "",
    carIsInLoan: "",
    isSelfInspection: null,
    kycAadharNumber: "",
    CertificateOfIncorporationNumber: "",
    CertificateOfIncorporationFileFileType: "",
    CertificateOfIncorporationFile: "",
    kycPanNumber: sbiAutoKycData?.kycPanNumber || "",
    kycProfileImg: "",
    kycProfileImgFileType: "",
    kycAadharImg: "",
    kycAadharImgBack: "",
    kycAadharImgFileType: "",
    kycAadharImgBackFileType: "",
    kycPanImgFileType: "",
    kycPanImg: "",
    carHypothecationCompany: "",
    typeOfKycDocument: "",
    isCarPUC: "Yes",
    kycTitle: sbiAutoKycData?.kycTitle,
    fatherTitle: sbiAutoKycData?.fatherTitle,
    fatherFirstName: sbiAutoKycData?.fatherFirstName,
    fatherLastName: sbiAutoKycData?.fatherLastName,
    ckycResponseNumber: sbiAutoKycData?.ckycResponseNumber || "",
    ckycUniqueId: sbiAutoKycData?.ckycUniqueId || "",
    vehicleType: "2W"
  };

  const [formData, setFormData] = useState(propsalData);

  useEffect(() => {

    const asyncFn = async () => {
      if (bodyData?.data?.vehicleNumber?.length < 9) {
        setLoading(false);
      } else {
        setLoading(true);
      }
      if (transactionId) {
        //call api to get data 
        let bodyData = {
          transactionId: transactionId,
          status: "getpaymentdata",
        };
        let payResp = await dispatch(paymentSendURLAction(bodyData));
        if (payResp?.payload?.data?.paymentinfo[0].status == "success" &&
          payResp?.payload?.data?.paymentinfo[0].policy_number) {
          const { company_name, policy_transaction_id, policy_number } = payResp?.payload?.data?.paymentinfo[0];
          if (company_name == ACKO) {
            navigate(`/Payment-Redirect?companyName=${company_name}&policy_number=${policy_number}&proposal_id=${policy_transaction_id}`)
          } else {
            navigate(
              `/Payment-Redirect?companyName=${company_name}&transaction_id=${policy_transaction_id}&PolicyNumber=${policy_number}`
            );
          }
        } else {
          let bodyDataRes = JSON.parse(payResp?.payload?.data?.paymentinfo[0].body_data)
          mappingAllData[0] = bodyDataRes.data;
          mappingAllData[0].vehicleType = "2W";
          await dispatch(alladdOnDataDataAction({
            ...bodyDataRes.data,
            ...{ vehicleType: "2W" }
          }
          ));
          await dispatch(
            allBodyDataAction({
              ...bodyDataRes.data,
              ...{ vehicleType: "2W" }
            })
          );
          setFormData({ ...bodyDataRes.data, ...{ vehicleType: "2W" }, ...formData })
          await dispatch(getCompanyName({
            companyName: payResp?.payload?.data?.paymentinfo[0].company_name
          }))
          if (payResp?.payload?.data?.paymentinfo[0].company_name == "sbi") {
            if (bodyDataRes.data?.carOwner?.toUpperCase() === "isCompany".toUpperCase()) {
              setCoverages([bodyDataRes.data?.coverage[0]]);
            } else {
              setCoverages(bodyDataRes.data?.coverage[0]);
            }
            await dispatch(
              sbiQuickQuoteAction({
                data: {
                  ...bodyDataRes.data
                },
              })
            );
          } else if (payResp?.payload?.data?.paymentinfo[0].company_name == "icici") {
            if (bodyDataRes.data?.carOwner?.toUpperCase() === "isCompany".toUpperCase()) {
              setCoverages([bodyDataRes.data?.coverage[0]]);
            } else {
              setCoverages(bodyDataRes.data?.coverage[0]);
            }
            await dispatch(
              iciciQuickQuoteAction({
                data: {
                  ...bodyDataRes.data
                },
              })
            );
            if (payResp?.payload?.data?.paymentinfo[0].kyc_data) {
              let kycData = JSON.parse(payResp?.payload?.data?.paymentinfo[0].kyc_data);
              await dispatch(
                addAutoKycDataAction({
                  ...kycData.iciciKycAutoData.data
                })
              );
              await dispatch(
                addManualKycDataAction({
                  ...kycData.iciciKycManualData.data
                })
              );
            }
          } else if (payResp?.payload?.data?.paymentinfo[0].company_name == "digit") {
            if (bodyDataRes.data?.carOwner?.toUpperCase() === "isCompany".toUpperCase()) {
              setCoverages([bodyDataRes.data?.coverage[0]]);
            } else {
              setCoverages(bodyDataRes.data?.coverage[0]);
            }
            await dispatch(
              digitQuickQuoteAction({
                data: {
                  ...bodyDataRes.data
                },
              })
            );
          } else if (payResp?.payload?.data?.paymentinfo[0].company_name == "acko") {
            if (bodyDataRes.data?.carOwner?.toUpperCase() === "isCompany".toUpperCase()) {
              setCoverages([bodyDataRes.data?.coverage[0]]);
            } else {
              setCoverages(bodyDataRes.data?.coverage[0]);
            }
            await dispatch(
              ackoQuickQuoteAction({
                data: {
                  ...bodyDataRes.data,
                  ...{ vehicleType: "2W" },
                  ...{ coverage: [bodyDataRes.data?.coverage[0]] },
                },
              })
            );
          } else if (payResp?.payload?.data?.paymentinfo[0].company_name == "hdfc") {
            if (bodyDataRes.data?.carOwner?.toUpperCase() === "isCompany".toUpperCase()) {
              setCoverages([bodyDataRes.data?.coverage[0]]);
            } else {
              setCoverages([bodyDataRes.data?.coverage[0]]);
            }
            await dispatch(
              hdfcQuickQuoteAction({
                data: {
                  ...bodyDataRes.data
                },
              })
            );
            if (payResp?.payload?.data?.paymentinfo[0].kyc_data) {
              let kycData = JSON.parse(payResp?.payload?.data?.paymentinfo[0].kyc_data);
              await dispatch(
                addAutoKycDataAction({
                  ...kycData.hdfcKycAutoData.data
                })
              );
            }
          }


          setShowForm("FormDetails");
          setLoading(false);
        }
        return () => { }
      } else if (id) {
        let data = {
          uuid: id,
          type: "fetch"
        };
        let resp = await dispatch(transactionURLAction(data));
        if (Array.isArray(resp?.payload?.data?.txninfo)) {
          if (resp?.payload?.data?.txninfo[0].body_data
            && resp?.payload?.data?.txninfo[0].company_name
          ) {
            if (resp?.payload?.data?.txninfo[0].status == "success" &&
              resp?.payload?.data?.txninfo[0].policy_number) {
              const { company_name, policy_transaction_id, policy_number } = resp?.payload?.data?.txninfo[0];
              if (company_name == ACKO) {
                navigate(`/Payment-Redirect?companyName=${company_name}&policy_number=${policy_number}&proposal_id=${policy_transaction_id}`)
              } else {
                navigate(
                  `/Payment-Redirect?companyName=${company_name}&transaction_id=${policy_transaction_id}&PolicyNumber=${policy_number}`
                );
              }
            } else {
              let bodyDataRes = JSON.parse(resp?.payload?.data?.txninfo[0].body_data)
              let addOnData = JSON.parse(resp?.payload?.data?.txninfo[0].addon_data)
              let formDataRes = JSON.parse(resp?.payload?.data?.txninfo[0].form_data)
              let carDets = JSON.parse(resp?.payload?.data?.txninfo[0].car_details_obj)
              setmobileNo(carDets?.mobileNo)
              setpincode(carDets?.pincode)
              await dispatch(allBodyDataAction({
                ...bodyDataRes,
                ...{ vehicleType: "2W" },
                ...{ coverage: [addOnData] },
                ...{
                  CertificateOfIncorporationFile: resp?.payload?.data?.txninfo[0].cin_file,
                  kycProfileImg: resp?.payload?.data?.txninfo[0].profile_img_file,
                  kycAadharImg: resp?.payload?.data?.txninfo[0].aadhar_file,
                  kycAadharImgBack: resp?.payload?.data?.txninfo[0].aadhar_back_file,
                  kycPanImg: resp?.payload?.data?.txninfo[0].pan_file,
                  mobileNo: carDets.mobileNo,
                }
              }));

              await dispatch(alladdOnDataDataAction({
                ...bodyDataRes,
                ...{ vehicleType: "2W" },
                ...{ coverage: [bodyDataRes?.coverage[0]] },
                ...{
                  CertificateOfIncorporationFile: resp?.payload?.data?.txninfo[0].cin_file,
                  kycProfileImg: resp?.payload?.data?.txninfo[0].profile_img_file,
                  kycAadharImg: resp?.payload?.data?.txninfo[0].aadhar_file,
                  kycAadharImgBack: resp?.payload?.data?.txninfo[0].aadhar_back_file,
                  kycPanImg: resp?.payload?.data?.txninfo[0].pan_file,
                  mobileNo: carDets.mobileNo
                }
              }));
              mappingAllData[0] = {
                ...bodyDataRes,
                ...{ vehicleType: "2W" },
                ... { mobileNo: carDets.mobileNo },
                ...{ coverage: [bodyDataRes?.coverage[0]] }
              };
              setFormData({
                ...formData,
                ...formDataRes,
                ...{ vehicleType: "2W" },
                ... { mobileNo: carDets.mobileNo },
                ...{ coverage: [bodyDataRes?.coverage[0]] },

              })

              await dispatch(getCompanyName({
                companyName: resp?.payload?.data?.txninfo[0].company_name
              }))
              let compname = resp?.payload?.data?.txninfo[0].company_name;

              if (compname == "sbi") {
                if (bodyDataRes.carOwner?.toUpperCase() === "isCompany".toUpperCase()) {
                  setCoverages([bodyDataRes.coverage[0]]);
                } else {
                  setCoverages(bodyDataRes.coverage[0]);
                }
                await dispatch(
                  sbiQuickQuoteAction({
                    data: {
                      ...bodyDataRes,
                      ...{ vehicleType: "2W" },
                      ...{ coverage: [bodyDataRes?.coverage[0]] },
                    },
                  })
                );
              } else if (compname == "icici") {
                if (bodyDataRes.carOwner?.toUpperCase() === "isCompany".toUpperCase()) {
                  setCoverages([bodyDataRes.coverage[0]]);
                } else {
                  setCoverages(bodyDataRes.coverage[0]);
                }
                await dispatch(
                  iciciQuickQuoteAction({
                    data: {
                      ...bodyDataRes,
                      ...{ coverage: [bodyDataRes?.coverage[0]] },
                    },
                  })
                );
                if (resp?.payload?.data?.txninfo[0].kyc_auto_data) {
                  let kycData = JSON.parse(resp?.payload?.data?.txninfo[0].kyc_auto_data);
                  await dispatch(
                    addAutoKycDataAction({
                      ...kycData.data
                    })
                  )
                }
                if (resp?.payload?.data?.txninfo[0].kyc_manual_data) {
                  let kycData = JSON.parse(resp?.payload?.data?.txninfo[0].kyc_manual_data);
                  await dispatch(
                    addManualKycDataAction({
                      ...kycData.data
                    })
                  );
                }
              } else if (compname == "digit") {
                if (bodyDataRes?.carOwner?.toUpperCase() === "isCompany".toUpperCase()) {
                  setCoverages([bodyDataRes?.coverage[0]]);
                } else {
                  setCoverages(bodyDataRes?.coverage[0]);
                }
                await dispatch(
                  digitQuickQuoteAction({
                    data: {
                      ...bodyDataRes,
                      ...{ coverage: [bodyDataRes?.coverage[0]] },
                    },
                  })
                );
              } else if (compname == "acko") {
                //check payment status
                let paymentStatus = await dispatch(ackocheckPaymentStatusAction({
                  data: {
                    transaction_id: id
                  },
                })
                );
                if (paymentStatus?.payload?.data?.redicrectLink) {
                  window.location.replace(paymentStatus?.payload?.data?.redicrectLink);
                  return;
                }

                if (bodyDataRes.data?.carOwner?.toUpperCase() === "isCompany".toUpperCase()) {
                  setCoverages([bodyDataRes?.coverage[0]]);
                } else {
                  setCoverages([bodyDataRes?.coverage[0]]);
                }
                await dispatch(
                  ackoQuickQuoteAction({
                    data: {
                      ...bodyDataRes,
                      ...{ vehicleType: "2W" },
                      ... { mobileNo: carDets.mobileNo },
                      ...{ coverage: [bodyDataRes?.coverage[0]] },
                    },
                  })
                );
              } else if (compname == "shreeram") {
                if (bodyDataRes?.carOwner?.toUpperCase() === "isCompany".toUpperCase()) {
                  setCoverages([bodyDataRes?.coverage[0]]);
                } else {
                  setCoverages(bodyDataRes?.coverage[0]);
                }
                await dispatch(
                  shreeRamQuickQuoteAction({
                    data: {
                      ...bodyDataRes,
                      ...{ coverage: [bodyDataRes?.coverage[0]] },
                      ...{
                        CertificateOfIncorporationFile: resp?.payload?.data?.txninfo[0].cin_file,
                        kycProfileImg: resp?.payload?.data?.txninfo[0].profile_img_file,
                        kycAadharImg: resp?.payload?.data?.txninfo[0].aadhar_file,
                        kycAadharImgBack: resp?.payload?.data?.txninfo[0].aadhar_back_file,
                        kycPanImg: resp?.payload?.data?.txninfo[0].pan_file,
                      }
                    },
                  })
                );
              } else if (compname == "tata") {

                if (bodyDataRes.data?.carOwner?.toUpperCase() === "isCompany".toUpperCase()) {
                  setCoverages([bodyDataRes?.coverage[0]]);
                } else {
                  setCoverages([bodyDataRes?.coverage[0]]);
                }
                await dispatch(
                  tataQuickQuoteAction({
                    data: {
                      ...bodyDataRes,
                      ...{ vehicleType: "2W" },
                      ... { mobileNo: carDets.mobileNo },
                      ...{ coverage: [bodyDataRes?.coverage[0]] },
                    },
                  })
                );
              } else if (compname == "hdfc") {

                if (bodyDataRes?.carOwner?.toUpperCase() === "isCompany".toUpperCase()) {
                  setCoverages([bodyDataRes?.coverage[0]]);
                } else {
                  setCoverages([bodyDataRes?.coverage[0]]);
                }
                await dispatch(
                  hdfcQuickQuoteAction({
                    data: {
                      ...bodyDataRes,
                      ...{ coverage: [bodyDataRes?.coverage[0]] },
                    },
                  })
                );
                if (resp?.payload?.data?.txninfo[0].kyc_manual_data) {
                  await sethdfcKycData(JSON.parse(resp?.payload?.data?.txninfo[0].kyc_manual_data));
                }
                if (resp?.payload?.data?.txninfo[0].stepper_value) {
                  let kycDataRes = "asbc"
                  setShowForm("FormDetails");
                  setStepperValue(resp?.payload?.data?.txninfo[0].stepper_value + 1)
                }

              }
              let previousCompany = localStorage.getItem('previousCompany')
              setShowForm((previousCompany !== null && previousCompany !== resp?.payload?.data?.txninfo[0].company_name) ? "ownerDetail" : resp?.payload?.data?.txninfo[0].form_value ? resp?.payload?.data?.txninfo[0].form_value : "ownerDetail")
              setStepperValue((previousCompany !== null && previousCompany !== resp?.payload?.data?.txninfo[0].company_name) ? 1 : resp?.payload?.data?.txninfo[0].stepper_value ? resp?.payload?.data?.txninfo[0].stepper_value : 1)
              // handleFormReveal( (previousCompany!==null) && previousCompany !== resp?.payload?.data?.txninfo[0].company_name ? "ownerDetail":resp?.payload?.data?.txninfo[0].form_value ? resp?.payload?.data?.txninfo[0].form_value : "ownerDetail")
            }
          } else {
            // update
            let data = {
              uuid: id,
              bodyData: bodyData.data,
              companyName: CompanyName,
              formData,
              formValue: ShowForm,
              stepperValue: StepperValue,
              type: "update_proposal"
            };
            await dispatch(transactionURLAction(data));
            let cover = JSON.parse(JSON.stringify(bodyData?.data?.coverage))
            let isFalsePaCoverObject = cover[0].find(
              (item) => item.name === "paCover"
            );
            if (
              isFalsePaCoverObject &&
              formData?.carOwner?.toUpperCase() === "isCompany".toUpperCase()
            ) {
              isFalsePaCoverObject.checked = false;
            }
            setCoverages(cover);
          }
        }
        setLoading(false);
      } else {
        let cover = JSON.parse(JSON.stringify(bodyData?.data?.coverage))
        let isFalsePaCoverObject = cover[0].find(
          (item) => item.name === "paCover"
        );
        if (
          isFalsePaCoverObject &&
          formData?.carOwner?.toUpperCase() === "isCompany".toUpperCase()
        ) {
          isFalsePaCoverObject.checked = false;
        }
        setCoverages(cover);
        setLoading(false);
        return () => { }
      }
    };
    asyncFn();
  }, [])

  // handle here carowner
  const validatePhoneisExist = () => {
    const newErrors = {};
    if (checkphone) {
      newErrors.phoneNumber = "Mobile Numbera already exist(Duplicate)";
    }
    return Object?.keys(newErrors)?.length === 0;

  }

  let [coverages, setCoverages] = useState();


  useEffect(() => {
    async function callQuickQuoteAPi() {


      //New comments
      if (formData?.carOwner?.toUpperCase() === "isCompany".toUpperCase()) {
        //bodyData.data["carOwner"] = formData.carOwner;

        let newCoverage = coverages && coverages[0]?.map((item) => {
          if (item.name == 'paCover') {
            return ({ ...item, checked: false })
          } else {
            return item
          }
        })
        console.log(newCoverage, "mappingAllData[0]?.PolicyTypeValue");
        setTriggerApi(false);
        setLoading(true);
        if (CompanyName == "sbi") {
          let response = await dispatch(
            sbiQuickQuoteAction({
              data: {
                ...bodyData.data,
                ...{ coverage: mappingAllData[0]?.PolicyTypeValue !== "Own Damage" ? newCoverage : coverages },
              },
            })
          );
          if (response?.payload?.body?.data?.quote) {
            setLoading(false);
          }
        } else if (CompanyName == "shreeram") {
          let response = await dispatch(
            shreeRamQuickQuoteAction({
              data: {
                ...bodyData.data,
                ...{ coverage: mappingAllData[0]?.PolicyTypeValue !== "Own Damage" ? newCoverage : coverages },
              },
            })
          );

          setLoading(false);
        } else if (CompanyName == "digit") {
          let response = await dispatch(
            digitQuickQuoteAction({
              data: {
                ...bodyData.data,
                ...{ coverage: mappingAllData[0]?.PolicyTypeValue !== "Own Damage" ? newCoverage : coverages },
              },
            })
          );
          setLoading(false);
        } else if (CompanyName == "zuno") {
          let response = await dispatch(
            zunoQuickQuoteAction({
              data: {
                ...bodyData.data,
                ...{ coverage: mappingAllData[0]?.PolicyTypeValue !== "Own Damage" ? newCoverage : coverages },
              },
            })
          );
          setLoading(false);
        } else if (CompanyName == "icici") {
          await dispatch(
            iciciQuickQuoteAction({
              data: {
                ...bodyData.data,
                ...{ coverage: mappingAllData[0]?.PolicyTypeValue !== "Own Damage" ? newCoverage : coverages },
              },
            })
          );
          setLoading(false);
        } else if (CompanyName == "hdfc") {
          await dispatch(
            hdfcQuickQuoteAction({
              data: {
                ...bodyData.data,
                ...{ coverage: mappingAllData[0]?.PolicyTypeValue !== "Own Damage" ? [newCoverage] : coverages },
              },
            })
          );
          setLoading(false);
        } else if (CompanyName == "acko") {
          await dispatch(
            ackoQuickQuoteAction({
              data: {
                ...bodyData.data,
                ...{ vehicleType: "2W" },
                ...{ coverage: mappingAllData[0]?.PolicyTypeValue !== "Own Damage" ? [newCoverage] : coverages },
              },
            })
          );
          setLoading(false);
        } else if (CompanyName == "tata") {
          let response = await dispatch(
            tataQuickQuoteAction({
              data: {
                ...bodyData.data,
                ...{ coverage: mappingAllData[0]?.PolicyTypeValue !== "Own Damage" ? [newCoverage] : coverages },
              },
            })
          );
          setLoading(false);
        }
      } else if (formData?.carOwner?.toUpperCase() === "individualOwner".toUpperCase()) {
        setLoading(true);
        if (CompanyName == "sbi") {
          let response = await dispatch(
            sbiQuickQuoteAction({
              data: {
                ...bodyData.data,
                ...{ coverage: [getPreviousAddonData?.coverage[0]] },
              },
            })
          );
          if (response?.payload?.body?.data?.quote) {
            setLoading(false);
            setTriggerApi(true);
          }
        } else if (CompanyName == "shreeram") {
          let response = await dispatch(
            shreeRamQuickQuoteAction({
              data: {
                ...bodyData.data,
                ...{ coverage: [getPreviousAddonData?.coverage[0]] },
              },
            })
          );
          setLoading(false);
          setTriggerApi(true);
        } else if (CompanyName == "digit") {
          let response = await dispatch(
            digitQuickQuoteAction({
              data: {
                ...bodyData.data,
                ...{ coverage: [getPreviousAddonData?.coverage[0]] },
              },
            })
          );
          setLoading(false);
          setTriggerApi(true);
        } else if (CompanyName == "zuno") {
          let response = await dispatch(
            zunoQuickQuoteAction({
              data: {
                ...bodyData.data,
                ...{ coverage: [getPreviousAddonData?.coverage[0]] },
              },
            })
          );
          setLoading(false);
          setTriggerApi(true);
        } else if (CompanyName == "icici") {
          await dispatch(
            iciciQuickQuoteAction({
              data: {
                ...bodyData.data,
                ...{ coverage: [getPreviousAddonData?.coverage[0]] },
              },
            })
          );
          setLoading(false);
          setTriggerApi(true);
        } else if (CompanyName == "hdfc") {
          await dispatch(
            hdfcQuickQuoteAction({
              data: {
                ...bodyData.data,
                ...{ coverage: [getPreviousAddonData?.coverage[0]] },
              },
            })
          );
          setLoading(false);
          setTriggerApi(true);
        } else if (CompanyName == "acko") {
          await dispatch(
            ackoQuickQuoteAction({
              data: {
                ...bodyData.data,
                ...{ vehicleType: "2W" },
                ...{ coverage: [getPreviousAddonData?.coverage[0]] },
              },
            })
          );
          setLoading(false);
          setTriggerApi(true);
        } else if (CompanyName == "tata") {
          let response = await dispatch(
            tataQuickQuoteAction({
              data: {
                ...bodyData.data,
                ...{ coverage: [getPreviousAddonData?.coverage[0]] },
              },
            })
          );
          setLoading(false);
          setTriggerApi(true);
        }
      }
    }

    if (bodyData?.data?.carOwner) {
      callQuickQuoteAPi();
    } else {
      return () => { }
    }
  }, [bodyData?.data?.carOwner]);

  // console.log("carOwnerState",carOwnerState)
  // console.log(formData, "bodyData?.data?.carOwner")


  // call here apecific kyc API

  const customerKyc = async (CompanyName) => {
    switch (CompanyName) {
      case "sbi":
        await dispatch(
          sbiKycAuto({
            data: {
              ...formData,
              ...{
                dob: formData?.dob?.toString(),
                ...{
                  requestId:
                    sbiQuickQuoteData?.data?.body?.data?.body?.RequestHeader
                      ?.requestID,
                },
              },
            },
          })
        );
        break;
      case "icici":
        await dispatch(
          iciciKycAuto({
            data: {
              ...formData,
              ...{
                dob: formData?.dob,
                ...{
                  correlationId:
                    iciciQuickQuoteData?.data.data?.body?.CorrelationId,
                },
              },
            },
          })
        );
        break;
      case "hdfc":
        await dispatch(
          hdfcKycAuto({
            data: {
              ...formData,
              ...{
                dob: formData?.dob,
                trackingid: id
              },
            },
          })
        );
        break;
      case "zuno":
        await dispatch(
          zunoKycCustomerAction({
            data: {
              ...formData,
              ...{
                dob: formData?.dob
              },
            },
          })
        );
        break;
      default:
        break;
    }
  };
  let [mobileNo, setmobileNo] = useState(localStorage.getItem("mobileNo"));

  // let pincode = bodyData?.data?.pincode;
  let vehicleType = bodyData?.data?.vehicleType;

  const mappingAllData = [bodyData].map((data) => {
    return {
      isNilDepInPrevPolicy: formData?.isNilDepInPrevPolicy,
      carOwner: formData?.carOwner,
      firstName: formData?.firstName,
      fathersName: formData?.fathersName,
      lastName: formData?.lastName,
      appointeeName: formData?.appointeeName,
      appointeeRelation: formData?.appointeeRelation,
      paExclusionReason: formData?.paExclusionReason,
      companyName: formData?.companyName,
      email: formData?.email,
      phoneNumber: formData?.phoneNumber,
      alternatePhoneNumber: formData?.alternatePhoneNumber,
      gstNumber: formData?.gstNumber,
      gender: formData?.gender,
      maritalStatus: formData?.maritalStatus,
      dob: formData?.dob?.toString(),
      nomineeName: formData?.nomineeName,
      nomineeAge: formData?.nomineeAge,
      nomineeRelation: formData?.nomineeRelation,
      addressOne: formData?.addressOne,
      addressTwo: formData?.addressTwo,
      state: formData?.state,
      city: formData?.city,
      pincode: formData?.pincode ? formData?.pincode : pincode,
      prevPolicyNumber: formData?.prevPolicyNumber,
      prevPolicyNameInsurer: formData?.prevPolicyNameInsurer,
      prevOdPolicyNumber: formData?.prevOdPolicyNumber,
      prevOdPolicyNameInsurer: formData?.prevOdPolicyNameInsurer,
      chassisNumber: formData?.chassisNumber
        ? formData?.chassisNumber
        : data?.data?.chassisNumber,
      engineNumber: formData?.engineNumber
        ? formData?.engineNumber
        : data?.data?.engineNumber,
      carIsInLoan: formData?.carIsInLoan,
      kycAadharNumber: formData?.kycAadharNumber,
      kycPanNumber: formData?.kycPanNumber,
      kycProfileImg: formData?.kycProfileImg,
      kycProfileImgFileType: formData?.kycProfileImgFileType,
      kycAadharImg: formData?.kycAadharImg,
      kycAadharImgBack: formData?.kycAadharImgBack,
      kycAadharImgFileType: formData?.kycAadharImgFileType,
      kycAadharImgBackFileType: formData?.kycAadharImgBackFileType,
      kycPanImgFileType: formData?.kycPanImgFileType,
      kycPanImg: formData?.kycPanImg,
      CertificateOfIncorporationNumber:
        formData?.CertificateOfIncorporationNumber,
      CertificateOfIncorporationFileFileType:
        formData?.CertificateOfIncorporationFileFileType,
      CertificateOfIncorporationFile: formData?.CertificateOfIncorporationFile,
      carHypothecationCompany: formData?.carHypothecationCompany,
      isCarPUC: formData?.isCarPUC,
      typeOfKycDocument: formData?.typeOfKycDocument,
      ckycResponseNumber: formData?.ckycResponseNumber,
      ckycUniqueId: formData?.ckycUniqueId,
      // viewPlans data
      PolicyTypeValue: data?.data?.PolicyTypeValue,
      basicOwnDamge: data?.data?.basicOwnDamge,
      basicTPprice: data?.data?.basicTPprice,
      cc: data?.data?.cc,
      coverage:
        formData?.carOwner?.toUpperCase() === "individualOwner".toUpperCase()
          ? [getPreviousAddonData?.coverage[0]]
          : coverages,
      grossPremium: data?.data?.grossPremium,
      gstPrice: data?.data?.gstPrice,
      idvValue: data?.data?.idvValue,
      isClaimPrevPolicy: data?.data?.isClaimPrevPolicy,
      isCngLpgVehicleType: data?.data?.isCngLpgVehicleType,
      odInternalCngLpg: data?.data?.odInternalCngLpg,
      tpInternalCngLpg: data?.data?.tpInternalCngLpg,
      isPrevPolicyExp: data?.data?.isPrevPolicyExp,
      make: data?.data?.make,
      posp: data?.data?.posp,
      maxIdv: data?.data?.maxIdv,
      mfgDate: data?.data?.policyRegDate,
      minIdv: data?.data?.minIdv,
      model: data?.data?.model,
      ncbDiscount: data?.data?.ncbDiscount,
      netPayable: data?.data?.netPayable,
      newNcb: data?.data?.newNcb,
      onBLurIdbValue: data?.data?.onBLurIdbValue,
      ownDamge: data?.data?.ownDamge,
      paCover: data?.data?.paCover,
      paidDriverAmt: data?.data?.paidDriverAmt,
      policyExpDate: data?.data?.policyExpDate,
      policyRegDate: data?.data?.policyRegDate,
      prevNcb: data?.data?.prevNcb,
      prevPolicyExpLastMonth: data?.data?.prevPolicyExpLastMonth,
      prevPolicyODexpDate: data?.data?.prevPolicyODexpDate,
      prevPolicyType: data?.data?.prevPolicyType,
      seatingCapacity: data?.data?.seatingCapacity,
      totalAddonsPrice: data?.data?.totalAddonsPrice,
      totalTPprice: data?.data?.totalTPprice,
      typeOfBusiness: data?.data?.typeOfBusiness,
      varient: data?.data?.varient,
      vehicleNumber: data?.data?.vehicleNumber,
      isFindMyCar: data?.data?.isFindMyCar,
      fuelType: data?.data?.fuelType,
      nonElectricalAccesories: data?.data?.nonElectricalAccesories,
      electricalAccesories: data?.data?.electricalAccesories,
      pcCoverSumInsured: data?.data?.pcCoverSumInsured,
      mobileNo,
      vehicleType: "2W"
    };
  });
  const isPaCoverChecked = bodyData?.data?.coverage[0]?.find(item => item.id === "checkbox1")?.checked || false;
  isZeroDepChecked = bodyData?.data?.coverage[0]?.find(item => item.id === "checkbox2")?.checked || false;



  useEffect(() => {

    setFormData((previtems) => ({
      ...previtems,
      firstName: hdfcKycData?.data?.name || "",
      companyName: hdfcKycData?.data?.name || "",
      fathersName: null || "",
      lastName: null || "",
      pincode: hdfcKycData?.data?.permanentPincode || "",
      kycPanNumber: hdfcKycData?.data?.pan || "",
      addressOne: hdfcKycData?.data?.permanentAddress1 || "",
      addressTwo: hdfcKycData?.data?.permanentAddress2 || "",
      state: hdfcKycData?.data?.statename || "",
      city: hdfcKycData?.data?.permanentCity || "",
      dob: formData.carOwner !== "isComapny" ? hdfcKycData?.data?.dob : hdfcKycData?.data?.doi || "",

    }))

  }, [hdfcKycData])

  const handleTataNilDep = (e) => {
    const { id, value, name } = e?.target || {}
  }

  let kycGender = "";

  //set policy number / quoatation number

  useEffect(() => {
    dispatch(
      allBodyDataAction({
        ...mappingAllData[0],
        ...formData,
      })
    );
  }, [sbiAutoKycData?.kycStatus === "CKYCSuccess", !isAutoKyc, formData]);//[formData]);

  const handleInputChange = (event, constName) => {
    const { name, value, checked } = event?.target || {};
    if (name && errors) {
      delete errors[name]
    }
    if (
      name === "kycAadharImg" ||
      name === "kycProfileImg" ||
      name === "kycPanImg" ||
      name === "kycAadharImgBack" ||
      name === "CertificateOfIncorporationFile"
    ) {
      const file = event.target.files[0];
      let uploadedFileName = "";
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const base64Data = e.target.result.split(",")[1];
          const fileExtension = "." + file.type.split("/").pop();

          setFormData((prevItems) => ({
            ...prevItems,
            [name]: base64Data,
            [`${name}FileType`]: fileExtension,
          }));
        };

        reader.readAsDataURL(file);
        uploadedFileName = file.name;
        const uploadedFileType = file.type;

        if (uploadedFileName.length > 40) {
          uploadedFileName = "..." + uploadedFileName.slice(-7);
        }

        if (name === "kycAadharImg") {
          setAadharImgName(uploadedFileName);
        } else if (name === "kycAadharImgBack") {
          setAadharImgBackName(uploadedFileName);
        } else if (name === "kycPanImg") {
          setPanImgName(uploadedFileName);
        } else if (name === "kycProfileImg") {
          setImage(URL.createObjectURL(file));
          setProfileImgName(uploadedFileName);
        } else if (name == "CertificateOfIncorporationFile") {
          setCINImgName(uploadedFileName);
        }
      }
    } else if (name === "carOwner") {
      // SetcarOwnerState( checked ? "isCompany" : "individualOwner",)
      setFormData((prevItems) => ({
        ...prevItems,
        carOwner: checked ? "isCompany" : "individualOwner",
      }));

    } else if (name === "phoneNumber") {
      if (value.length <= 10) {
        setcheckphone(false)
        setFormData((prevItems) => ({
          ...prevItems,
          phoneNumber: value,
        }));
      }
    } else if (name == "nomineeAge") {
      if (value < 125) {
        setFormData((prevItems) => ({
          ...prevItems,
          nomineeAge: value,
        }));
      }
    } else if (name === "firstName") {    //here adding a validation for firstName & lastName for Numeric and special char
      let newValu = value.replace(/[0-9\!\@\#\$\%\^\&\*\)\(\+\=\.\<\>\{\}\[\]\:\;\'\"\|\~\`\_\-\\\?]/g, '');
      setFormData((prevItems) => ({
        ...prevItems,
        firstName: newValu?.toUpperCase(),
      }));
    }
    else if (name === "lastName") {
      let newValu = value.replace(/[0-9\!\@\#\$\%\^\&\*\)\(\+\=\.\<\>\{\}\[\]\:\;\'\"\|\~\`\_\-\\\?]/g, '');
      setFormData((prevItems) => ({
        ...prevItems,
        lastName: newValu?.toUpperCase(),
      }));
    }
    else if (name === "kycPanNumber") {
      let newValu = value.replace(/[\!\@\#\$\%\^\&\*\)\(\+\=\.\<\>\{\}\[\]\:\;\'\"\|\~\`\_\-\\\?]/g, '');
      setFormData((prevItems) => ({
        ...prevItems,
        kycPanNumber: newValu?.toUpperCase(),
      }));
    } else if (name === "isNilDepInPrevPolicy") {
      if (value === "true") {
        setShowNilDepState(true)
        setFormData((prevItems) => ({
          ...prevItems,
          isNilDepInPrevPolicy: value,
        }));
      } else {
        setShowNilDepState(false)
        setFormData((prevItems) => ({
          ...prevItems,
          isNilDepInPrevPolicy: value,
        }));

      }
    }//here adding a validation for firstName & lastName for Numeric and special char
    else if (name === "firstName") {
      let newValu = value.replace(/[0-9\!\@\#\$\%\^\&\*\)\(\+\=\.\<\>\{\}\[\]\:\;\'\"\|\~\`\_\-]/g, '');
      setFormData((prevItems) => ({
        ...prevItems,
        firstName: newValu?.toUpperCase(),
      }));
    } else if (name === "lastName") {

      let newValu = value.replace(/[0-9\!\@\#\$\%\^\&\*\)\(\+\=\.\<\>\{\}\[\]\:\;\'\"\|\~\`\_\-]/g, '');
      setFormData((prevItems) => ({
        ...prevItems,
        lastName: newValu?.toUpperCase(),
      }));
    }

    else {
      setFormData((prevItems) => ({
        ...prevItems,
        [name]: value,
      }));
    }
  };

  const handleDatePickerValue = (newDate) => {
    setFormData((prevData) => ({
      ...prevData,
      dob: newDate,
    }));
  };

  const handlePopUps = () => {
    if (ShowCityDropdown) {
      setShowCityDropdown(false);
    }
  };

  const HandleEditProposalDetails = (fromName, state) => {
    if (fromName === "ownerDetails") {
      setShowForm("ownerDetail");
      setEdit(true);
    } else if (fromName === "personalDetails") {
      setShowForm("PersonalDetails");
      setEdit(true);
    } else if (fromName === "addressDetails") {
      setShowForm("AddressDetails");
      setEdit(true);
    } else if (fromName === "vehicleDetails") {
      setStepperValue(4);
      setShowForm("VehicleDetails");
      setEdit(true);
    }
  };

  const handleBackBtn = (formName) => {
    setEdit(false);
    switch (formName) {
      case "PersonalDetails":
        setStepperValue(StepperValue - 1);
        setShowForm("ownerDetail");
        break;

      case "AddressDetails":
        if (CompanyName !== ACKO && formData.carOwner !== "isCompany") { //here change some code
          setStepperValue(StepperValue - 1);
          setShowForm("PersonalDetails");
        } else {
          setShowForm("ownerDetail");
        }
        break;

      case "vehicleDetails":
        setStepperValue(StepperValue - 1);
        setShowForm("AddressDetails");
        break;

      case "kycDetails":
        // alert("ka")
        setStepperValue(4);
        setShowForm("VehicleDetails");
        break;

      case "InspectionDetails":
        setStepperValue(5);
        setShowForm("kycDetails");
        break;

      case "FormDetails":
        if (CompanyName == ACKO || CompanyName == "tata") {
          setStepperValue(4);
          setShowForm("VehicleDetails");
        } else if (CompanyName == TATA) {
          setStepperValue(4);
          setShowForm("VehicleDetails");
        }
        else {
          if (InspectionStatus) {
            setShowForm("InspectionDetails");
            setStepperValue(StepperValue - 1);
          } else {
            setStepperValue(5);
            setShowForm("kycDetails");
          }
        }
        break;

      default:
        break;
    }
  };

  const makeKyc = async (e) => {
    e.preventDefault();
    //if company name to kyc else
    if (CompanyName == "sbi") {
      const isValidKyc = validateKycDetails();
      if (isValidKyc) {
        setIsDisabledButton(true);
        if (isAutoKyc) {
          await customerKyc("sbi");
          setIsDisabledButton(false);
        }
        if (!isAutoKyc) {
          await dispatch(sbiKyc(bodyData));
          setIsDisabledButton(false);
        }
      }
    } else if (CompanyName == "icici") {
      const isValidKyc = validateKycDetails();
      if (isValidKyc) {
        setIsDisabledButton(true);
        if (isAutoKyc) {
          await customerKyc("icici");
          setIsDisabledButton(false);
        }
        if (!isAutoKyc) {
          await dispatch(
            iciciKyc({
              data: {
                ...bodyData.data,
                ...{
                  correlationId:
                    iciciQuickQuoteData?.data.data?.body?.CorrelationId,
                  dob: formData?.dob?.toString(),
                },
              },
            })
          );
          setIsDisabledButton(false);
        }
      } else {
        setIsDisabledButton(false);
      }
    } else if (CompanyName == "hdfc") {
      const isValidKyc = validateKycDetails();
      if (isValidKyc) {
        setIsDisabledButton(true);
        if (isAutoKyc) {
          await customerKyc("hdfc");
          setIsDisabledButton(false);
        }
      }
    } else if (CompanyName == "zuno") {
      const isValidKyc = validateKycDetails();
      if (isValidKyc) {
        setIsDisabledButton(true);
        if (isAutoKyc) {
          await customerKyc("zuno");
          setIsDisabledButton(false);
        }
      }
    } else if (CompanyName == "digit") {
      const isValidKyc = validateKycDetails();
      if (isValidKyc) {
        setShowForm("FormDetails");
        setIsDisabledButton(true);
        if (isAutoKyc) {
          setIsDisabledButton(false);
        }
        if (!isAutoKyc) {
          await dispatch(sbiKyc(bodyData));
          setIsDisabledButton(false);
        }
      }
    } else if (CompanyName == "shreeram") {
      setFormData((prevItems) => ({
        ...prevItems,
        typeOfKycDocument: "C",
      }));

      const isValidKyc = validateKycDetails();
      if (isValidKyc) {
        handleFormReveal("kycDetails");
      }
    }
    // else if (CompanyName == "tata") {
    //   const isValidKyc = validateKycDetails();
    //   if (isValidKyc) {
    //     setIsDisabledButton(true);
    //     await dispatch(tataManualKycAction({
    //       data: {
    //         ...bodyData.data,
    //         ...{
    //           proposalNo:
    //             tataProposalRes.data.data.quote.data[0].proposal_id,
    //           autoKycRsponseReqId:
    //             tataAutoKyc.data.data.quote.data.req_id,
    //         },
    //       }
    //     }));
    //     setIsDisabledButton(false);
    //   }
    // } 
    else {
      setIsDisabledButton(false);
      const isValidKyc = validateKycDetails();
      //shreeram digit proceed to confirm details here aand handle other thimgs

      if (isValidKyc) {
        handleFormReveal("kycDetails");
      }
      if (!isAutoKyc) {
        try {
          let maniualKycResponse = await dispatch(
            sbiKyc({
              data: {
                ...bodyData.data,
                ...{
                  requestId:
                    sbiQuickQuoteData?.data?.body?.data?.body?.RequestHeader
                      ?.requestID,
                },
              },
            })
          );
          setIsDisabledButton(false);
          if (maniualKycResponse?.payload?.message) {
            toast.error("KYC " + maniualKycResponse?.payload?.message, {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          }
        } catch (error) {
          toast.error("Internal Server Error", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      }
    }
  };

  useEffect(() => {
    if (
      !kycAutoData?.isLoading &&
      !kycAutoData?.isError &&
      kycAutoData?.data?.body?.data?.data
    ) {
      const { A99SearchDownloadResponse, A99ResponseData } =
        kycAutoData?.data?.body?.data?.data;
      if (A99ResponseData?.CKYCDownloadResult?.CKYCStatus == "CKYCSuccess") {
        setIsDisabledButton(false);
        handleFormReveal("kycDetails");
      } else {
        kycAutoData = "";
        if (formData?.carOwner !== "individualOwner") {
          setFormData((prevItems) => ({
            ...prevItems,
            typeOfKycDocument: "Certificate of Incorporation",
          }));
        } else {
          setFormData((prevItems) => ({
            ...prevItems,
            typeOfKycDocument: "Aadhar Card",
          }));
        }
        setAutoKYCrejected(true);
        setIsAutoKyc(false);
        setIsDisabledButton(false);
      }
    } else if (
      (kycAutoData?.isError && !kycAutoData?.data?.body?.data?.data) ||
      kycAutoData?.data?.message?.toUpperCase() ===
      "Service Unavailable".toUpperCase() ||
      kycAutoData?.data?.message?.toUpperCase() ===
      "Internal Server Error".toUpperCase()
    ) {
      kycAutoData = "";
      setFormData((prevItems) => ({
        ...prevItems,
        typeOfKycDocument: "Aadhar Card",
      }));
      setAutoKYCrejected(true);
      setIsAutoKyc(false);
      setIsDisabledButton(false);
    }
  }, [kycAutoData]);

  useEffect(() => {
    if (
      !iciciKycAutoData?.isLoading &&
      !iciciKycAutoData?.isError &&
      iciciKycAutoData?.data?.body?.data
    ) {
      if (iciciKycAutoData?.data?.body?.data?.statusMessage == "Failed") {
        if (formData.carOwner == "individualOwner") {
          setFormData((prevItems) => ({
            ...prevItems,
            typeOfKycDocument: "Aadhar Card",
          }));
        } else {
          setFormData((prevItems) => ({
            ...prevItems,
            typeOfKycDocument: "C",
          }));
        }
        setAutoKYCrejected(true);
        setIsAutoKyc(false);
        setIsDisabledButton(false);
        // iciciKycAutoData = "";
      } else {
        setIsDisabledButton(false);
        handleFormReveal("kycDetails");
      }
    } else if (
      (iciciKycAutoData?.isError && !iciciKycAutoData?.data?.body?.data) ||
      iciciKycAutoData?.data?.message?.toUpperCase() ===
      "Service Unavailable".toUpperCase() ||
      iciciKycAutoData?.data?.message?.toUpperCase() ===
      "Internal Server Error".toUpperCase()
    ) {
      iciciKycAutoData = "";
      setFormData((prevItems) => ({
        ...prevItems,
        typeOfKycDocument: "Aadhar Card",
      }));
      setAutoKYCrejected(true);
      setIsAutoKyc(false);
      setIsDisabledButton(false);
    }
  }, [iciciKycAutoData]);

  useEffect(() => {
    if (
      kycManualData?.data?.body?.data?.data &&
      kycManualData?.data?.body?.data?.data?.status == "Success"
    ) {
      setIsKycSuccess(true);
      setIsDisabledButton(false);
      handleFormReveal("kycDetails");
    }

    if (isKycSuccess) {
      setIsKycSuccess(false);
      setIsDisabledButton(false);
      handleFormReveal("kycDetails");
    }
    if (
      kycManualData?.data?.body?.data?.data?.status.toUpperCase() === "FAILURE"
    ) {
      toast.error("KYC " + kycManualData?.data?.body?.data?.data?.status, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }, [kycManualData]);

  useEffect(() => {
    if (
      iciciKycManualData?.data?.body?.data &&
      iciciKycManualData?.data?.body?.data?.statusMessage != "Failed"
    ) {
      setIsKycSuccess(true);
      setIsDisabledButton(false);
      handleFormReveal("kycDetails");
    }

    if (isKycSuccess) {
      setIsKycSuccess(false);
      setIsDisabledButton(false);
      handleFormReveal("kycDetails");
    }
    if (iciciKycManualData?.data?.body?.data?.statusMessage == "Failed") {
      toast.error("KYC " + iciciKycManualData?.data?.body?.data?.message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }, [iciciKycManualData]);

  // zuno Auto & Manual
  useEffect(() => {
    (async function () {
      if (
        !zunoKycAutoData?.isLoading &&
        !zunoKycAutoData?.isError &&
        zunoKycAutoData?.data?.data?.quote
      ) {
        if (zunoKycAutoData?.data?.data?.quote?.data?.KYC_Status !== "1" && !zunoKycId) {
          setLoading(true);
          let zunoData = {
            kycId: zunoKycAutoData?.data?.data?.quote?.data?.VISoF_KYC_Req_No,
            data: {
              zunoBody: bodyData,
            },
            type: "insert",
          };
          await dispatch(
            zunoInsertBodydAction(
              zunoData
            ));
          window.location.replace(zunoKycAutoData?.data?.data?.quote?.data?.IC_KYC_REG_URL);
          // setAutoKYCrejected(true);
          // setIsAutoKyc(false);
          // setIsDisabledButton(false);
        } else {
          setIsDisabledButton(false);
          handleFormReveal("kycDetails");
        }
      } else if (
        (zunoKycAutoData?.isError && !zunoKycAutoData?.data?.data?.quote) ||
        zunoKycAutoData?.data?.message?.toUpperCase() ===
        "Service Unavailable".toUpperCase() ||
        zunoKycAutoData?.data?.message?.toUpperCase() ===
        "Internal Server Error".toUpperCase()
      ) {
        if (!zunoKycId) {
          setLoading(true);
          let zunoData = {
            kycId: zunoKycAutoData?.data?.data?.quote?.data?.VISoF_KYC_Req_No,
            data: {
              zunoBody: bodyData,
            },
            type: "insert",
          };
          await dispatch(
            zunoInsertBodydAction(
              zunoData
            ));
          window.location.replace(zunoKycAutoData?.data?.data?.quote?.data?.IC_KYC_REG_URL);
        }
      }
    })();
  }, [zunoKycAutoData]);


  // Hdfc kyc
  useEffect(() => {
    (async function () {
      if (
        !hdfcKycAutoData?.isLoading &&
        !hdfcKycAutoData?.isError &&
        hdfcKycAutoData?.data?.data?.quote
      ) {
        if (hdfcKycAutoData?.data?.data?.quote?.data?.iskycVerified !== 1) {

          let hdfcPortalKyc = hdfcKycAutoData?.data?.data?.quote?.data?.redirect_link
          dispatch(hdfcKycAutoReducer.actions.resethdfcKycAutoAction());
          window.location.replace(hdfcPortalKyc);
          setAutoKYCrejected(true);
          setIsAutoKyc(false);
          setIsDisabledButton(false);
        } else {
          console.log("In HDFC AUto Kyc passed")
          let data = {
            uuid: id,
            type: "fetch"
          };
          let resp = await dispatch(transactionURLAction(data));
          await sethdfcKycData(JSON.parse(resp?.payload?.data?.txninfo[0].kyc_auto_data));
          console.log("Kyc data from Db ", hdfcKycData)
          setIsDisabledButton(false);
          handleFormReveal("kycDetails");
        }
      } else if (
        (hdfcKycAutoData?.isError && !hdfcKycAutoData?.data?.data?.quote) ||
        hdfcKycAutoData?.data?.message?.toUpperCase() ===
        "Service Unavailable".toUpperCase() ||
        hdfcKycAutoData?.data?.message?.toUpperCase() ===
        "Internal Server Error".toUpperCase()
      ) {
        window.location.replace(hdfcKycAutoData?.data?.data?.quote?.data?.redirect_link);
      }
    })();
  }, [hdfcKycAutoData]);

  useEffect(() => {
    dispatch(getStateAction("statename"));
    return () => { };
  }, []);

  useEffect(() => {
    dispatch(getCityAction(formData?.state));
    return () => { };
  }, [formData?.state]);

  useEffect(() => {
    dispatch(getInsurerList("insurename"));
  }, []);

  const getInsurerListResponse = useSelector((state) => {
    return state?.getInsurerDetails?.data?.data?.result;
  });

  const insurerList = getInsurerListResponse?.map((data) => {
    return {
      label: data?.name_of_insurance_company,
      value: data?.name_of_insurance_company,
    };
  });

  const handleFinancier = (value) => {
    delete errors?.carHypothecationCompany

    setFormData((prevItems) => ({
      ...prevItems,
      carHypothecationCompany: value?.value,
    }));
  };

  const handleStateValue = (value) => {
    delete errors?.state
    setFormData((prevItems) => ({
      ...prevItems,
      state: value?.value,
      city: null
    }));
  };

  const handleCityValue = (value) => {
    delete errors?.city
    setFormData((prevItems) => ({
      ...prevItems,
      city: value?.value,
    }));
  };

  const handleBundleInsurer = (value) => {
    delete errors?.prevPolicyNameInsurer;
    setFormData((prevItems) => ({
      ...prevItems,
      prevPolicyNameInsurer: value?.value,
    }));
  };

  const handleOdInsurer = (value) => {
    delete errors?.prevOdPolicyNameInsurer
    setFormData((prevItems) => ({
      ...prevItems,
      prevOdPolicyNameInsurer: value?.value,
    }));
  };

  const handleStepperRoute = (formName) => {
    let isValid = "";
    let isPersonalValid = "";
    let isValidVehicleDetails = "";
    let isValidAdd = "";
    switch (formName) {
      case "ownerDetail":
        setShowForm("ownerDetail");
        break;

      case "PersonalDetails":
        isValid = validatePersonalDetails();
        if (isValid) {
          setShowForm("PersonalDetails");
          setStepperValue(2);
        }
        break;

      case "AddressDetails":
        isPersonalValid = validateGendernMarritalDetails();
        isValid = validatePersonalDetails();
        if (formData.carOwner !== "isCompany" && CompanyName != ACKO) {
          if (isPersonalValid) {
            setShowForm("AddressDetails");
            setStepperValue(3);
          }
        } else {
          if (isValid) {
            // setShowForm("AddressDetails");
            // setStepperValue(3);
          }
        }

        break;

      case "VehicleDetails":
        isValidAdd = validateAddressDetails();
        if (isValidAdd) {
          setShowForm("VehicleDetails");
          setStepperValue(4);
        }
        break;

      case "kycDetails":
        isValidVehicleDetails = validateVehicleDetails();
        if (isValidVehicleDetails) {
          setShowForm("kycDetails");
          setStepperValue(5);
        }
        break;

      default:
        break;
    }
  };

  const handleFormReveal = async (formName, e, editFormName) => {
    if (e) {
      e.preventDefault();
    }
    // setEdit(false);
    switch (formName) {
      case "ownerDetail":
        let isValid = validatePersonalDetails();
        let isValidPhone = validatePhoneisExist()

        if (isValid && isValidPhone) {
          let data = {
            uuid: id,
            bodyData: bodyData.data,
            companyName: CompanyName,
            formData,
            formValue: CompanyName == ACKO ? "AddressDetails" : "PersonalDetails",
            stepperValue: StepperValue + 1,
            type: "update_proposal"
          };
          SetPhonestatussloading(true)
          let isPhonValid = await dispatch(
            phonevalidationAction({
              data: {
                phoneNumber: formData?.phoneNumber
              }

            })
          );
          SetPhonestatussloading(false)
          if (!isPhonValid?.payload?.data?.multipleNumer) {
            setcheckphone(isPhonValid?.payload?.data?.multipleNumer)
            await dispatch(transactionURLAction(data));
            setShowForm(CompanyName == ACKO ? "AddressDetails" : "PersonalDetails");
            setStepperValue(StepperValue + 1);

          } else {

            setcheckphone(isPhonValid?.payload?.data?.multipleNumer)
            setErrors({ ...errors, phoneNumber: "Phone number already exist (Duplicate Number)" })
          }
        }

        break;
      case "PersonalDetails":
        let isPersonalValid = validateGendernMarritalDetails();
        if (formData.carOwner !== "isCompany") {
          if (isPersonalValid) {
            let data = {
              uuid: id,
              bodyData: bodyData.data,
              companyName: CompanyName,
              formData,
              formValue: "AddressDetails",
              stepperValue: StepperValue + 1,
              type: "update_proposal"
            };
            await dispatch(transactionURLAction(data));
            setShowForm("AddressDetails");
            setStepperValue(StepperValue + 1);
          }
        } else {
          let isValid = validatePersonalDetails();
          if (isValid) {
            let data = {
              uuid: id,
              bodyData: bodyData.data,
              companyName: CompanyName,
              formData,
              formValue: "AddressDetails",
              stepperValue: StepperValue + 1,
              type: "update_proposal"
            };
            await dispatch(transactionURLAction(data));
            setShowForm("AddressDetails");
            setStepperValue(StepperValue + 1);
          }
        }

        break;
      case "AddressDetails":
        const isValidAdd = validateAddressDetails();
        if (isValidAdd) {
          let data = {
            uuid: id,
            bodyData: bodyData.data,
            companyName: CompanyName,
            formData,
            formValue: "VehicleDetails",
            stepperValue: 4,
            type: "update_proposal"
          };
          await dispatch(transactionURLAction(data));
          setShowForm("VehicleDetails");
          setStepperValue(4);
        }
        break;
      case "vehicleDetails":
        const isValidVehicleDetails = validateVehicleDetails();
        if (isValidVehicleDetails) {
          if (CompanyName == ACKO || CompanyName == TATA) {
            let data = {
              uuid: id,
              bodyData: bodyData.data,
              companyName: CompanyName,
              formData,
              formValue: "FormDetails",
              stepperValue: 6,
              type: "update_proposal"
            };
            await dispatch(transactionURLAction(data));

            setShowForm("FormDetails");
            setStepperValue(6);
          } else {
            let data = {
              uuid: id,
              bodyData: bodyData.data,
              companyName: CompanyName,
              formData,
              formValue: "kycDetails",
              stepperValue: 5,
              type: "update_proposal"
            };
            await dispatch(transactionURLAction(data));
            setShowForm("kycDetails");
            setStepperValue(5);
          }
        }
        break;
      case "kycDetails":
        if (InspectionStatus) {
          let data = {
            uuid: id,
            bodyData: bodyData.data,
            companyName: CompanyName,
            formData,
            formValue: "InspectionDetails",
            stepperValue: StepperValue + 1,
            type: "update_proposal"
          };
          await dispatch(transactionURLAction(data));
          setShowForm("InspectionDetails");
          setStepperValue(StepperValue + 1);
        } else {
          let data;
          if (CompanyName != ICICI && CompanyName != HDFC)
            data = {
              uuid: id,
              bodyData: bodyData.data,
              companyName: CompanyName,
              formData,
              formValue: "FormDetails",
              stepperValue: StepperValue + 1,
              type: "update_proposal"
            };
          if (!Edit) {
            setShowForm("FormDetails");
            await dispatch(transactionURLAction(data));
          }


          if (editFormName === "editOwnerDetail") {
            const validOwnerForm = validatePersonalDetails();
            if (validOwnerForm) {
              setShowForm("FormDetails");
              await dispatch(transactionURLAction(data));
            }
          } else if (editFormName === 'editPersonalDetails') {
            const validPersonalForm = validateGendernMarritalDetails()
            if (validPersonalForm) {
              setShowForm("FormDetails");
              await dispatch(transactionURLAction(data));
            }
          } else if (editFormName === 'editAddressDetails') {
            const validAddress = validateAddressDetails()
            if (validAddress) {
              setShowForm("FormDetails");
              await dispatch(transactionURLAction(data));
            }
          } else if (editFormName == 'editvehicleDetails') {
            const validateVehicle = validateVehicleDetails()
            if (validateVehicle) {
              setShowForm("FormDetails");
              await dispatch(transactionURLAction(data));
            }
          }
        }
        break;
      case "InspectionDetails":
        let data = {
          uuid: id,
          bodyData: bodyData.data,
          companyName: CompanyName,
          formData,
          formValue: "FormDetails",
          stepperValue: StepperValue + 1,
          type: "update_proposal"
        };
        await dispatch(transactionURLAction(data));
        setShowForm("FormDetails");
        setStepperValue(StepperValue + 1);
        break;
      default:
        break;
    }
  };

  // VALIDATION FUNCTION FOR ALTERNATE FORMS

  const validateAddressDetails = () => {
    const newErrors = {};
    if (!formData.addressOne) {
      newErrors.addressOne = "Address Line 1 is required";
    }
    if (!formData.addressTwo) {
      newErrors.addressTwo = "Address Line 2 is required";
    }
    if (!formData.state) {
      newErrors.state = "State is required";
    }
    if (!formData.city) {
      newErrors.city = "City is required";
    }
    if (!formData.pincode && !pincode) {
      newErrors.pincode = "Pin code is required";
    }

    let pinregEx = /^[1-9]{1}[0-9]{2}[0-9]{3}$/;
    if (formData.pincode && !pinregEx.test(formData.pincode)) {
      newErrors.pincode = "Invalid pin code";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const validateVehicleDetails = () => {
    const newErrors = {};


    if (
      mappingAllData.length &&
      mappingAllData[0]?.typeOfBusiness?.toLowerCase() != "new" && !mappingAllData[0]?.prevPolicyExpLastMonth
    ) {
      if (!formData.prevPolicyNumber) {
        newErrors.prevPolicyNumber = "Previous policy Number is required";
      }
      if (!formData.prevPolicyNameInsurer) {
        newErrors.prevPolicyNameInsurer = "Insurer is required";
      }
    }
    if (!formData.chassisNumber) {
      newErrors.chassisNumber = "Chassis Number is required";
    }
    if (!formData.chassisNumber && CompanyName === "hdfc" && formData.chassisNumber.length !== 17) {
      newErrors.chassisNumber = "Chassis Number should be 17 digit ";
    }
    if (!formData.engineNumber) {
      newErrors.engineNumber = "Engine Number is required";
    }
    if (!formData.carIsInLoan) {
      newErrors.carIsInLoan = "Please select one option";
    }

    if (formData?.carIsInLoan == "Yes" && !formData.carHypothecationCompany) {
      newErrors.carHypothecationCompany = "Please select one option"
    }
    if (formData.isCarPUC === "No") {
      newErrors.isCarPUC = "To proceed further you should have Valid PUC";
    }

    if (
      (mappingAllData[0]?.prevPolicyType == "Bundled (3Y TP + 1Y OD)" ||
        mappingAllData[0]?.prevPolicyType == "Bundled") &&
      mappingAllData[0]?.PolicyTypeValue == "Own Damage" && !mappingAllData[0]?.prevPolicyExpLastMonth
    ) {
      if (!formData?.prevOdPolicyNumber) {
        newErrors.prevOdPolicyNumber = "Previous Own Damage Number is required";
      }

      if (!formData?.prevOdPolicyNameInsurer) {
        newErrors.prevOdPolicyNameInsurer =
          "Previous Own Damage Insurer is required";
      }
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const validatePersonalDetails = () => {

    const newErrors = {};
    if (formData.carOwner !== "isCompany") {
      if (!formData.firstName) {
        newErrors.firstName = "First Name is required";
      }
      if (!formData.lastName) {
        newErrors.lastName = "Last Name is required";
      }
    }

    if (!formData.email) {
      newErrors.email = "Email is required";
    }

    let regexEmail = /^\S+@\S+\.\S+$/;

    if (formData.email && !regexEmail.test(formData.email)) {
      newErrors.email = "Email is Invalid";
    }
    // if (checkphone) {
    //   newErrors.phoneNumber = "Phone Number is already  exist(Duplicate Number) ";
    // }

    let regexMobile = /^[2-9]{1}[0-9]{9}$/;

    if (!formData.phoneNumber) {
      newErrors.phoneNumber = "Phone Number is required";
    }

    if (formData.phoneNumber && !regexMobile.test(formData.phoneNumber)) {
      newErrors.phoneNumber = "Mobile number is Invalid";
    }

    if (formData.carOwner === "isCompany") {
      if (!formData.companyName) {
        newErrors.companyName = "Company Name is required";
      }
      if (!formData.dob && (CompanyName == ICICI)) {
        newErrors.dob = "Date of Incorporation is required";
      }
    }

    if (!isPaCoverChecked && CompanyName === SHREERAM) {
      if (!formData.paExclusionReason) {
        newErrors.paExclusionReason = "PA Exclusion reason is required";
      }
    }

    if (CompanyName === SHREERAM) {
      if (!formData.isNilDepInPrevPolicy) {
        newErrors.isNilDepInPrevPolicy = "required";
      }
    }

    let regpan = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;

    if (CompanyName == TATA && !formData.kycPanNumber) {
      newErrors.kycPanNumber = "Pan number is required";
    }

    if (CompanyName == TATA && isZeroDepChecked && bodyData?.data?.PolicyTypeValue !== "Third Party" && ZeroDepValue !== 0 && vehicleType === "2W") {
      if (!formData.isNilDepInPrevPolicy) {
        newErrors.isNilDepInPrevPolicy = "required";
      }
    }

    if (formData.kycPanNumber) {
      if (!regpan.test(formData.kycPanNumber)) {
        newErrors.kycPanNumber = "Pan number is in Invalid Format";
      }
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const validateGendernMarritalDetails = () => {
    const newErrors = {};
    if (!formData.gender) {
      newErrors.gender = "Gender is required";
    }

    if (!formData.maritalStatus) {
      newErrors.maritalStatus = "Marital Status is required";
    }

    if (!formData.nomineeName) {
      newErrors.nomineeName = "Nominee Name is required";
    }

    if (!formData.nomineeAge) {
      newErrors.nomineeAge = "Age is required";
    }

    // && (formData.nomineeAge < 18 && CompanyName === SHREERAM)

    if (!formData?.nomineeRelation) {
      newErrors.nomineeRelation = "Nominee Relation is required";
    }

    if (!formData.dob) {
      newErrors.dob = "Date of Birth is required";
      // alert("nai hai");
    }

    if (formData.dob) {
      var userDOB = new Date(formData.dob);
      var currentDate = new Date();
      var age = currentDate.getFullYear() - userDOB.getFullYear();

      if (
        currentDate.getMonth() < userDOB.getMonth() ||
        (currentDate.getMonth() === userDOB.getMonth() &&
          currentDate.getDate() < userDOB.getDate())
      ) {
        age--;
      }

      if (age >= 18) {
        console.log("The user is 18 years or older. dobValidation");
      } else {
        newErrors.dob = "You must be 18+ to proceed further";
      }
    }

    if (formData?.nomineeAge < 18 && CompanyName === SHREERAM && formData?.nomineeAge !== "") {
      if (!formData?.appointeeName) {
        newErrors.appointeeName = "Appointee Name is required";
      }
      if (!formData?.appointeeRelation) {
        newErrors.appointeeRelation = "Appointee Relation is required";
      }
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const validateKycDetails = () => {
    const newErrors = {};

    if (!formData.typeOfKycDocument && CompanyName !== SHREERAM) {
      newErrors.typeOfKycDocument = "Please select one document";
    }

    if (
      formData.typeOfKycDocument &&
      formData.typeOfKycDocument == "PAN Card" &&
      !formData.kycPanNumber
    ) {
      newErrors.kycPanNumber = "Pan number is required";
    }

    if (
      AutoKYCrejected &&
      CompanyName == "icici" &&
      formData.carOwner == "isCompany" &&
      !formData.kycPanNumber
    ) {
      newErrors.kycPanNumber = "Pan number is required";
    }

    let regpan = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;
    if (
      formData.typeOfKycDocument &&
      formData.typeOfKycDocument == "PAN Card" &&
      formData.kycPanNumber &&
      !regpan.test(formData.kycPanNumber)
    ) {
      newErrors.kycPanNumber = "Pan number is in invalid format";
    }

    var regaadhar = /^[2-9]{1}[0-9]{11}$/;
    if (
      formData.typeOfKycDocument &&
      formData.typeOfKycDocument == "Aadhar Card" &&
      !formData.kycAadharNumber
    ) {
      newErrors.kycAadharNumber = "Aadhar number is required";
    }

    if (
      formData.typeOfKycDocument &&
      formData.typeOfKycDocument == "Aadhar Card" &&
      formData.kycAadharNumber &&
      !regaadhar.test(formData.kycAadharNumber)
    ) {
      // invalid aadhar card number
      newErrors.kycAadharNumber = "Aadhar number is in invalid format";
    }

    if (
      AutoKYCrejected &&
      formData.typeOfKycDocument == "Aadhar Card" &&
      !formData.kycAadharImg
    ) {
      newErrors.kycAadharImg = "Aadhar Front Image is required";
    }

    if (
      AutoKYCrejected &&
      formData.typeOfKycDocument == "Aadhar Card" &&
      !formData.kycAadharImgBack && CompanyName !== "tata"
    ) {
      newErrors.kycAadharImgBack = "Aadhar Back Image is required";
    }

    if (
      formData.typeOfKycDocument === "PAN Card" &&
      !AutoKYCrejected &&
      CompanyName !== "sbi" &&
      CompanyName !== "icici" &&
      CompanyName !== "digit" &&
      CompanyName !== "zuno" &&
      CompanyName !== "acko" &&
      CompanyName !== "hdfc" &&
      !formData.kycPanImg
    ) {
      newErrors.kycPanImg = "Pan Image is required";
    }

    if (
      AutoKYCrejected &&
      CompanyName == "icici" &&
      formData.carOwner == "isCompany" &&
      !formData.kycPanImg
    ) {
      newErrors.kycPanImg = "Pan Image is required";
    }

    if (
      formData.typeOfKycDocument == "Certificate of Incorporation" &&
      !AutoKYCrejected &&
      CompanyName == "shreeram" &&
      !formData.CertificateOfIncorporationFile
    ) {
      newErrors.CertificateOfIncorporationFile =
        "Certificate of Incorporation is required";
    }

    if (
      AutoKYCrejected &&
      CompanyName == "icici" &&
      formData.carOwner == "isCompany" &&
      !formData.CertificateOfIncorporationFile
    ) {
      newErrors.CertificateOfIncorporationFile =
        "Certificate of Incorporation is required";
    }

    if (
      AutoKYCrejected &&
      CompanyName == "icici" &&
      formData.carOwner == "isCompany" &&
      !formData.CertificateOfIncorporationNumber
    ) {
      newErrors.CertificateOfIncorporationNumber =
        "Certificate of Incorporation is required";
    }

    if (
      formData.typeOfKycDocument == "Certificate of Incorporation" &&
      (!AutoKYCrejected || CompanyName !== "sbi") &&
      !formData.CertificateOfIncorporationNumber
    ) {
      newErrors.CertificateOfIncorporationNumber =
        "Certificate of Incorporation is required";
    }

    if (CompanyName == "shreeram") {
      if (!formData.kycProfileImg) {
        newErrors.kycProfileImg = "Profile Image is required";
      }


      if (formData.carOwner !== "isCompany") {
        if (!formData.fathersName) {
          newErrors.fathersName = "Father Name is required";
        }

        if (!formData.kycPanImg) {
          newErrors.kycPanImg = "Pan Image is required";
          newErrors.typeOfKycDocument = "Please upload Aadhar and Pan details";
        }

        if (!formData.kycPanNumber) {
          newErrors.kycPanNumber = "Pan number is required";
          newErrors.typeOfKycDocument = "Please upload Aadhar and Pan details";
        }

        if (!formData.kycAadharNumber) {
          newErrors.kycAadharNumber = "Aadhar number is required";
          newErrors.typeOfKycDocument = "Please upload Aadhar and Pan details";
        }

        if (!formData.kycAadharImg) {
          newErrors.kycAadharImg = "Aadhar Front Image is required";
          newErrors.typeOfKycDocument = "Please upload Aadhar and Pan details";
        }

        if (!formData.kycAadharImgBack) {
          newErrors.kycAadharImgBack = "Aadhar Back Image is required";
          newErrors.typeOfKycDocument = "Please upload Aadhar and Pan details";
        }
      }

      if (formData.carOwner == "isCompany") {
        if (!formData.kycPanImg) {
          newErrors.kycPanImg = "Pan Image is required";
          newErrors.typeOfKycDocument = "Please upload Certificate of Incorporation and Pan details";
        }

        if (!formData.kycPanNumber) {
          newErrors.kycPanNumber = "Pan number is required";
          newErrors.typeOfKycDocument = "Please upload Aadhar and Pan details";
        }

        if (!formData.CertificateOfIncorporationFile) {
          newErrors.CertificateOfIncorporationFile =
            "Certificate of Incorporation is required";
          newErrors.typeOfKycDocument = "Please upload Certificate of Incorporation and Pan details";
        }

        if (!formData.CertificateOfIncorporationNumber) {
          newErrors.CertificateOfIncorporationNumber =
            "Certificate of Incorporation is required";
          newErrors.typeOfKycDocument = "Please upload Certificate of Incorporation and Pan details";
        }
      }
    }

    if (CompanyName == "digit" && formData.carOwner !== "isCompany") { }

    if (AutoKYCrejected && formData?.typeOfKycDocument === "Certificate of Incorporation" && CompanyName == "sbi" && formData.carOwner == "isCompany") {

      if (!formData?.CertificateOfIncorporationFile) {
        newErrors.CertificateOfIncorporationFile = "Certificate Of Incorporation is required";
      }

      if (!formData?.CertificateOfIncorporationNumber) {
        newErrors.CertificateOfIncorporationNumber = "Certificate Of Incorporation Number is required";
      }

    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  useEffect(() => {
    setDobClone(formData?.dob);
  }, [formData?.dob])

  const handleDobClone = (value, name, emptyString) => {
    setDobClone(new Date(value));
  }

  const cancelCalendarValue = (name) => {
    setDobClone(formData?.dob);
    setShowDobCalendar(false);
  }

  useEffect(() => {
    if (loading) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }

    return () => {
      document.body.style.overflow = '';
    };
  }, [loading]);

  // exclude zero dep in shreeram, if there is No Zero Dep in previous policy

  useEffect(() => {

    // SHREERAM QUICK QUOTE API CALL

    async function triggerQuickQuote() {
      if (formData?.isNilDepInPrevPolicy === "true") {
        setTriggerApi(false);
        setLoading(true);
        if (CompanyName == "shreeram") {
          let response = await dispatch(
            shreeRamQuickQuoteAction({
              data: {
                ...bodyData.data,
                ...{ coverage: coverages },
              },
            })
          );
          setLoading(false);
        }
      } else if (formData?.isNilDepInPrevPolicy === "false") {
        setLoading(true);
        if (CompanyName == "shreeram") {
          let response = await dispatch(
            shreeRamQuickQuoteAction({
              data: {
                ...bodyData.data,
                ...{ coverage: [getPreviousAddonData?.coverage[0]] },
              },
            })
          );
          setLoading(false);
          setTriggerApi(true);
        }
      }
    }



    // TATA QUICK QUOTE API CALL

    async function triggerTataQuickQuote() {
      if (formData?.isNilDepInPrevPolicy === "true") {
        setTriggerApi(false);
        setLoading(true);
        if (CompanyName == "tata") {
          let response = await dispatch(
            tataQuickQuoteAction({
              data: {
                ...bodyData.data,
                ...{ coverage: coverages },
              },
            })
          );
          setLoading(false);
        }
      } else if (formData?.isNilDepInPrevPolicy === "false") {
        setLoading(true);
        if (CompanyName == "tata") {
          let response = await dispatch(
            tataQuickQuoteAction({
              data: {
                ...bodyData.data,
                ...{ coverage: [getPreviousAddonData?.coverage[0]] },
              },
            })
          );
          setLoading(false);
          setTriggerApi(true);
        }
      }
    }

    if (bodyData && CompanyName === SHREERAM) {
      triggerQuickQuote();
    } else if (bodyData && CompanyName === TATA) {
      triggerTataQuickQuote();
    } else {
      return () => { }
    }
  }, [bodyData?.data?.isNilDepInPrevPolicy]);


  const getOtpTata = (otp) => {
    if (otp.length == 6) {
      (async function () {
        setStoreTataOtp(otp);
        closeModal();
        setLoading(true);
        let otpVerify = await dispatch(tataOtpVerifyAction({
          data: {
            proposalNo:
              tataProposalRes?.data?.data?.quote?.data[0]?.proposal_no,
            proposalBody:
              bodyData?.data,
            clientId:
              tataAutoKyc?.data?.data?.quote?.data?.client_id,
            otp:
              otp
          }
        }));
        if (otpVerify?.payload?.data?.quote?.message_txt == "Verification Successful") {
          // insert db
          let body = {
            companyname: CompanyName,
            transactionid: tataProposalRes.data.data.quote.data[0].proposal_no,
            bodydata: {
              tata: tataProposalRes?.data,
            },
            type: "initiate",
          };
          await dispatch(paymentWebhookAction(body));

          // start payment
          let onlinePayment = await dispatch(tataOnlinePaymentAction({
            data: {
              ...bodyData.data,
              ...{
                paymentId:
                  tataProposalRes.data.data.quote.data[0].payment_id,
              },
            }
          }));
          if (onlinePayment?.payload?.data?.quote?.message_txt == "Success") {
            let paymentLink = JSON.parse(onlinePayment?.payload?.data?.quote?.data)
            document.getElementById("myForm").action = paymentLink?.url;
            document.getElementsByName("pgiRequest")[0].value = paymentLink?.pgiRequest;
            dispatch(tataProposalReducer.actions.resettataProposalAction());
            document.getElementById("invisibleSubmitButtonTata").click(); // Programmatically click the invisible button
          } else {
            setLoading(false);
            toast.error(onlinePayment?.payload?.data?.quote?.message_txt ? onlinePayment?.payload?.data?.quote?.message_txt : "Something went wrong !", {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            dispatch(tataAutoKycReducer.actions.resettataAutoKycAction());
            dispatch(tataProposalReducer.actions.resettataProposalAction());
          }
        } else {
          setLoading(false);
          toast.error(otpVerify?.payload?.data?.quote?.message_txt ? otpVerify?.payload?.data?.quote?.message_txt : "Something went wrong !", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          dispatch(tataAutoKycReducer.actions.resettataAutoKycAction());
          dispatch(tataProposalReducer.actions.resettataProposalAction());
          await setFormData((prevItems) => ({
            ...prevItems,
            kycAadharNumber: '',
          }))
        }
      })();
    }
  }


  let saveCalendarValueforBike = (name) => {
    let dobValue = ''

    if (DobClone !== undefined && DobClone !== "Invalid Date") {
      dobValue = new Date(DobClone);
      // Set the time part to 00:00:00
      dobValue.setHours(0);
      dobValue.setMinutes(0);
      dobValue.setSeconds(0);

      setFormData((prevItems) => ({
        ...prevItems,
        dob: dobValue,
      }))
      setShowDobCalendar(false);
    }
  }
  const closePaaymentLinkModal = () => {
    setShowPaymentLink(false)
  }
  const HnaldeCloseNotFoundQouetModal = () => {
    closePaaymentLinkModal();
  }

  return (
    <div className="main-proposalForm-container">
      <Navbar />
      <ToastContainer />
      <Modal
        isOpen={carNoPopup}
        onRequestClose={closePaaymentLinkModal}
        style={window.innerWidth <= 610 ? customStylesForMobile : customStyles}
      >
        <div className="NoQuoetFondBoxmainContainer">
          <div style={{ display: "flex", justifyContent: "end", cursor: "pointer" }} onClick={HnaldeCloseNotFoundQouetModal}>
            {/* <img src={Close} alt="" /> */}
          </div>
          <div className="NoQuoetFondBox">
            <div>
              <img src={CompanyImage} alt="" />
            </div>
            <div className="secodboxfornoQuoet">
              <div><img src={caution} alt="" /></div>
              <div>Something Went Worng</div>
            </div >
            <h4>Vehicle Number is Missing!</h4>
            {/* <Botton className="NoQuoetBtn" text="Check Vehicle Number" event={(e) => HnaldeCloseNotFoundQouetModal(e)} /> */}
            {/* <div className="NoQuoetFondBoxboxthree">
              {CompanyName} is not providing the quote because selected vehicle is not mapped with the insurer.
            </div> */}
          </div>
        </div>
      </Modal>

      {/* VERIFY OTP MODAL: TATA */}

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
      >
        <OtpModal
          closeModal={closeModal}
          openModal={openModal}
          getOtp={getOtpTata}
        />
      </Modal>

      <div id="cover-spin" style={{ display: loading ? "" : "none" }}>
        <img src={loader2} className="gifLoader"></img>
      </div>
      <div
        className={
          !ShowKycInputs
            ? "PropsalFormContainer"
            : "PropsalFormContainer_padding PropsalFormContainer"
        }
      >
        <div className="stepperWithForm maxWidth">
          <div className={ShowForm === "FormDetails" ? "hideWithOpacity" : ""}>
            {!ShowKycInputs && (
              <Stepper
                ShowForm={ShowForm}
                StepperValue={StepperValue}
                InspectionStatus={InspectionStatus}
                handleStepperRoute={handleStepperRoute}
                formData={formData}
              />
            )}
          </div>
          <div
            className={
              ShowKycInputs === false
                ? "PropsalFormContainer_parent "
                : "padding_PropsalFormContainer_parent PropsalFormContainer_parent"
            }
            onClick={handlePopUps}
          >
            <>
              {ShowForm === "ownerDetail" ? (
                <>
                  <p
                    className="mobile-back-fmc mobile-back-pform"
                    onClick={() => navigate("/View-Plans-Bike?id=" + id)}
                  >
                    <img src={backIcon} alt="" srcset="" /> Back to Plans
                  </p>
                  <div className="breakupContainer PropsalFormContainer_child">
                    <div className="PremiumContainer-div">
                      <PremiumContainerBike
                        CompanyImage={CompanyImage}
                        CompanyName={CompanyName}
                        PolicyTypeValue={bodyData?.data?.PolicyTypeValue}
                        mappingAllData={mappingAllData}
                        setZeroDepValue={setZeroDepValue}
                      />
                    </div>
                  </div>
                  <div id="PropsalFormContainer_child-formFields" className="formContainer PropsalFormContainer_child">
                    <ProposalOne
                      nameValue={formData.ownerName}
                      emailValue={formData.email}
                      phoneValue={formData.phoneNumber}
                      panValue={formData.kycPanNumber}
                      ownerValue={formData.carOwner}
                      gstValue={formData.gstNumber}
                      compNameValue={formData.companyName}
                      kycStatus={ShowKycInputs}
                      formData={formData}
                      errors={errors}
                      Edit={Edit}
                      DobClone={DobClone}
                      handleSubmitForm={handleFormReveal}
                      HandleInputChange={(e) => handleInputChange(e)}
                      companyName={CompanyName}
                      sbiAutoKycData={sbiAutoKycData}
                      dateValue={formData.dob}
                      handleBackBtn={handleBackBtn}
                      cancelCalendarValue={cancelCalendarValue}
                      isPaCoverChecked={isPaCoverChecked}
                      isZeroDepChecked={isZeroDepChecked}
                      handleTataNilDep={handleTataNilDep}
                      ShowNilDepState={ShowNilDepState}
                      handleDobClone={handleDobClone}
                      bodyData={bodyData}
                      setShowDobCalendar={setShowDobCalendar}
                      saveCalendarValue={saveCalendarValueforBike}
                      ShowDobCalendar={ShowDobCalendar}
                      ZeroDepValue={ZeroDepValue}
                      checkphone={checkphone}
                      phonestatussloading={phonestatussloading}
                      vehicleType="2W"
                    />
                  </div>
                </>
              ) : ShowForm === "PersonalDetails" ? (
                <>
                  <p
                    className="mobile-back-fmc mobile-back-pform"
                    onClick={() => handleBackBtn("PersonalDetails")}
                  >
                    <img src={backIcon} alt="" srcset="" /> Back to Owner
                    Details
                  </p>
                  <div className="breakupContainer PropsalFormContainer_child">
                    <div className="PremiumContainer-div">
                      <PremiumContainerBike
                        CompanyImage={CompanyImage}
                        CompanyName={CompanyName}
                        PolicyTypeValue={bodyData?.data?.PolicyTypeValue}
                        mappingAllData={mappingAllData}
                      />
                    </div>
                  </div>
                  <div id="PropsalFormContainer_child-formFields" className="formContainer PropsalFormContainer_child">
                    <ProposalTwo
                      HandleInputChange={handleInputChange}
                      handleSubmitForm={handleFormReveal}
                      Edit={Edit}
                      handleDatePickerValue={(e) => handleDatePickerValue(e)}
                      dateValue={formData.dob}
                      nomineeName={formData.nomineeName}
                      nomineeAge={formData.nomineeAge}
                      nomineeRelation={formData.nomineeRelation}
                      handleBackBtn={handleBackBtn}
                      formData={formData}
                      DobClone={DobClone}
                      errors={errors}
                      bodyData={bodyData}
                      companyName={CompanyName}
                      sbiAutoKycData={sbiAutoKycData}
                      handleDobClone={handleDobClone}
                      saveCalendarValue={saveCalendarValueforBike}
                      cancelCalendarValue={cancelCalendarValue}
                      ShowDobCalendar={ShowDobCalendar}
                      setShowDobCalendar={setShowDobCalendar}
                    />
                  </div>
                </>
              ) : ShowForm === "AddressDetails" ? (
                <>
                  <p
                    className="mobile-back-fmc mobile-back-pform"
                    onClick={() => handleBackBtn("AddressDetails")}
                  >
                    <img src={backIcon} alt="" srcset="" /> Back to Personal
                    Details
                  </p>
                  <div className="breakupContainer PropsalFormContainer_child">
                    <div className="PremiumContainer-div">
                      <PremiumContainerBike
                        CompanyImage={CompanyImage}
                        CompanyName={CompanyName}
                        PolicyTypeValue={bodyData?.data?.PolicyTypeValue}
                        mappingAllData={mappingAllData}
                      />
                    </div>
                  </div>
                  <div id="PropsalFormContainer_child-formFields" className="formContainer PropsalFormContainer_child">
                    <PropsalThree
                      HandleInputChange={handleInputChange}
                      handleStateValue={handleStateValue}
                      handleCityValue={handleCityValue}
                      handleSubmitForm={handleFormReveal}
                      handleCityDropdown={(e) =>
                        setShowCityDropdown(!ShowCityDropdown)
                      }
                      ShowCityDropdown={ShowCityDropdown}
                      stateList={stateList}
                      cityList={cityList}
                      state={formData?.state}
                      city={formData?.city}
                      pincode={formData?.pincode ? formData?.pincode : pincode}
                      addressOne={formData?.addressOne}
                      addressTwo={formData?.addressTwo}
                      handleBackBtn={handleBackBtn}
                      formData={formData}
                      Edit={Edit}
                      errors={errors}
                      bodyData={bodyData}
                      CompanyName={CompanyName}
                    />
                  </div>
                </>
              ) : ShowForm === "VehicleDetails" ? (
                <>
                  <p
                    className="mobile-back-fmc mobile-back-pform"
                    onClick={() => handleBackBtn("vehicleDetails")}
                  >
                    <img src={backIcon} alt="" srcset="" /> Back to Address
                    Details
                  </p>
                  <div className="breakupContainer PropsalFormContainer_child">
                    <div className="PremiumContainer-div">
                      <PremiumContainerBike
                        CompanyImage={CompanyImage}
                        CompanyName={CompanyName}
                        PolicyTypeValue={bodyData?.data?.PolicyTypeValue}
                        mappingAllData={mappingAllData}
                      />
                    </div>
                  </div>
                  <div id="PropsalFormContainer_child-formFields" className="formContainer PropsalFormContainer_child">
                    <PropsalFour
                      HandleInputChange={handleInputChange}
                      handleSubmitForm={handleFormReveal}
                      handleBundleInsurer={handleBundleInsurer}
                      handleOdInsurer={handleOdInsurer}
                      prevPolicyNumber={formData.prevPolicyNumber}
                      prevPolicyNameInsurer={formData.prevPolicyNameInsurer}
                      chassisNumber={formData.chassisNumber}
                      engineNumber={formData.engineNumber}
                      carIsInLoan={formData.carIsInLoan}
                      carHypothecationCompany={formData.carHypothecationCompany}
                      handleBackBtn={handleBackBtn}
                      formData={formData}
                      bodyData={bodyData}
                      insurerList={insurerList}
                      handleFinancier={handleFinancier}
                      mappingAllData={mappingAllData}
                      Edit={Edit}
                      businessType={mappingAllData[0].typeOfBusiness}
                      errors={errors}
                      vehicleType="2W"
                      CompanyName={CompanyName}
                    />
                  </div>
                </>
              ) : ShowForm === "kycDetails" ? (
                <>
                  <p
                    className="mobile-back-fmc mobile-back-pform"
                    onClick={() => handleBackBtn("kycDetails")}
                  >
                    <img src={backIcon} alt="" srcset="" /> Back to Vehicle
                    Details
                  </p>
                  <div className="breakupContainer PropsalFormContainer_child">
                    <div className="PremiumContainer-div">
                      <PremiumContainerBike
                        CompanyImage={CompanyImage}
                        CompanyName={CompanyName}
                        PolicyTypeValue={bodyData?.data?.PolicyTypeValue}
                        mappingAllData={mappingAllData}
                      />
                    </div>
                  </div>
                  <div id="PropsalFormContainer_child-formFields" className="formContainer PropsalFormContainer_child formContainer-collapsible">
                    <KycForm
                      handleKycToggle={makeKyc}
                      HandleInputChange={(e) => handleInputChange(e)}
                      handleBackBtn={handleBackBtn}
                      kycAadharNumber={formData.kycAadharNumber}
                      kycPanNumber={formData.kycPanNumber}
                      kycProfileImg={formData.kycProfileImg}
                      kycAadharImg={formData.kycAadharImg}
                      kycAadharImgBack={formData.kycAadharImgBack}
                      kycPanImg={formData.kycPanImg}
                      typeOfKycDocument={formData.typeOfKycDocument}
                      AadharImgName={AadharImgName}
                      AadharImgBackName={AadharImgBackName}
                      PanImgName={PanImgName}
                      CINImgName={CINImgName}
                      bodyData={bodyData}
                      ProfileImgName={ProfileImgName}
                      AutoKYCrejected={AutoKYCrejected}
                      setAutoKYCrejected={setAutoKYCrejected}
                      isDisabledButton={isDisabledButton}
                      CompanyName={CompanyName}
                      carOwner={formData.carOwner}
                      CertificateOfIncorporationNumber={
                        formData.CertificateOfIncorporationNumber
                      }
                      errors={errors}
                      Image={Image}
                      companyName={CompanyName}
                      formData={formData}
                    />
                  </div>
                </>
              ) : ShowForm === "InspectionDetails" ? (
                <>
                  <p
                    className="mobile-back-fmc mobile-back-pform"
                    onClick={() => handleBackBtn("InspectionDetails")}
                  >
                    <img src={backIcon} alt="" srcset="" /> Back to Kyc Details
                  </p>
                  <div className="breakupContainer PropsalFormContainer_child">
                    <div className="PremiumContainer-div">
                      <PremiumContainerBike
                        CompanyImage={CompanyImage}
                        CompanyName={CompanyName}
                        PolicyTypeValue={bodyData?.data?.PolicyTypeValue}
                        mappingAllData={mappingAllData}
                      />
                    </div>
                  </div>
                  <div id="PropsalFormContainer_child-formFields" className="formContainer PropsalFormContainer_child">
                    <Inspection
                      HandleInputChange={handleInputChange}
                      handleSubmitForm={handleFormReveal}
                      handleBackBtn={handleBackBtn}
                      emailValue={formData.email}
                      phoneValue={formData.phoneNumber}
                      formData={formData}
                      handleCityDropdown={(e) =>
                        setShowCityDropdown(!ShowCityDropdown)
                      }
                      ShowCityDropdown={ShowCityDropdown}
                      stateList={stateList}
                      cityList={cityList}
                      state={formData.state}
                      city={formData.city}
                      pincode={formData.pincode}
                      addressOne={formData.addressOne}
                      addressTwo={formData.addressTwo}
                    />
                  </div>
                </>
              ) : ShowForm === "FormDetails" ? (
                <>
                  <div className="breakupContainer PropsalFormContainer_child">
                    <div className="PremiumContainer-div">
                      <PremiumContainerBike
                        CompanyImage={CompanyImage}
                        CompanyName={CompanyName}
                        PolicyTypeValue={bodyData?.data?.PolicyTypeValue}
                        mappingAllData={mappingAllData}
                      />
                    </div>
                  </div>
                  <div id="PropsalFormContainer_child-formFields" className="formContainer PropsalFormContainer_child formContainer-collapsible">
                    <div className="titleContainer  ">
                      <p>Confirm your details</p>
                    </div>
                    <Collapsible
                      data={formData}
                      setFormData={setFormData}
                      sbiAutoKycData={sbiAutoKycData}
                      mappingAllData={mappingAllData}
                      HandleEditProposalDetails={HandleEditProposalDetails}
                      handleBackBtn={handleBackBtn}
                      collapsibleTitle={collapsibleTitle}
                      setEdit={setEdit}
                      Edit={Edit}
                      headerCollapsible={headerCollapsible}
                      CompanyName={CompanyName}
                      hdfcKycDate={hdfcKycData?.data?.dob}
                      setAutoKYCrejected={setAutoKYCrejected}
                      tataOtpValue={StoreTataOtp}
                      closeModal={closeModal}
                      openModal={openModal}
                    />
                  </div>
                </>
              ) : (
                ""
              )}
            </>
          </div>
        </div>


        <div className="footer-parent-padding" >
          <div className="footer_bottom_container maxWidth  footer_bottom_container_pForm ">
            <p>{bottomFooter[0].contentOne}</p>
          </div>
          <div className="footer_bottom_containerTwo maxWidth ">
            <div className="footer_bottom_containerTwo_part">
              <p> {bottomFooter[0].contentTwo}</p>
              <p>{bottomFooter[0].contentThree}</p>
            </div>
            <div className="footer_bottom_containerTwo_part footer_bottom_containerTwo_part-mob">
              <p>{bottomFooter[0].contentFour}</p>
              <p>{bottomFooter[0].contentFive}</p>
            </div>
          </div>
        </div>
      </div>

      {/* hidden form for tata */}
      <form
        id="myForm"
        action=""
        method="post"
        hidden
      >
        <input type="hidden" name="pgiRequest" value="" />
        <input type="submit" id="invisibleSubmitButtonTata" value="Submit" />
      </form>
    </div>
  );
};

export default ProposalForm;
