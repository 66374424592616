import React, { useState } from "react";
import activeRadio from "../../Assets/Images/radioActiveSvg.svg";
import radio from "../../Assets/Images/radioSvg.svg";
import tick from "../../Assets/Images/success.svg";
import arrow from "../../Assets/Images/arrow.svg";
import Botton from "../Button/Button";
import { useSelector, useDispatch } from "react-redux";
import { carDetailsforProposal } from "../../utils/commonUtils";
import SkeletonLoader from "../Pages/Loader/Skeleton loader/SkeletonLoader";

const PackageModal = (props) => {
    const {
        packageData,
        setCarDetails,
        carDetails,
        closeModal,
        carDetailsCopyState,
        setcarDetailsCopyState,
        test,
        tataQuoteLoading,
    } = props;

    const [ShowClaimList, setShowClaimList] = useState(false);

    const handleDropdown = () => {
        if (ShowClaimList) {
            setShowClaimList(false);
        }
    }

    const getBodyData = useSelector((state) => {
        return state?.bodyData;
    });

    const sortedPlanKeys = Object.keys(packageData).sort((a, b) => {
        const numA = parseInt(a.substring(1));
        const numB = parseInt(b.substring(1));
        return numA - numB;
    });

    function filterKeys(carDetails, sortedKeys) {
        if (carDetails.isNilDepInPrevPolicy === "false") {
            return ["P1", "P2"];
        } else {
            return sortedKeys;
        }
    }

    const totalNetPremiums = {};

    for (const planKey in packageData) {
        if (packageData?.hasOwnProperty(planKey)) {
            const packageDetails = packageData[planKey];
            const vehicleBreakup = carDetailsforProposal("tata", packageDetails, getBodyData, test);
            let totalNetPremium = vehicleBreakup.DuePremium;
            const finalTotalPremium = totalNetPremium;
            totalNetPremiums[planKey] = Math.round(finalTotalPremium);
        }
    }

    function filterKeys(carDetails, sortedKeys) {
        if (carDetails.isNilDepInPrevPolicy === "false") {
            let arr = [];
            if ('P1' in totalNetPremiums) {
                arr.push("P1")
            }
            if ('P2' in totalNetPremiums) {
                arr.push("P2")
            }
            return arr;
        } else if (carDetails.isInvoiceCoverInPrevPolicy === "false" && carDetails.isInvoiceCoverInPrevPolicy !== "") {
            // let arr = ["P1", "P2", "P3", "P4", "P5", "P6", "P8", "P10"];

            return ["P1", "P2", "P3", "P4", "P5", "P6", "P10"];
        } else {
            return sortedKeys;
        }
    }

    const filteredKeys = filterKeys(carDetails, sortedPlanKeys);

    const countOfTataPackages = filteredKeys?.length;

    const planNames = {
        P1: "SILVER",
        P2: "GOLD",
        P3: "PEARL",
        P4: "PEARL+",
        P5: "SAPPHIRE",
        P6: "SAPPHIRE+",
        P7: "SAPPHIRE++",
        P8: "OPTIONAL",
        P9: "PLATINUM",
        P10: "CORAL",
        P11: "PEARL++",
    };

    const handleSelectedPlan = (planName) => {
        if (planName === carDetailsCopyState?.quotePackageNameCopy) {
            setcarDetailsCopyState((prevItems) => ({
                ...prevItems,
                quotePackageNameCopy: "",
            }));
        } else {
            setcarDetailsCopyState((prevItems) => ({
                ...prevItems,
                quotePackageNameCopy: planName,
            }));
        }
    };

    const setPlanPackage = (planName) => {
        setCarDetails((prevItems) => ({
            ...prevItems,
            quotePackageName: carDetailsCopyState?.quotePackageNameCopy,
        }));
        if(carDetailsCopyState?.quotePackageNameCopy){
            closeModal();
        }
       
    };

    const resetPlanPackage = () => {
        setcarDetailsCopyState((prevItems) => ({
            ...prevItems,
            quotePackageNameCopy: carDetails?.quotePackageName,
        }));
        closeModal();
    }

    const planAddons = {
        P1: ["Repair Of Glass, Plastic Fiber and Rubber."],
        P2: [
            "Road Side Assistance",
            "Key Replacement",
            "Loss Of Personal Belongings",
        ],
        P3: [
            "Zero Depreciation",
            "Key Replacement",
            "Loss Of Personal Belongings",
            "Road Side Assistance",
        ],
        P4: [
            "Zero Depreciation",
            "Road Side Assistance",
            "Consumable Expenses",
            "Engine Secure",
            "Key Replacement",
            "Loss Of Personal Belongings",
        ],
        P5: [
            "Zero Depreciation",
            "Road Side Assistance",
            "Consumable Expenses",
            "Tyre Secure",
            "Key Replacement",
            "Loss Of Personal Belongings",
        ],
        P6: [
            "Zero Depreciation",
            "Road Side Assistance",
            "Consumable Expenses",
            "Tyre Secure",
            "Engine Secure",
            "Key Replacement",
            "Loss Of Personal Belongings",
        ],
        P7: [
            "Zero Depreciation",
            "Return to Invoice",
            "Consumable Expenses",
            "Road Side Assistance",
            "Engine Secure",
            "Tyre Secure",
            "Key Replacement",
            "Loss Of Personal Belongings",
        ],
        P8: ["Choose as per preference"],
        P9: [
            "Zero Depreciation",
            "Road Side Assistance",
            "Return to Invoice",
            "Consumable Expenses",
            "Key Replacement",
            "Loss Of Personal Belongings",
        ],
        P10: [
            "Zero Depreciation",
            "Road Side Assistance",
            "Consumable Expenses",
            "Key Replacement",
            "Loss Of Personal Belongings",
        ],
        P11: [
            "Zero Depreciation",
            "Road Side Assistance",
            "Return to Invoice",
            "Consumable Expenses",
            "Engine Secure",
            "Key Replacement",
            "Loss Of Personal Belongings",
        ],
        P12: [
            "Road Side Assistance",
            "Key Replacement",
            "Loss Of Personal Belongings",
        ],
        P13: [
            "Zero Depreciation",
            "Return to Invoice",
            "Road Side Assistance",
            "Consumable Expenses",
            "Tyre Secure",
            "Key Replacement",
            "Loss Of Personal Belongings",
        ],
    };

    const handleNumberOfClaim = (e) => {
        const { id, name, value } = e?.target || {};
        setCarDetails((prevItems) => ({
            ...prevItems,
            [name]: value,
        }))
    }

    const handleZeroDepValue = (selectedValue) => {
        setCarDetails((prevItems) => ({
            ...prevItems,
            isNilDepInPrevPolicy: selectedValue,
        }))
    }

    return (
        <>
            <div className="package-container full-height" onClick={handleDropdown} >
                <div className="head-addon-modal head-package-modal">
                    <div>
                        <p>TATA Plans <span style={{ color: "#5086FF", fontSize: ".8rem" }}> ({countOfTataPackages} Plans Available) </span> </p>
                    </div>
                    <div className="dp-main-container" >
                        <div className="dp-main-head" onClick={() => setShowClaimList(!ShowClaimList)} >
                            <p> {carDetails?.numberOfClaim === "99" ? "Unlimited" : carDetails?.numberOfClaim}  Claim</p>
                            <p>Apply on Zero Dep</p>
                            <img src={arrow} />
                        </div>
                        {
                            ShowClaimList && (
                                <div className="dp-main-options">
                                    <button type="button" name="numberOfClaim" value="1" onClick={(e) => handleNumberOfClaim(e)} > 1 Claim </button>
                                    <button type="button" name="numberOfClaim" value="2" onClick={(e) => handleNumberOfClaim(e)} > 2 Claim</button>
                                    <button type="button" name="numberOfClaim" value="99" onClick={(e) => handleNumberOfClaim(e)} > Unlimited Claim </button>
                                </div>
                            )
                        }
                    </div>
                </div>
                <div className="package-container-modal">
                    <div className="switch-container-modal">
                        <div className="textnilldeppervious">Zero Dep in your previous policy?  </div>
                        <div className="switchBtn-container" >
                            <button className={carDetails?.isNilDepInPrevPolicy == "true" ? "active-switchclass" : "Non-active-switch-class"} id="activeStwitchbtn" onClick={() => handleZeroDepValue("true")} >Yes</button>
                            <button className={carDetails?.isNilDepInPrevPolicy == "false" ? "active-switchclass" : "Non-active-switch-class"} id="noactiveStwitchbtn" onClick={() => handleZeroDepValue("false")} >No</button>
                        </div>
                    </div>
                    {sortedPlanKeys.map((planKey) => (
                        <div
                            className={
                                carDetailsCopyState?.quotePackageNameCopy == planNames[planKey]
                                    ? "plan-package-container active-plan-package-container"
                                    : "plan-package-container"
                            }
                            key={planKey}
                        >
                            <div
                                className="plan-package-head"
                                onClick={() => handleSelectedPlan(planNames[planKey])}
                            >
                                <div className="radio-container-package-popup">
                                    <img
                                        src={
                                            carDetailsCopyState?.quotePackageNameCopy ==
                                                planNames[planKey]
                                                ? activeRadio
                                                : radio
                                        }
                                        alt="radio"
                                    />
                                    <p>{planNames[planKey]}</p>
                                </div>
                                <div className="radio-container-package-plan">
                                    {
                                        tataQuoteLoading ? (
                                            <SkeletonLoader height={30} />
                                        ) : (
                                            <>
                                                <p>₹ {totalNetPremiums[planKey]?.toLocaleString('en-IN')}</p>
                                                <p>Inc GST</p>
                                            </>
                                        )
                                    }
                                </div>
                            </div>
                            {carDetailsCopyState?.quotePackageNameCopy ==
                                planNames[planKey] && (
                                    <div className="plan-breakup-package">
                                        {planAddons[planKey]?.map((addon, index) => (
                                            <div className="addons-plan-tab" key={index}>
                                                <img src={tick} alt="tick" />
                                                <p>{addon}</p>
                                            </div>
                                        ))}
                                    </div>
                                )}
                        </div>
                    ))}
                </div>
                <div className={carDetails?.isNilDepInPrevPolicy === "false" ? " fixed-modal-btn-container packahe-modal-btn-container" : "packahe-modal-btn-container"}>
                    <Botton
                        text="Cancel"
                        className="secondry_btn btn-packageModals"
                        event={resetPlanPackage}
                    />
                    <Botton
                        text={!carDetailsCopyState?.quotePackageNameCopy?"Select Plan":"Proceed"}
                        className={!carDetailsCopyState?.quotePackageNameCopy? "button btn-packageModals noSelectedPlan":"button btn-packageModals"}
                        event={setPlanPackage}
                    />
                </div>
            </div>
        </>
    );
};

export default PackageModal;
