import React, { useState, useEffect, useRef } from "react";
import "./DropDown.css";
import arrow from "../../Assets/Images/arrow.svg";
import blueArrow from "../../Assets/Images/blueArrow.svg";

const DropDown = (props) => {
  const {
    dropDownMenu,
    className,
    classNameForHead,
    Head,
    clickEvent,
    revealOptions,
    afterTrueClass,
    valueSelectionEvent,
    revealOptionsClasses,
    classNameTwo,
    dropDownHeadClass,
    dropdownId,
    idForHead,
    idForHeadTxt,
    placeHolder,
    headClick,
    name,
    inputId,
    value,
    label,
    arrowInGray,
    idForArrow,
    idForParentObj,
    inputClass,
    errState,
    errMsg,
    labelclass,
    KYCmethod,
    errClassName,
  } = props;
  // here change for some style for kyc dropdown 
  const dropDwnStyle = {
    color: name == "typeOfKycDocument" ? "#5086FF" : ""
  }
  console.log(arrowInGray, "dfsdfs");

  return (
    <>
      <div
        className={
          revealOptions
            ? afterTrueClass
            : errState
              ? errClassName + " " + className
              : className
        }
      >
        <div
          id={idForHead}
          className={dropDownHeadClass}
          onClick={(e) => headClick(e)}
        >
          {value === "" || value === null || value === undefined ? (
            <p className={classNameForHead} id={idForHeadTxt}>
              {Head}
            </p>
          ) : (
            <input
              type="text"
              className={inputClass ? inputClass : "Input_Dropdown"}
              value={value}
              name={name}
              style={dropDwnStyle}
              autoComplete="off"
              placeholder={placeHolder}
              id={inputId}
              readOnly
            />
          )}
          {/* here some change for kyc styles */}
          {(dropDownMenu?.length > 1 || !KYCmethod) ? (arrowInGray && arrowInGray !== 'HideArrowforDigitandZuno') ? (
            <img id="arrowInGray" src={name == "typeOfKycDocument" ? blueArrow : arrowInGray} alt="" srcSet="" />
          ) : (
            arrowInGray == 'HideArrowforDigitandZuno' ? ""
              : <img id={idForArrow} src={arrow} alt="" srcSet="" />
          ) : ""}
        </div>
        <div
          id={dropdownId}
          className={revealOptions ? revealOptionsClasses : classNameTwo}
          onClick={(e) => clickEvent(e)}

        >
          {(dropDownMenu?.length > 1 || !KYCmethod) && dropDownMenu?.map((item, index) => (
            <>
              <button
                id={idForParentObj}
                key={index}
                onClick={valueSelectionEvent}
                className="dropdownItems-text"
                value={item.value}
                name={name}
                type="button"
              >
                {item.label}
              </button>
            </>
          ))}
        </div>
        {value && label ? (
          <p
            className={
              labelclass ? labelclass + " label-dropdown" : "label-dropdown"
            }
          >
            {label}
          </p>
        ) : null}
      </div>
      {errState && <p className="err-msg">{errState ? errMsg : ""}</p>}
    </>
  );
};

export default DropDown;
